import { assertNever, QueryReturn } from '@runql/util';
import {
   BigQueryQueryBuilder,
   DatabricksQueryBuilder,
   MsSQLDataType,
   MsSQLQueryBuilder,
   MySQLDataType,
   MySQLQueryBuilder,
   OracleDataType,
   OracleQueryBuilder,
   PostgresDataType,
   PostgresQueryBuilder,
   RedshiftDataType,
   RedshiftQueryBuilder,
   SnowflakeQueryBuilder,
   SnowflakeDataType,
   TrinoQueryBuilder,
   DatabricksDataType,
} from '.';
import { ColumnNull, SchemaCache } from '../../entities';
import { DBMS } from '../../enums';

export interface ColumnDefinition {
   comment?: string;
   data?: any;
   dataType?: string;
   default?: string;
   defaultConstraint?: string;
   extra?: string;
   nullable?: boolean;
}

export interface ColumnModify {
   dataType?: string;
   default?: string;
   drop?: boolean;
   isNew?: boolean;
   nullable?: boolean;
   rename?: string;
}

export interface RelationDefinition {
   comment?: string;
   data?: any;
   dataType?: string;
   default?: string;
   extra?: string;
   nullable?: boolean;
}

export interface RelationModify {
   columns?: string[];
   drop?: boolean;
   fkColumns?: string[];
   fkTable?: string;
   isNew?: boolean;
   name?: string;
   onDelete?: string;
   onUpdate?: string;
}

export interface IndexDefinition {
   columnNames?: string[];
   data?: any;
   delete?: boolean;
   isNew?: boolean;
   primary?: boolean;
   rename?: string;
   type?: string;
   unique?: boolean;
}

export interface IndexModify {
   columnNames: string[];
   delete?: boolean;
   indexType?: string;
   isNew?: boolean;
   primary?: boolean;
   rename?: string;
   unique?: boolean;
}

interface ClientDataUpdate {
   catalog?: string;
   data?: Record<string, any>;
   key: Record<string, any>;
   nodeId?: string;
   schema: string;
   table: string;
   type: 'update';
}

interface ClientDataInsert {
   catalog?: string;
   data?: Record<string, any>;
   nodeId?: string;
   schema: string;
   table: string;
   type: 'insert';
}

interface ClientDataDelete {
   catalog?: string;
   key: Record<string, any>;
   nodeId?: string;
   schema: string;
   table: string;
   type: 'delete';
}

interface ClientTableModify {
   catalog?: string;
   columns?: Record<string, ColumnModify>;
   schema: string;
   table: string;
   type: 'modify';
}

interface ClientIndexModify {
   catalog?: string;
   indexes?: Record<string, IndexModify>;
   schema: string;
   table: string;
   type: 'modifyIndex';
}

interface ClientRelationsModify {
   catalog?: string;
   relations?: Record<string, RelationModify>;
   schema: string;
   table: string;
   type: 'modifyRelation';
}

export type ClientDataChange =
   | ClientDataUpdate
   | ClientDataInsert
   | ClientDataDelete
   | ClientTableModify
   | ClientIndexModify
   | ClientRelationsModify;

export function queryBuilderFactory(dbms: DBMS): QueryBuilder {
   switch (dbms) {
      case DBMS.Big_Query:
         return new BigQueryQueryBuilder();
      case DBMS.Databricks:
         return new DatabricksQueryBuilder();
      case DBMS.MSSQL:
         return new MsSQLQueryBuilder();
      case DBMS.MySQL:
         return new MySQLQueryBuilder();
      case DBMS.Oracle:
         return new OracleQueryBuilder();
      case DBMS.Postgres:
         return new PostgresQueryBuilder();
      case DBMS.Redshift:
         return new RedshiftQueryBuilder();
      case DBMS.Snowflake:
         return new SnowflakeQueryBuilder();
      case DBMS.Trino:
         return new TrinoQueryBuilder();
      default:
         return new QueryBuilder();
   }
}

export function buildChangeQueries(
   data: ClientDataChange[],
   schemaCache: SchemaCache[],
   dbms: DBMS
): string[] {
   const queries: string[] = [];
   const queryBuilder = queryBuilderFactory(dbms);

   if (data.length > 1) {
      queries.push(queryBuilder.beginTransaction());
   }
   data.forEach((change) => {
      if (change.type === 'update') {
         const updateObj = queryBuilder.update({
            catalog: change.catalog,
            schema: change.schema,
            table: change.table,
            updates: Object.entries(change.data || {}).reduce<Record<string, ColumnDefinition>>(
               (acc, [column, value]) => {
                  const colDef = schemaCache.find((col) => col.columnName === column);
                  if (!colDef) {
                     console.warn(`Column definition not found for column: ${column}`);
                     return acc;
                  }
                  acc[column] = {
                     data: value,
                     nullable: colDef.columnNullable === ColumnNull.NULL,
                     dataType: colDef.columnType,
                  };
                  return acc;
               },
               {}
            ),
            where: change.key,
         });
         queries.push(updateObj);
      } else if (change.type === 'insert') {
         if (!change.data) {
            console.error('No data to insert');
            return;
         }
         queries.push(
            queryBuilder.insert({
               catalog: change.catalog,
               schema: change.schema,
               table: change.table,
               insertData: change.data,
            })
         );
      } else if (change.type === 'delete') {
         queries.push(
            queryBuilder.delete({
               catalog: change.catalog,
               schema: change.schema,
               table: change.table,
               where: change.key,
            })
         );
      } else if (change.type === 'modify') {
         const columnUpdate = change.columns
            ? Object.entries(change.columns).reduce<
                 Record<string, { definition?: ColumnDefinition; modify: ColumnModify }>
              >((acc, [name, modify]) => {
                 const column = schemaCache.find((col) => col.columnName === name);
                 acc[name] = {
                    definition: column && {
                       nullable: column.columnNullable === ColumnNull.NULL,
                       dataType: column.columnType,
                       comment: column.columnComment,
                       default: column.columnDefault,
                       defaultConstraint: column.columnDefaultConstraint,
                       extra: column.columnExtra,
                    },
                    modify,
                 };

                 return acc;
              }, {})
            : undefined;
         if (!columnUpdate) {
            console.error('No columns to modify');
            return;
         }
         // Separate column renames from other table changes.
         const columnRenames: Record<
            string,
            { definition?: ColumnDefinition; modify: ColumnModify }
         > = {};
         Object.entries(columnUpdate).forEach(([key, value]) => {
            if (value.modify.rename && value.modify.isNew === false) {
               columnRenames[key] = {
                  definition: columnUpdate[key].definition,
                  modify: {
                     rename: value.modify.rename,
                  },
               };
               value.modify.rename = undefined;
            }
         });
         queries.push(
            queryBuilder.alterTable({
               catalog: change.catalog,
               columns: columnUpdate,
               schema: change.schema,
               table: change.table,
            })
         );
         queries.push(
            queryBuilder.alterTable({
               catalog: change.catalog,
               columns: columnRenames,
               schema: change.schema,
               table: change.table,
            })
         );
      } else if (change.type === 'modifyIndex' && dbms === DBMS.MySQL) {
         const updateObj = queryBuilder.modifyIndex({
            catalog: change.catalog,
            schema: change.schema,
            table: change.table,
            updates: Object.entries(change.indexes || {}).reduce<Record<string, IndexDefinition>>(
               (acc, [index, value]) => {
                  acc[index] = value;
                  return acc;
               },
               {}
            ),
         });
         queries.push(updateObj);
      } else if (change.type === 'modifyRelation') {
         const relationUpdate = change.relations
            ? Object.entries(change.relations).reduce<
                 Record<string, { definition?: RelationDefinition; modify: RelationModify }>
              >((acc, [name, modify]) => {
                 const relation = schemaCache.find((rel) => rel.relationName === name);
                 acc[name] = {
                    definition: relation && {
                       nullable: relation.columnNullable === ColumnNull.NULL,
                       dataType: relation.columnType,
                       comment: relation.columnComment,
                       default: relation.columnDefault,
                       extra: relation.columnExtra,
                    },
                    modify,
                 };
                 return acc;
              }, {})
            : undefined;
         if (!relationUpdate) {
            console.error('No columns to modify');
            return;
         }
         queries.push(
            queryBuilder.alterRelation({
               relations: relationUpdate,
               schema: change.schema,
               table: change.table,
               catalog: change.catalog,
            })
         );
      }
   });

   if (data.length > 1 || dbms === DBMS.Oracle) {
      queries.push(queryBuilder.endTransaction());
   }

   return queries;
}

export function buildExplainQuery(query: string, dbms: DBMS, analyze = false): string {
   const queryBuilder = queryBuilderFactory(dbms);
   return queryBuilder.explainQuery(query, analyze);
}

export const extractExplainResult = (dbms: DBMS, result: QueryReturn): string => {
   switch (dbms) {
      case DBMS.MySQL:
         return result.rows?.[0].EXPLAIN;
      case DBMS.Postgres:
      case DBMS.Trino:
      case DBMS.Redshift:
         if (!Array.isArray(result.rows)) return '';
         return result.rows?.map((row) => row['QUERY PLAN']).join('\n');
      case DBMS.Databricks:
         //TODO: Test databricks explain
         return result.rows?.[0].plan;
      case DBMS.Snowflake:
         return result.rows?.[0].content;
      case DBMS.MSSQL:
         if (!Array.isArray(result.rows)) return '';
         return result.rows?.map((row) => row['StmtText']).join('\n');
      case DBMS.Oracle:
      case DBMS.MongoDB:
      case DBMS.Neo4j:
      case DBMS.Python:
      case DBMS.CHAT_GPT:
      case DBMS.Hugging_Face:
      case DBMS.Big_Query:
      case DBMS.Federated:
         throw new Error('Unsupported DBMS');
      default:
         assertNever(dbms);
   }
};

export enum SQLType {
   BIGINT = 'BIGINT',
   BINARY = 'BINARY',
   BLOB = 'BLOB',
   BOOLEAN = 'BOOLEAN',
   CHAR = 'CHAR',
   CLOB = 'CLOB',
   DATE = 'DATE',
   DECFLOAT = 'DECFLOAT',
   DECIMAL = 'DECIMAL',
   DOUBLE = 'DOUBLE',
   DOUBLE_PRECISION = 'DOUBLE PRECISION',
   FLOAT = 'FLOAT',
   INTEGER = 'INTEGER',
   INTERVAL = 'INTERVAL',
   JSON = 'JSON',
   NCHAR = 'NCHAR',
   NCLOB = 'NCLOB',
   NUMERIC = 'NUMERIC',
   NVARCHAR = 'NVARCHAR',
   REAL = 'REAL',
   ROW = 'ROW',
   SMALLINT = 'SMALLINT',
   TIME = 'TIME',
   TIMESTAMP = 'TIMESTAMP',
   VARBINARY = 'VARBINARY',
   VARCHAR = 'VARCHAR',
   XML = 'XML',
}

export function getDataTypes(dbms: DBMS | undefined): string[] {
   switch (dbms) {
      case DBMS.MySQL:
         return Object.values(MySQLDataType);
      case DBMS.MSSQL:
         return Object.values(MsSQLDataType);
      case DBMS.Snowflake:
         return Object.values(SnowflakeDataType);
      case DBMS.Postgres:
         return Object.values(PostgresDataType);
      case DBMS.Redshift:
         return Object.values(RedshiftDataType);
      case DBMS.Oracle:
         return Object.values(OracleDataType);
      case DBMS.Databricks:
         return Object.values(DatabricksDataType);
      default:
         return Object.values(SQLType);
   }
}

export class QueryBuilder {
   public getQuotedValue(value?: any, column?: ColumnDefinition): string | number {
      if (column && this.isTypeNumeric(column)) {
         return value;
      }
      if (column && this.isTypeString(column)) {
         if (!value) return "''";
         else if (value.toLowerCase() === 'null') return 'null';
      }
      if (value instanceof Date) {
         return `'${value.toISOString().slice(0, 19).replace('T', ' ')}'`;
      }

      return isNaN(value) ? `'${String(value).replaceAll("'", "''")}'` : String(value);
   }

   public buildWhereClause(where: Record<string, any>): string {
      return Object.entries(where)
         .map(([k, v]) => `${this.wrapIdentifier(k)} = ${this.getQuotedValue(v)}`)
         .join(' AND ');
   }

   public isTypeNumeric(column: ColumnDefinition): boolean {
      if (!column.dataType) {
         return false;
      }
      const normalizedType = column.dataType.toUpperCase();
      switch (normalizedType) {
         case 'INTEGER':
         case 'INT':
         case 'SMALLINT':
         case 'BIGINT':
         case 'DECIMAL':
         case 'NUMERIC':
         case 'FLOAT':
         case 'REAL':
         case 'DOUBLE PRECISION':
         case 'DOUBLE':
         case 'DECFLOAT':
            return true;
         default:
            return false;
      }
   }

   public isTypeString(column: ColumnDefinition): boolean {
      if (!column.dataType) {
         return false;
      }
      const normalizedType = column.dataType.toUpperCase();

      // all possible string types from database types above
      const stringTypes = [
         'CHAR',
         'VARCHAR',
         'TEXT',
         'STRING',
         'TINYTEXT',
         'MEDIUMTEXT',
         'LONGTEXT',
         'NCHAR',
         'NVARCHAR',
         'NTEXT',
         'VARCHAR2',
         'CLOB',
         'NCLOB',
      ];

      return stringTypes.some((type) => normalizedType.includes(type));
   }

   public qualifyTable(table: string, schema?: string, catalog?: string): string {
      return `${catalog && schema ? `${this.wrapIdentifier(catalog)}.` : ''}${
         schema ? `${this.wrapIdentifier(schema)}.` : ''
      }${this.wrapIdentifier(table)}`;
   }

   public wrapIdentifier(identifier: string): string {
      return `"${identifier}"`;
   }

   public beginTransaction(): string {
      return 'BEGIN;';
   }

   public endTransaction(): string {
      return 'COMMIT;';
   }

   public update({
      updates,
      catalog,
      table,
      schema,
      where,
   }: {
      catalog?: string;
      schema?: string;
      table: string;
      updates: Record<string, ColumnDefinition>;
      where: Record<string, any>;
   }): string {
      // Building SET clause for the update
      const setClause = Object.entries(updates)
         .map(([column, value]) => {
            return `${this.wrapIdentifier(column)} = ${this.getQuotedValue(value.data, value)}`;
         })
         .join(', ');

      return `UPDATE ${this.qualifyTable(
         table,
         schema,
         catalog
      )} SET ${setClause} WHERE ${this.buildWhereClause(where)};`;
   }

   public insert({
      catalog,
      table,
      schema,
      insertData,
   }: {
      catalog?: string; // Not all DBMS support catalog
      insertData: Record<string, any>;
      schema?: string;
      table: string;
   }): string {
      const columns = Object.keys(insertData)
         .map((col) => this.wrapIdentifier(col))
         .join(', ');
      const values = Object.values(insertData)
         .map((v) => this.getQuotedValue(v))
         .join(', ');

      return `INSERT INTO ${this.qualifyTable(
         table,
         schema,
         catalog
      )} (${columns}) VALUES (${values});`;
   }

   public delete({
      catalog,
      table,
      schema,
      where,
   }: {
      catalog?: string; // Not all DBMS support catalog
      schema?: string;
      table: string;
      where: Record<string, any>;
   }): string {
      return `DELETE FROM ${this.qualifyTable(
         table,
         schema,
         catalog
      )} WHERE ${this.buildWhereClause(where)};`;
   }

   public alterTable({
      catalog,
      columns,
      table,
      schema,
   }: {
      catalog?: string; // Not all DBMS support catalog
      columns: Record<string, { definition?: ColumnDefinition; modify: ColumnModify }>;
      schema?: string;
      table: string;
   }): string {
      const alterTable = `ALTER TABLE ${this.qualifyTable(table, schema, catalog)}\n`;
      const columnChanges = Object.entries(columns).map(([column, { modify }]) => {
         const changeScripts: string[] = [];
         if (modify.isNew) {
            const nullable = modify.nullable ? '' : 'NOT NULL';
            const defaultValue = modify.default ? `DEFAULT ${modify.default}` : '';
            changeScripts.push(
               `ADD COLUMN ${this.wrapIdentifier(column)} ${
                  modify.dataType
               } ${nullable} ${defaultValue}`
            );
         } else if (modify.drop) {
            changeScripts.push(`DROP COLUMN ${this.wrapIdentifier(column)}`);
         } else {
            if (modify.dataType) {
               changeScripts.push(
                  `ALTER COLUMN ${this.wrapIdentifier(column)} SET DATA TYPE ${modify.dataType}`
               );
            }
            if (modify.default) {
               changeScripts.push(
                  `ALTER COLUMN ${this.wrapIdentifier(column)} SET DEFAULT ${modify.default}`
               );
            }
            if (modify.nullable !== undefined) {
               changeScripts.push(
                  `ALTER COLUMN ${this.wrapIdentifier(column)} ${
                     modify.nullable ? 'DROP NOT NULL' : 'SET NOT NULL'
                  }`
               );
            }
            if (modify.rename) {
               changeScripts.push(
                  `RENAME COLUMN ${this.wrapIdentifier(column)} TO ${this.wrapIdentifier(
                     modify.rename
                  )}`
               );
            }
         }
         return changeScripts.join(',\n');
      });

      if (columnChanges.length > 0) {
         return `${alterTable} ${columnChanges.join(',\n')};`;
      }
      return '';
   }

   public alterRelation({
      catalog,
      relations,
      table,
      schema,
   }: {
      catalog?: string;
      relations: Record<string, { definition?: RelationDefinition; modify: RelationModify }>;
      schema?: string;
      table: string;
   }): string {
      const alterTable = `ALTER TABLE ${this.qualifyTable(table, schema)}\n`;
      const relationChanges = Object.entries(relations).map(([relation, { modify }]) => {
         const changeScripts: string[] = [];

         // If it's a new relation, create the foreign key constraint
         if (modify.isNew) {
            const foreignKeyColumns = modify.columns
               ?.map((col) => this.wrapIdentifier(col))
               .join(', ');
            const referencedColumns = modify.fkColumns
               ?.map((col) => this.wrapIdentifier(col))
               .join(', ');
            const fkTable = this.wrapIdentifier(modify.fkTable || '');

            changeScripts.push(
               `ADD CONSTRAINT ${this.wrapIdentifier(relation)} FOREIGN KEY (${foreignKeyColumns})
               REFERENCES ${fkTable} (${referencedColumns})
               ON UPDATE ${modify.onUpdate} ON DELETE ${modify.onDelete}`
            );
         }
         // If the relation should be dropped
         else if (modify.drop) {
            changeScripts.push(`DROP CONSTRAINT ${this.wrapIdentifier(relation)}`);
         }
         return changeScripts.join('\n');
      });

      if (relationChanges.length > 0) {
         return `${alterTable} ${relationChanges.join('\n')};`;
      }
      return '';
   }

   public explainQuery(query: string, analyze = false): string {
      if (analyze) {
         return `EXPLAIN ANALYZE ${query}`;
      }
      return `EXPLAIN ${query}`;
   }

   public modifyIndex({
      catalog,
      schema,
      table,
      updates,
   }: {
      catalog?: string; // Not all DBMS support catalog
      schema?: string;
      table: string;
      updates: Record<string, IndexDefinition>;
   }): string {
      const statements = [];
      const fullTableName = this.qualifyTable(table, schema, catalog);

      for (const indexName in updates) {
         const indexUpdate = updates[indexName];
         if (!indexUpdate.isNew) {
            statements.push(`DROP INDEX ${this.wrapIdentifier(indexName)} ON ${fullTableName};`);
         }

         // Add a new index if it's not marked for deletion
         if (
            !indexUpdate.delete &&
            ((indexUpdate.columnNames && indexUpdate.columnNames.length > 0) || indexUpdate.rename)
         ) {
            let indexType = '';
            if (indexUpdate.unique) {
               indexType = 'UNIQUE INDEX';
            } else {
               indexType = 'INDEX';
            }
            const columns = indexUpdate.columnNames
               ?.map((col) => this.wrapIdentifier(col))
               .join(', ');
            const indexNameToUse = indexUpdate.rename || indexName;
            statements.push(
               `CREATE ${indexType} ${this.wrapIdentifier(
                  indexNameToUse
               )} ON ${fullTableName} (${columns});`
            );
         }
      }

      return statements.join('\n');
   }

   // -- Delete Table

   protected ifExists = '';

   public supportsDisablingForeignKeyChecks = false;

   protected disableForeignKeyChecks(statements: string[]): string[] {
      return statements;
   }

   public deleteTable({
      catalog,
      force = false,
      schema,
      table,
   }: {
      catalog?: string;
      force?: boolean;
      schema: string;
      table: string;
   }) {
      const statements = [
         `${`DROP TABLE ${this.ifExists}`.trim()} ${this.qualifyTable(table, schema, catalog)};`,
      ];

      if (!this.supportsDisablingForeignKeyChecks) {
         return statements;
      }

      return force ? this.disableForeignKeyChecks(statements) : statements;
   }

   // -- Create Table

   protected increments(
      name: string = 'id',
      { nullable = true, primaryKey = true }: { nullable?: boolean; primaryKey?: boolean } = {}
   ) {
      return `${this.wrapIdentifier(name)} int${nullable || primaryKey ? ' NOT NULL' : ''}${
         primaryKey ? ' PRIMARY KEY' : ''
      }`;
   }

   public createTable({
      catalog,
      schema,
      table,
   }: {
      catalog?: string;
      schema: string;
      table: string;
   }) {
      return `CREATE TABLE ${this.qualifyTable(table, schema, catalog)} (
  ${this.increments()}
);`;
   }

   // -- Fetch Table Content

   public fetchTableContent(
      {
         catalog,
         schema,
         table,
      }: {
         catalog?: string;
         schema: string;
         table: string;
      },
      { limit = 10_000 }: { limit?: number | null } = {}
   ) {
      return `SELECT *
FROM ${this.qualifyTable(table, schema, catalog)}${limit !== null ? `\nLIMIT ${limit}` : ''};`;
   }
}
