import { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import {
   Button,
   Nav,
   NavItem,
   NavLink,
   TabContainer,
   TabContent,
   TabPane,
   Stack,
   Spinner,
} from 'react-bootstrap';

import { SERVICE_PLANS } from '@runql/util';
import { QueryVersion, DataChatMessage, DataChatThread, WorkspaceStatus } from '../entities';
import {
   Page,
   Chat,
   ChatHistory,
   ChatRef,
   AnswerCard,
   AnswersLite,
   QueryGetter,
   QueryWidget,
   QueryResults,
   ErrorBoundary,
} from '../components';
import {
   useOrg,
   usePerson,
   useWorkspaces,
   useRunQueries,
   useListDataChatThreads,
   useGetDataChatThread,
   useAddDataChatMessage,
   usePatchDataChatThread,
} from '../hooks';
import { PersonRole } from '../enums';
import { IconX } from '../utilities/icons';

export const AnswersPage = (): JSX.Element => {
   const navigate = useNavigate();
   const params = useParams();
   const threadId = Number(params.threadId);

   const org = useOrg();
   const lite = !(org?.plan && SERVICE_PLANS[org.plan].businessAnswers);
   const person = usePerson();
   const isAnalyst = person.role !== PersonRole.ORG_BUSINESS_USER;
   const workspaces = useWorkspaces()?.filter((w) => w.status === WorkspaceStatus.OPEN);
   const ref = useRef<ChatRef>(null);
   const threadsQuery = useListDataChatThreads();
   const threads = threadsQuery.data ?? [];
   const currentThreadQuery = useGetDataChatThread(threadId);
   const currentThread = currentThreadQuery.data;
   const [selectedQuery, setSelectedQuery] = useState<QueryVersion>();
   const { run, runStatus, modals } = useRunQueries();
   const { isRunning, results } = (selectedQuery && runStatus(selectedQuery)) ?? {
      isRunning: false,
      results: undefined,
   };
   const addDataChatMessage = useAddDataChatMessage();
   const patchDataChatThread = usePatchDataChatThread();

   const addMessage = (message: Partial<DataChatMessage>, thread?: Partial<DataChatThread>) => {
      addDataChatMessage.mutate(
         {
            id: message.threadId,
            message: {
               content: message.content,
               queryVersionId: message.queryVersionId,
            },
            state: thread?.state,
         },
         {
            onSuccess: (thread) => {
               navigate(`/answers/${thread.id!}`);
            },
         }
      );
   };

   const runQuery = (query: QueryVersion) => {
      setSelectedQuery(query);
      if (!runStatus(query)) {
         run({ queryVersion: query });
      }
   };

   let error;
   if (threadsQuery.isError || currentThreadQuery.isError) {
      error = 'Failed to load conversations';
   } else if (
      org?.plan === undefined ||
      workspaces === undefined ||
      threadsQuery.isLoading ||
      currentThreadQuery.isLoading
   ) {
      error = <Spinner />;
   } else if (lite && isAnalyst) {
      error =
         'Upgrade your plan to supercharge your handling of ad hoc data requests from business users.';
   } else if (isAnalyst && threads.length === 0) {
      error = 'There are no requests yet.';
   } else if (!isAnalyst && workspaces?.length === 0) {
      error =
         'You have not been added to any workspaces. Please ask your team to add you to the workspaces related to your business questions.';
   }
   if (error) {
      return (
         <Page>
            <div className="h-100 d-flex justify-content-center align-items-center">{error}</div>
         </Page>
      );
   }

   return (
      <Page
         nav={
            lite ? undefined : threads.length ? (
               <ChatHistory
                  chatRef={ref.current ?? undefined}
                  currentId={threadId}
                  noNewThread={isAnalyst}
                  onChange={(current) => {
                     navigate(current?.id ? `/answers/${current.id}` : '/answers');
                     setSelectedQuery(undefined);
                  }}
                  threads={threads}
                  title={isAnalyst ? 'Pending Requests' : 'My Answers'}
               />
            ) : undefined
         }
         noPad
         noScroll
      >
         <PanelGroup autoSaveId="answers" className="w-100 h-100" direction="vertical">
            <Panel defaultSize={40} id="chat" minSize={0} order={1}>
               {lite && <AnswersLite onRunQuery={runQuery} />}
               {!lite && (
                  <Chat
                     greeting="What would you like to know?"
                     newChatFooter={
                        <div>Your workspaces: {workspaces?.map((w) => w.name).join(', ')}</div>
                     }
                     noNewThread={isAnalyst}
                     onSend={({ content, nextState, queryVersionId }) => {
                        addMessage(
                           {
                              content,
                              queryVersionId,
                              threadId: threadId ?? undefined,
                           },
                           {
                              state: nextState,
                           }
                        );
                     }}
                     ref={ref}
                     renderQueries={(queries) =>
                        isAnalyst ? (
                           <div className="query-list two-up">
                              {queries.map((q, i) => (
                                 <QueryGetter key={i} queryVersion={q.query.queryVersion}>
                                    {(queryVersion) => (
                                       <QueryWidget
                                          collapse
                                          dataChatThreadId={threadId}
                                          onClick={async () => {
                                             patchDataChatThread.mutate({
                                                id: currentThread!.id!,
                                                data: { workspaceId: q.query.workspace.id },
                                             });
                                             return true;
                                          }}
                                          queryVersion={queryVersion}
                                          source="answers"
                                       />
                                    )}
                                 </QueryGetter>
                              ))}
                           </div>
                        ) : (
                           <div className="answers-grid">
                              {queries.map((q, i) => (
                                 <QueryGetter key={i} queryVersion={q.query.queryVersion}>
                                    {(queryVersion) => (
                                       <AnswerCard
                                          onClick={runQuery}
                                          queryVersion={queryVersion}
                                          selected={selectedQuery?.id === q.query.queryVersion.id}
                                       />
                                    )}
                                 </QueryGetter>
                              ))}
                           </div>
                        )
                     }
                     thread={currentThread ?? null}
                  />
               )}
            </Panel>
            {(isRunning || !!results?.length) && (
               <>
                  <PanelResizeHandle className="panelHandle horizontal">
                     <div className="panelHandleLine" />
                  </PanelResizeHandle>
                  <Panel
                     collapsible
                     defaultSize={60}
                     id="results"
                     minSize={20}
                     onCollapse={() => setSelectedQuery(undefined)}
                     order={2}
                  >
                     {isRunning && (
                        <Stack
                           className="h-100 w-100 justify-content-center align-items-center "
                           gap={2}
                        >
                           <Spinner />
                           <div>Loading results...</div>
                        </Stack>
                     )}
                     {!isRunning && !!results?.length && (
                        <TabContainer activeKey={'results'} onSelect={(t) => t} transition={false}>
                           <div className="h-100 d-flex flex-column">
                              <Nav
                                 className="bottom-explore-tabs explore-tabs bg-secondary"
                                 id="exploreTabNavQuery"
                                 role="tablist"
                              >
                                 <NavItem
                                    bsPrefix="explore-tab"
                                    className="bottom-explore-tab active"
                                 >
                                    <NavLink
                                       className="position-relative override-active-pointer"
                                       eventKey="results"
                                       role="tab"
                                    >
                                       Results
                                    </NavLink>
                                 </NavItem>
                                 <div className="flex-grow-1" />
                                 <div className="d-flex align-items-center">
                                    <Button
                                       onClick={() => setSelectedQuery(undefined)}
                                       size="sm"
                                       variant="link"
                                    >
                                       <IconX />
                                    </Button>
                                 </div>
                              </Nav>
                              <TabContent className="flex-grow-1 overflow-hidden bottom-query-panel-tab-content">
                                 <TabPane
                                    className="h-100 overflow-hidden"
                                    eventKey="results"
                                    key="results"
                                    mountOnEnter={true}
                                 >
                                    <ErrorBoundary
                                       title={'An error occurred when retrieving results.'}
                                    >
                                       <QueryResults
                                          queryReturn={results[results.length - 1]}
                                          readOnly
                                          simplified
                                       />
                                    </ErrorBoundary>
                                 </TabPane>
                              </TabContent>
                           </div>
                        </TabContainer>
                     )}
                  </Panel>
               </>
            )}
         </PanelGroup>
         {modals}
      </Page>
   );
};

export default AnswersPage;
