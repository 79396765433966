import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Nav, Dropdown, InputGroup, Stack } from 'react-bootstrap';
import classNames from 'classnames';
import { IconType } from 'react-icons';
import { BiChevronDown } from 'react-icons/bi';
import Loader from 'react-spinners/PulseLoader';

import { IconNewChat, IconSendChat, IconMenuWorkspaces } from '../utilities/icons';
import { Button } from '../components/Button';
import {
   PersonUtilities,
   DataChatThread,
   DataChatMessage,
   DataChatMessageQuery,
   DataChatThreadState,
   useOpenQuery,
   DataChatMessageRole,
   Person,
} from '../entities';
import { getShortDateTimeString } from '../utilities/formatters';
import { usePerson, usePatchDataChatThread } from '../hooks';
import { PersonRole } from '../enums';

export type ChatSuggestion = {
   icon?: IconType;
   label: string;
   onClick: () => void;
};

const SuggestionButton = ({ icon, label, onClick }: ChatSuggestion) => {
   const CustomIcon = icon;
   return (
      <Button colorScheme="secondary" onClick={onClick} size="md" style={{ borderRadius: '8px' }}>
         <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            {CustomIcon && <CustomIcon size="20px" />}
            <span style={{ fontSize: '14px', fontWeight: '400' }}>{label}</span>
         </div>
      </Button>
   );
};

const ChatStart = forwardRef(
   (
      {
         greeting,
         onSend,
         suggestions,
         footer,
      }: {
         footer?: React.ReactNode;
         greeting?: string;
         onSend: (message: { content?: string; queryVersionId?: number }) => void;
         suggestions?: ChatSuggestion[];
      },
      ref
   ): JSX.Element => {
      const inputRef = useRef<ChatInputRef>(null);
      useImperativeHandle(ref, () => ({
         setPrompt: (p: string) => inputRef.current?.setPrompt(p),
      }));
      return (
         <Stack
            className="w-100 h-100 justify-content-center align-items-center position-relative"
            gap={3}
         >
            <h1 className="m-0" style={{ opacity: '75%', fontSize: '30px', fontWeight: '600' }}>
               {greeting ?? 'How can I help?'}
            </h1>
            <ChatInput big onSend={onSend} prompt="Ask Runa a question" ref={inputRef} />
            {!!suggestions?.length && (
               <Stack className="justify-content-center" direction="horizontal" gap={2}>
                  {suggestions.map((suggestion, index) => (
                     <SuggestionButton key={index} {...suggestion} />
                  ))}
               </Stack>
            )}
            <div className="position-absolute w-100 py-2 px-3" style={{ bottom: '0' }}>
               {footer}
            </div>
         </Stack>
      );
   }
);

const ChatBubble = ({
   message,
   person,
   side,
}: {
   message: DataChatMessage;
   person: Person;
   side: 'ours' | 'theirs';
}): JSX.Element => {
   return (
      <div
         className={classNames('card py-2 px-3', side === 'ours' ? 'bg-secondary' : 'bg-primary')}
      >
         <Stack gap={2}>
            <div>{message.content}</div>

            <Stack
               className="query-card-footer fs-9p align-items-end w-100 text-muted"
               direction="horizontal"
               gap={1}
            >
               <div>{getShortDateTimeString(message.created)}</div>
               <div className="fw-500 potential-badge">– {PersonUtilities.getFullName(person)}</div>
            </Stack>
         </Stack>
      </div>
   );
};

type ChatInputRef = { setPrompt: (prompt: string) => void };

const ChatInput = forwardRef<
   ChatInputRef,
   {
      big?: boolean;
      disabled?: boolean;
      onSend: (message: { content?: string; queryVersionId?: number }) => void;
      prompt: string;
   }
>(({ big, disabled, onSend, prompt }, ref): JSX.Element => {
   const [message, setMessage] = useState<string>('');
   const inputRef = useRef<HTMLInputElement>(null);

   useImperativeHandle(ref, () => ({
      setPrompt: (p) => {
         setMessage(p);
         inputRef.current?.focus();
      },
   }));

   return (
      <Stack className="align-items-center justify-content-center" direction="horizontal" gap={2}>
         <Form
            className="w-100 align-items-center"
            onSubmit={(e) => {
               e.preventDefault();
               onSend({ content: message });
               setMessage('');
            }}
            style={{ maxWidth: big ? '768px' : undefined }}
         >
            <InputGroup>
               <Form.Control
                  autoFocus
                  className={classNames(big ? 'form-control fs-14p' : 'small-form-control-input')}
                  disabled={disabled}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={prompt}
                  ref={inputRef}
                  value={message}
               />
               <button
                  className="btn btn-xs btn-primary plausible-event-name--askRuna"
                  disabled={disabled}
                  type="submit"
               >
                  <IconSendChat size={big ? '18px' : '16px'} />
               </button>
            </InputGroup>
         </Form>
      </Stack>
   );
});

export const ChatHistory = ({
   chatRef,
   threads,
   currentId,
   onChange,
   title,
   noNewThread,
}: {
   chatRef?: ChatRef;
   currentId: number | null;
   noNewThread?: boolean;
   onChange: (thread: DataChatThread | null) => void;
   threads: DataChatThread[];
   title: string;
}): JSX.Element => {
   const person = usePerson();
   return (
      <Nav className="h-100 overflow-y-auto flex-column navigation-menu-width pt-1">
         <Stack
            className="m-2 mt-0 justify-content-between align-items-center"
            direction="horizontal"
            gap={2}
         >
            <div className="fw-bold fs-16">{title}</div>
            {!noNewThread && (
               <Button
                  colorScheme="secondary"
                  onClick={() => onChange(null)}
                  size="sm"
                  style={{ height: '100%' }}
               >
                  <IconNewChat size="16px" />
               </Button>
            )}
         </Stack>
         {threads.map((thread) => (
            <Nav.Link
               active={thread.id === currentId}
               key={thread.id}
               onClick={(e) => {
                  e.preventDefault();
                  onChange(thread);
                  chatRef?.setPrompt('');
               }}
            >
               <Stack gap={1}>
                  <div className="truncate-lines truncate-lines-2">{thread.title}</div>
                  {person.id !== thread.creator?.id && (
                     <div className="text-nowrap">
                        {PersonUtilities.getFullName(thread.creator)}
                     </div>
                  )}
                  <div className="text-nowrap">
                     {getShortDateTimeString(thread.latestMessageAt)}
                  </div>
               </Stack>
            </Nav.Link>
         ))}
      </Nav>
   );
};

export type ChatRef = ChatInputRef;

export const Chat = forwardRef(
   (
      {
         greeting,
         onSend,
         renderQueries,
         suggestions,
         thread,
         newChatFooter,
         noNewThread,
         currentQueryVersionId,
      }: {
         currentQueryVersionId?: number;
         greeting?: string;
         newChatFooter?: React.ReactNode;
         noNewThread?: boolean;
         onSend: (message: {
            content?: string;
            nextState?: DataChatThreadState;
            queryVersionId?: number;
         }) => void;
         renderQueries: (queries: DataChatMessageQuery[]) => React.ReactNode;
         suggestions?: ChatSuggestion[];
         thread: DataChatThread | null;
      },
      ref
   ): JSX.Element => {
      const inputRef = useRef<ChatInputRef>(null);
      const messages = thread?.messages ?? [];
      const runaResponding =
         thread?.state === DataChatThreadState.NEW &&
         messages.length > 0 &&
         messages[messages.length - 1].role === DataChatMessageRole.CREATOR;
      const scrollRef = useRef<HTMLDivElement | null>(null);
      const person = usePerson();
      const openQuery = useOpenQuery();

      const newQuery = () =>
         openQuery({
            workspaceId: thread?.workspaceId,
            dataChatThreadId: thread?.id,
            newTab: true,
            source: 'answers',
            queryVersion: {
               steps: [],
            },
         });

      useImperativeHandle(ref, () => ({
         setPrompt: (p: string) => inputRef.current?.setPrompt(p),
      }));

      // Scroll to the bottom when Runa responds
      useEffect(() => {
         scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, [runaResponding, messages.length]);

      const patchDataChatThread = usePatchDataChatThread();

      if (messages.length === 0) {
         if (noNewThread) {
            return (
               <div className="h-100 d-flex justify-content-center align-items-center">
                  Select a thread.
               </div>
            );
         } else {
            return (
               <ChatStart
                  footer={newChatFooter}
                  greeting={greeting}
                  onSend={onSend}
                  ref={inputRef}
                  suggestions={suggestions}
               />
            );
         }
      }
      const lastMessage = messages[messages.length - 1];
      const responses = lastMessage.metadata?.success
         ? lastMessage.metadata?.data.suggestedResponses
         : undefined;
      return (
         <div className="h-100 d-flex flex-column justify-content-end">
            <Stack className="p-3 pb-0 overflow-auto flex-grow-0" gap={3}>
               {messages.map((message, index) => (
                  <Stack className="flex-grow-0" gap={2} key={index}>
                     <Stack
                        gap={2}
                        ref={
                           !runaResponding && index === messages.length - 1 ? scrollRef : undefined
                        }
                     >
                        {message.content && (
                           <ChatBubble
                              message={message}
                              person={
                                 message.role === DataChatMessageRole.CREATOR
                                    ? thread?.creator ?? person
                                    : message.role === DataChatMessageRole.SYSTEM
                                    ? {
                                         firstName: 'Runa',
                                      }
                                    : message.person ?? {
                                         firstName: 'Unknown',
                                      }
                              }
                              side={
                                 (person.role === PersonRole.ORG_BUSINESS_USER) ===
                                 (message.role === DataChatMessageRole.CREATOR)
                                    ? 'ours'
                                    : 'theirs'
                              }
                           />
                        )}
                        {!!message.messageQueries?.length && renderQueries(message.messageQueries)}
                     </Stack>
                  </Stack>
               ))}
               {runaResponding && <Loader color="#6366f1" size={6} />}
               {runaResponding && <div ref={scrollRef} />}
            </Stack>
            <Stack className="flex-grow-0 p-3 pt-3" gap={2}>
               {responses && (
                  <Stack direction="horizontal" gap={2}>
                     {responses.map((response, i) => (
                        <Button key={i} onClick={() => onSend(response)}>
                           {response.content}
                        </Button>
                     ))}
                  </Stack>
               )}
               {!currentQueryVersionId &&
                  person.role !== PersonRole.ORG_BUSINESS_USER &&
                  thread?.creatorWorkspaces && (
                     <Stack className="justify-content-end" direction="horizontal" gap={2}>
                        <Dropdown>
                           <Dropdown.Toggle size="sm" variant="secondary">
                              <IconMenuWorkspaces className="me-1" size="12px" />
                              {
                                 thread.creatorWorkspaces.find((w) => w.id === thread.workspaceId)
                                    ?.name
                              }
                              <BiChevronDown size={16} />
                           </Dropdown.Toggle>
                           <Dropdown.Menu className="fs-10p">
                              {thread.creatorWorkspaces.map((w) => (
                                 <Dropdown.Item
                                    active={thread.workspaceId === w.id}
                                    key={w.id}
                                    onClick={() => {
                                       patchDataChatThread.mutate({
                                          id: thread.id!,
                                          data: { workspaceId: w.id },
                                       });
                                    }}
                                 >
                                    {w.name}
                                 </Dropdown.Item>
                              ))}
                           </Dropdown.Menu>
                        </Dropdown>
                        <Button onClick={newQuery}>New Query</Button>
                     </Stack>
                  )}
               {currentQueryVersionId && (
                  <Stack className="justify-content-end" direction="horizontal" gap={2}>
                     <Button
                        onClick={() => {
                           onSend({ queryVersionId: currentQueryVersionId });
                        }}
                        size="sm"
                     >
                        Send Query
                     </Button>
                  </Stack>
               )}
               {!responses &&
                  (!thread ||
                     !(
                        person.role === PersonRole.ORG_BUSINESS_USER &&
                        [DataChatThreadState.CANCELLED, DataChatThreadState.ACCEPTED].includes(
                           thread?.state
                        )
                     )) && (
                     <ChatInput
                        onSend={onSend}
                        prompt={
                           person.role === PersonRole.ORG_BUSINESS_USER
                              ? thread?.state === DataChatThreadState.NEW
                                 ? 'Ask Runa a question'
                                 : thread?.assignee
                                 ? `Respond to ${thread.assignee.firstName}`
                                 : 'Add a message'
                              : thread?.creator
                              ? `Respond to ${thread.creator.firstName}`
                              : 'Add a message'
                        }
                        ref={inputRef}
                     />
                  )}
            </Stack>
         </div>
      );
   }
);

export default Chat;
