/* eslint-disable typescript-sort-keys/string-enum */
import { ColumnDefinition, ColumnModify, QueryBuilder } from './QueryBuilder';

export interface MsSQLColumnModify extends ColumnModify {
   description?: string;
}

export enum MsSQLDataType {
   // Exact Numerics
   TINYINT = 'TINYINT',
   SMALLINT = 'SMALLINT',
   INT = 'INT',
   BIGINT = 'BIGINT',
   BIT = 'BIT',
   DECIMAL = 'DECIMAL',
   SMALLDECIMAL = 'SMALLDECIMAL',
   NUMERIC = 'NUMERIC',
   MONEY = 'MONEY',
   SMALLMONEY = 'SMALLMONEY',

   // Approximate Numerics
   FLOAT = 'FLOAT',
   REAL = 'REAL',

   // Date and Time
   DATE = 'DATE',
   DATETIME = 'DATETIME',
   DATETIME2 = 'DATETIME2',
   DATETIMEOFFSET = 'DATETIMEOFFSET',
   SMALLDATETIME = 'SMALLDATETIME',
   TIME = 'TIME',

   // Character Strings
   CHAR = 'CHAR',
   VARCHAR = 'VARCHAR',
   TEXT = 'TEXT',

   // Unicode Character Strings
   NCHAR = 'NCHAR',
   NVARCHAR = 'NVARCHAR',
   NTEXT = 'NTEXT',

   // Binary Strings
   BINARY = 'BINARY',
   VARBINARY = 'VARBINARY',
   IMAGE = 'IMAGE',

   // Other Data Types
   XML = 'XML',
   GEOMETRY = 'GEOMETRY',
   GEOGRAPHY = 'GEOGRAPHY',
   HIERARCHYID = 'HIERARCHYID',
   UNIQUEIDENTIFIER = 'UNIQUEIDENTIFIER',
   JSON = 'JSON',
   ROWVERSION = 'ROWVERSION',
   SQL_VARIANT = 'SQL_VARIANT',

   // Special Data Types
   CURSOR = 'CURSOR',
   TABLE = 'TABLE',
}

export const MsSQLNumericColumns = [
   MsSQLDataType.TINYINT,
   MsSQLDataType.SMALLINT,
   MsSQLDataType.INT,
   MsSQLDataType.BIGINT,
   MsSQLDataType.BIT,
   MsSQLDataType.DECIMAL,
   MsSQLDataType.NUMERIC,
   MsSQLDataType.MONEY,
   MsSQLDataType.SMALLMONEY,
   MsSQLDataType.FLOAT,
   MsSQLDataType.REAL,
];

export class MsSQLQueryBuilder extends QueryBuilder {
   public wrapIdentifier(identifier: string): string {
      //check if identifier is already wrapped
      if (identifier.startsWith('[') && identifier.endsWith(']')) {
         return identifier;
      }
      return `[${identifier}]`;
   }

   public qualifyTable(table: string, schema?: string, catalog?: string, asString = false): string {
      let result = '';
      if (catalog) {
         if (asString) result += `${catalog}.`;
         else result += `[${catalog}].`;
      }

      if (schema) {
         if (asString) result += `${schema}.`;
         else result += `[${schema}].`;
      }

      if (asString) result = result += `${table}`;
      else result += `[${table}]`;

      return result;
   }

   public beginTransaction(): string {
      return 'BEGIN TRANSACTION;';
   }

   public isTypeNumeric(column: ColumnDefinition): boolean {
      return MsSQLNumericColumns.includes(column.dataType as MsSQLDataType);
   }

   public alterTable({
      columns,
      table,
      schema,
      catalog,
   }: {
      catalog?: string;
      columns: Record<string, { definition?: ColumnDefinition; modify: MsSQLColumnModify }>;
      schema?: string;
      table: string;
   }): string {
      const alterTable = `ALTER TABLE ${this.qualifyTable(table, schema, catalog)} `;
      const columnChanges: string[] = [];
      const secondaryQueries: string[] = [];
      Object.entries(columns).forEach(([column, { modify, definition }]) => {
         const dataType = modify.dataType ?? definition?.dataType;
         const defaultVal =
            modify.default || definition?.default
               ? ` DEFAULT ${modify.default ?? definition?.default}`
               : '';
         const nullable = modify.nullable ?? definition?.nullable ?? true ? ' NULL' : ' NOT NULL';

         if (modify.isNew) {
            // Create column
            columnChanges.push(
               `${alterTable} ADD ${this.wrapIdentifier(
                  column
               )} ${dataType}${defaultVal}${nullable};`
            );

            if (modify.description) {
               secondaryQueries.push(
                  `EXEC ${catalog}.sys.sp_addextendedproperty 'MS_Description', '${modify.description}', 'SCHEMA', '${schema}', 'TABLE', '${table}', 'COLUMN', '${column}';`
               );
            }
         } else if (modify.drop) {
            if (definition?.defaultConstraint) {
               columnChanges.push(
                  `${alterTable} DROP CONSTRAINT ${this.wrapIdentifier(
                     definition.defaultConstraint
                  )};`
               );
            }
            // Drop column
            columnChanges.push(`${alterTable} DROP COLUMN ${this.wrapIdentifier(column)};`);
         } else {
            // Modify column
            if (!definition) {
               throw new Error(`ColumnDefinition is required to edit columns`);
            }

            if (modify.dataType || modify.nullable) {
               columnChanges.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(
                     column
                  )} ${dataType}${nullable};`
               );
            }

            if (modify.default) {
               if (definition.defaultConstraint) {
                  columnChanges.push(
                     `${alterTable} DROP CONSTRAINT ${this.wrapIdentifier(
                        definition.defaultConstraint
                     )};`
                  );
               }
               columnChanges.push(
                  `${alterTable} ADD DEFAULT ${modify.default} FOR ${this.wrapIdentifier(column)};`
               );
            }

            if (modify.description) {
               secondaryQueries.push(
                  `EXEC ${catalog}.sys.sp_updateextendedproperty 'MS_Description', '${modify.description}', 'SCHEMA', '${schema}', 'TABLE', '${table}', 'COLUMN', '${column}';`
               );
            }

            if (modify.rename) {
               secondaryQueries.push(
                  `EXEC ${catalog}.sys.sp_rename N'${this.qualifyTable(
                     table,
                     schema,
                     catalog,
                     true
                  )}.${column}', N'${modify.rename}', 'COLUMN';`
               );
            }
         }
      });

      let result = '';
      if (columnChanges.length > 0) {
         result = `${columnChanges.join('\n')}`;
      }
      if (secondaryQueries.length > 0) {
         if (result.length > 0) result += '\n';
         result += `${secondaryQueries.join('\n')}`;
      }
      return result;
   }

   public explainQuery(query: string, analyze?: boolean): string {
      return `SET SHOWPLAN_TEXT ON;\nGO\n ${query};\nGO`;
   }

   // -- Create Table

   protected increments(
      name: string = 'id',
      { nullable = true, primaryKey = true }: { nullable?: boolean; primaryKey?: boolean } = {}
   ) {
      return `${this.wrapIdentifier(name)} int identity (1, 1)${
         nullable || primaryKey ? ' not null' : ''
      }${primaryKey ? ' primary key' : ''}`;
   }

   // -- Fetch Table Content

   public fetchTableContent(
      {
         catalog,
         schema,
         table,
      }: {
         catalog?: string;
         schema: string;
         table: string;
      },
      { limit = 10_000 }: { limit?: number | null } = {}
   ) {
      return `SELECT${limit !== null ? ` TOP ${limit}` : ''} *
FROM ${this.qualifyTable(table, schema, catalog)};`;
   }
}
