import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Collapse, Form, InputGroup, Button, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import LoadingError from '../../../../components/UI/LoadingError';
import LoadingSpinner from '../../../../components/UI/LoadingSpinner';
import { ConnectionAccessType, SSLMode } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { getErrorMessage, IconX } from '../../../../utilities';
import {
   connectionName,
   description,
   host,
   withHostRefinement,
   password,
   port,
   username,
} from '../validators';
import {
   AccessTypeField,
   HideDetailsField,
   SSHConnectionFields,
   SSHCredentialFields,
} from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';
import { SSHAuthenticationMethod } from '@runql/util';
import { desktopFeatureAvailable, isDesktop } from '../../../../services/DesktopQueryService';

const credentialsSchema = z.object({
   accountName: username,
   password: password,
});

const postgreSqlSchema = withHostRefinement(
   z
      .object({
         connectionAccessType: z.nativeEnum(ConnectionAccessType),
         dbms: z.literal(DBMS.Postgres),
         connectionName: connectionName,
         description: description,
         host: host,
         port: port,
         database: z
            .string()
            .min(1, 'required')
            .trim()
            .regex(/^[^.]*$/, 'Database cannot specify a schema'),
         sharedConnection: z.boolean().default(false),
         hideDetails: z.boolean().default(false),
         sslCaCert: z.any().optional(),
         sslCaCertId: z.number().optional(),
         useSSL: z.boolean().default(false),
         useSSH: z.boolean().default(false),
         sshHost: z.string().optional(),
         sshPort: z.number().optional(),
         sshUsername: z.string().optional(),
         sshPassword: z.string().optional(),
         sshAuthMethod: z
            .nativeEnum(SSHAuthenticationMethod)
            .default(SSHAuthenticationMethod.PASSWORD),
         sshKeyFile: z.string().trim().optional(),
      })
      .merge(credentialsSchema)
);

type PostgreSqlDetailFormData = z.infer<typeof postgreSqlSchema>;

function PostgreSqlDetailForm({
   editType,
   formId,
   isSaving,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   isSaving?: boolean;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register form
   const formMethods = useForm<PostgreSqlDetailFormData>({
      resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : postgreSqlSchema),
      mode: 'onTouched',
      defaultValues: {
         sshUsername: '',
         sshPassword: '',
         sshHost: '',
         sshPort: 22,
         sshAuthMethod: SSHAuthenticationMethod.PASSWORD,
         sshKeyFile: '',
      },
   });
   const { handleSubmit, register, formState, reset, setValue, setError, watch, clearErrors } =
      formMethods;
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;
   const useSSH = !!watch('useSSH', false);
   const sshAvailable = desktopFeatureAvailable('postgresSSH');

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   const useSSL = !!watch('useSSL', false);
   const sslCaCertId = watch('sslCaCertId', undefined);
   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);
   const isSshPermitted = sshAvailable && connectionAccessType === ConnectionAccessType.INDIVIDUAL;

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      console.log(
         'selectedConnectionQuery.data.dataCredentials?.[0].sshPassword',
         selectedConnectionQuery?.data?.dataCredentials?.[0].sshPassword
      );

      if (selectedConnectionQuery.data) {
         const dataCredential = selectedConnectionQuery.data.dataCredentials?.[0];

         const sshAuthMethod =
            dataCredential?.sshAuthMethod === SSHAuthenticationMethod.KEY_FILE &&
            typeof dataCredential?.sshKeyFile === 'string' &&
            dataCredential.sshKeyFile.length > 0
               ? SSHAuthenticationMethod.KEY_FILE
               : SSHAuthenticationMethod.PASSWORD;

         const formData: PostgreSqlDetailFormData = {
            dbms: DBMS.Postgres,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? ''),
            database: selectedConnectionQuery.data.catalogName ?? '',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            accountName: dataCredential?.accountName ?? '',
            password: dataCredential?.accountPassword === undefined ? '' : 'CURRENT',
            useSSL: selectedConnectionQuery.data.sslMode === SSLMode.VALIDATED,
            sslCaCertId: selectedConnectionQuery.data.sslCaCertId ?? undefined,

            useSSH: selectedConnectionQuery.data.useSSH ?? false,
            sshHost: selectedConnectionQuery.data.sshHost ?? '',
            sshPort: parseInt(selectedConnectionQuery.data.sshPort ?? '22'),
            sshAuthMethod,
            sshUsername: dataCredential?.sshUsername ?? '',
            sshPassword: dataCredential?.sshPassword === undefined ? '' : 'CURRENT',
            sshKeyFile:
               sshAuthMethod === SSHAuthenticationMethod.KEY_FILE
                  ? dataCredential?.sshKeyFile ?? ''
                  : undefined,
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data?.person.role]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = (data: PostgreSqlDetailFormData) => {
      if (onSubmit) {
         onSubmit(data);
      }
   };

   const [clearedFields, setClearedFields] = useState<string[]>([]);

   const handleFocus =
      (checkValue: string, setValueOnFocus: string = '') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (e.target.value === checkValue) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof PostgreSqlDetailFormData, setValueOnFocus); // Update form state
            setClearedFields([...clearedFields, e.target.name]);
         }
      };

   const handleBlur =
      (checkValue: string = '', setValueOnFocus: string = 'CURRENT', touchedField: string) =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (
            e.target.value === checkValue &&
            !(touchedField in touchedFields) &&
            clearedFields.includes(touchedField)
         ) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof PostgreSqlDetailFormData, setValueOnFocus); // Update form state
         } else if (!e.target.value) {
            setError(touchedField as keyof PostgreSqlDetailFormData, {
               type: 'manual',
               message: 'This field is required',
            });
            setValue(e.target.name as keyof PostgreSqlDetailFormData, undefined, {
               shouldDirty: true,
               shouldTouch: true,
            });
         }
      };

   // Render
   if (authPersonQuery.isLoading || selectedConnectionQuery.isLoading || isSaving)
      return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   return (
      <FormProvider {...formMethods}>
         <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
            <Stack gap={3}>
               {!onlyCreds && (
                  <>
                     <input defaultValue={DBMS.Postgres} type="hidden" {...register('dbms')} />
                     <Form.Group>
                        <Form.Label>
                           Connection Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('connectionName')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.connectionName && !!errors.connectionName}
                           isValid={touchedFields.connectionName && !errors.connectionName}
                           placeholder="Connection Name"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.connectionName?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                           {...register('description')}
                           as="textarea"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.description && !!errors.description}
                           isValid={touchedFields.description && !errors.description}
                           placeholder="Description"
                           rows={3}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.description?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>
                           Host <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('host')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.host && !!errors.host}
                           isValid={touchedFields.host && !errors.host}
                           placeholder="Host"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.host?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>
                           Port <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('port', { valueAsNumber: true })}
                           defaultValue="5432"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.port && !!errors.port}
                           isValid={touchedFields.port && !errors.port}
                           placeholder="Port"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.port?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>
                           Database <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('database')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.database && !!errors.database}
                           isValid={touchedFields.database && !errors.database}
                           placeholder="Database"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.database?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>SSL Mode</Form.Label>
                        <Form.Select
                           disabled={editType !== 'connection'}
                           {...register('useSSL', {
                              setValueAs: (v) => v === true || v === 'true',
                           })}
                        >
                           <option value="false">Use SSL if available</option>
                           <option value="true">Require SSL and validate certificates</option>
                        </Form.Select>
                     </Form.Group>
                     <Collapse in={useSSL}>
                        <Form.Group>
                           <Form.Label>SSL CA Certificate</Form.Label>
                           {sslCaCertId ? (
                              <InputGroup>
                                 <Form.Control placeholder="Uploaded file" readOnly type="text" />
                                 <Button
                                    onClick={() =>
                                       setValue('sslCaCertId', undefined, { shouldDirty: true })
                                    }
                                    variant="secondary"
                                 >
                                    <IconX />
                                 </Button>
                              </InputGroup>
                           ) : (
                              <Form.Control {...register('sslCaCert')} type="file" />
                           )}
                        </Form.Group>
                     </Collapse>
                     <Form.Group>
                        <Form.Label>Use SSH</Form.Label>
                        {connectionAccessType === ConnectionAccessType.SHARED ? (
                           <div>SSH is not available for shared connections</div>
                        ) : !isDesktop() ? (
                           <div>SSH available in desktop application</div>
                        ) : !sshAvailable ? (
                           <div>Please update for SSH support</div>
                        ) : (
                           <Form.Check
                              {...register('useSSH', {
                                 onChange() {
                                    clearErrors('sshHost');
                                    clearErrors('sshPort');
                                    clearErrors('sshUsername');
                                    clearErrors('sshPassword');
                                 },
                              })}
                              type="switch"
                           />
                        )}
                        <Form.Control.Feedback type="invalid">
                           {errors.useSSH?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Collapse in={useSSH}>
                        <div>
                           <SSHConnectionFields useSSH={useSSH} />
                        </div>
                     </Collapse>
                     <AccessTypeField
                        disabled={editType !== 'connection'}
                        {...register('connectionAccessType', {
                           setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                           onChange(event) {
                              const value = parseInt(event.target.value);
                              if (value === ConnectionAccessType.INDIVIDUAL) {
                                 setValue('hideDetails', false, { shouldDirty: true });
                              } else if (value === ConnectionAccessType.SHARED) {
                                 // Shared connections should not have SSH enabled
                                 setValue('useSSH', false, { shouldDirty: true });
                              }

                              setValue('accountName', '', { shouldDirty: true });
                              setValue('password', '', { shouldDirty: true });
                           },
                        })}
                     />
                     {explorerIsAdmin && (
                        <Collapse in={connectionAccessType === ConnectionAccessType.SHARED}>
                           <div>
                              <HideDetailsField
                                 {...register('hideDetails')}
                                 disabled={editType !== 'connection'}
                              />
                           </div>
                        </Collapse>
                     )}
                  </>
               )}
               <Form.Group>
                  <Form.Label>User Name</Form.Label> <span className="text-danger">*</span>
                  <Form.Control
                     {...register('accountName')}
                     disabled={editType === 'read-only'}
                     isInvalid={touchedFields.accountName && !!errors.accountName}
                     isValid={touchedFields.accountName && !errors.accountName}
                     placeholder="User Name"
                     required={true}
                  />
                  <Form.Control.Feedback type="invalid">
                     {errors.accountName?.message}
                  </Form.Control.Feedback>
               </Form.Group>
               <Form.Group>
                  <Form.Label>Password</Form.Label> <span className="text-danger">*</span>
                  <Form.Control
                     {...register('password')}
                     disabled={editType === 'read-only'}
                     isInvalid={touchedFields.password && !!errors.password}
                     isValid={touchedFields.password && !errors.password}
                     onBlur={handleBlur('', 'CURRENT', 'password')}
                     onFocus={handleFocus('CURRENT', '')}
                     placeholder="Password"
                     required={true}
                     type="password"
                  />
                  <Form.Control.Feedback type="invalid">
                     {errors.password?.message}
                  </Form.Control.Feedback>
               </Form.Group>

               <Collapse in={useSSH}>
                  <div>
                     <SSHCredentialFields
                        editType={editType}
                        isSshKeyFilePermitted={isSshPermitted}
                        useSSH={useSSH}
                     />
                  </div>
               </Collapse>
            </Stack>
         </Form>
      </FormProvider>
   );
}

export default PostgreSqlDetailForm;
