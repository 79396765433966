import { Container } from 'inversify';

import {
   ApiService,
   DataConnectionService,
   DataCredentialService,
   ExploreTabService,
   FeatureService,
   InviteService,
   OrgService,
   PersonService,
   QueryCommentService,
   QueryLogService,
   QuerySavedCustomTagService,
   QueryService,
   SchemaCacheService,
   SearchService,
   SnippetService,
   AskService,
   StatsService,
   SubscriptionService,
   WalkthroughService,
   WorkspaceCollaboratorService,
   WorkspaceSchemaConnectionService,
   WorkspaceLogService,
   WorkspaceService,
   DesktopQueryService,
   DataChatService,
} from './services';
import { OrgRolePermissionService } from './services/OrgRolePermissionService';
import {
   WebLocalCredentialService,
   DesktopLocalCredentialService,
} from './services/LocalCredentialService';
import { isDesktop } from './services/DesktopQueryService';
import { TYPES } from './types';

import type { LocalCredentialService } from './services/LocalCredentialService';

const container = new Container();

container.bind<ApiService>(TYPES.apiService).to(ApiService).inSingletonScope();

container.bind<ExploreTabService>(TYPES.exploreTabService).to(ExploreTabService).inSingletonScope();

container
   .bind<DataConnectionService>(TYPES.dataConnectionService)
   .to(DataConnectionService)
   .inSingletonScope();

container
   .bind<DataCredentialService>(TYPES.dataCredentialService)
   .to(DataCredentialService)
   .inSingletonScope();

container.bind<QueryService>(TYPES.queryService).to(QueryService).inSingletonScope();

container.bind<WorkspaceService>(TYPES.workspaceService).to(WorkspaceService).inSingletonScope();

container
   .bind<WorkspaceCollaboratorService>(TYPES.workspaceCollaboratorService)
   .to(WorkspaceCollaboratorService)
   .inSingletonScope();

container.bind<PersonService>(TYPES.personService).to(PersonService).inSingletonScope();

container.bind<QueryLogService>(TYPES.querylogService).to(QueryLogService).inSingletonScope();

container.bind<OrgService>(TYPES.orgService).to(OrgService).inSingletonScope();

container.bind<SearchService>(TYPES.searchService).to(SearchService).inSingletonScope();

container
   .bind<WorkspaceLogService>(TYPES.workspaceLogService)
   .to(WorkspaceLogService)
   .inSingletonScope();

container.bind<StatsService>(TYPES.statsService).to(StatsService).inSingletonScope();

container
   .bind<SubscriptionService>(TYPES.subscriptionService)
   .to(SubscriptionService)
   .inSingletonScope();

container
   .bind<QuerySavedCustomTagService>(TYPES.querySavedCustomTagService)
   .to(QuerySavedCustomTagService)
   .inSingletonScope();

container
   .bind<WorkspaceSchemaConnectionService>(TYPES.workspaceSchemaConnectionService)
   .to(WorkspaceSchemaConnectionService)
   .inSingletonScope();

container.bind<FeatureService>(TYPES.featureService).to(FeatureService).inSingletonScope();
container
   .bind<WalkthroughService>(TYPES.walkthroughService)
   .to(WalkthroughService)
   .inSingletonScope();

container.bind<AskService>(TYPES.askService).to(AskService).inSingletonScope();

container.bind<InviteService>(TYPES.inviteService).to(InviteService).inSingletonScope();

container.bind<SnippetService>(TYPES.snippetService).to(SnippetService).inSingletonScope();

container
   .bind<OrgRolePermissionService>(TYPES.orgRolePermissionService)
   .to(OrgRolePermissionService)
   .inSingletonScope();

container
   .bind<SchemaCacheService>(TYPES.schemaCacheService)
   .to(SchemaCacheService)
   .inSingletonScope();

container
   .bind<QueryCommentService>(TYPES.queryCommentService)
   .to(QueryCommentService)
   .inSingletonScope();

container
   .bind<LocalCredentialService>(TYPES.localCredentialService)
   .to(isDesktop() ? DesktopLocalCredentialService : WebLocalCredentialService)
   .inSingletonScope();

container
   .bind<DesktopQueryService>(TYPES.desktopQueryService)
   .to(DesktopQueryService)
   .inSingletonScope();

container.bind<DataChatService>(TYPES.dataChatService).to(DataChatService).inSingletonScope();

export default container;
