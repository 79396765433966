import React, { useState } from 'react';
import { Collapse, Modal, Stack } from 'react-bootstrap';
import { Button } from '../../../../components';
import CodeViewer from '../../../../components/UI/CodeViewer';
import { useOptionalExploreTab } from '../../../../hooks';

export const ConfirmChangesModal = ({
   changeCount,
   onClose,
   onConfirm,
   query,
   running,
   show,
}: {
   changeCount?: number;
   onClose?: () => void;
   onConfirm?: () => void;
   query?: string[];
   running?: boolean;
   show: boolean;
}): JSX.Element => {
   const [showQuery, setShowQuery] = useState(false);
   const exploreTab = useOptionalExploreTab();
   return (
      <Modal
         onHide={() => {
            onClose?.();
         }}
         show={show}
         size="lg"
      >
         <Modal.Header closeButton>
            <Modal.Title className="fs-14p">Apply Data Changes</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="fs-12p">
               <div className="row">
                  <div className="col-2 text-end">Host:</div>
                  <div className="col-10">
                     {exploreTab?.tableSchema?.dataConnection?.name &&
                        `${exploreTab?.tableSchema?.dataConnection?.name}`}
                  </div>
               </div>
               <div className="row">
                  <div className="col-2 text-end">Database:</div>
                  <div className="col-10">
                     {(exploreTab?.tableSchema?.schemaName || exploreTab?.tableSchema?.tableName) &&
                        `${
                           exploreTab.tableSchema?.schemaName &&
                           `${exploreTab.tableSchema.schemaName}.`
                        }${exploreTab.tableSchema?.tableName}`}
                  </div>
               </div>
            </div>
            <Stack className="mt-3 justify-content-end" direction="horizontal" gap={2}>
               <Button
                  colorScheme="secondary"
                  message={`${showQuery ? `Hide` : `Show`} Query`}
                  onClick={() => {
                     setShowQuery(!showQuery);
                  }}
                  size="sm"
               />

               <Button
                  colorScheme="secondary"
                  disabled={running}
                  message="Cancel"
                  onClick={onClose}
                  size="sm"
               />
               <Button
                  disabled={running}
                  isLoading={running}
                  loadingMessage="Saving..."
                  message="Apply Changes"
                  onClick={onConfirm}
                  size="sm"
               />
            </Stack>
            {query && query.length > 0 && (
               <Collapse in={showQuery}>
                  <div className="card query-card border-1 mt-3 px-3 fs-14p">
                     <CodeViewer query={query.join('\n').trim()} />
                  </div>
               </Collapse>
            )}
         </Modal.Body>
      </Modal>
   );
};
