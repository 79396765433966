import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { Nav, NavItem, NavLink, TabContainer, TabContent, TabPane } from 'react-bootstrap';

import { BiCollapseVertical } from 'react-icons/bi';
import { RiExpandUpDownLine } from 'react-icons/ri';
import {
   AiPulse,
   QueryAdmin,
   QueryDocs,
   QueryLogList,
   QuerySuggestions,
   QueryVersionList,
   RunSource,
} from '../components';
import { QueryStep, QueryVersion, StepType, WorkspaceStatus } from '../entities';
import { walkthroughStep } from '../entities/Walkthrough';
import { useCurrentQuery, useCurrentStep, useOptionalWorkspace } from '../hooks';
import { useQueryPanelContext } from '../hooks/QueryPanelContext';
import { useWalkthroughStep } from '../hooks/walkthrough';
import { QueryReturn } from '../interfaces';
import AskRuna from './AskRuna';
import ErrorBoundary from './ErrorBoundary';
import { QueryChart } from './QueryChart';
import { QueryComments } from './QueryComments';
import QueryParams from './QueryParams';
import QueryResultsTabs from './QueryResultsTabs';
import RequestsTabContent from './RequestsTabContent';

export const queryPanelTabs = {
   comments: 'Comments',
   docs: 'Documentation',
   runSource: 'runSource',
   versions: 'Versions',
   logs: 'Logs',
   admin: 'Admin',
   requests: 'Requests',
   gap: null,
   runa: 'Ask Runa',
   suggestions: 'Suggestions',
   params: 'Parameters',
   results: 'Data Results',
   chart: 'Chart',
};

export type Tab = {
   body: React.ReactNode;
   title: string;
};

export const QueryPanel = forwardRef(
   (
      {
         allowDocGeneration = false,
         collapsePanel,
         expandPanel,
         extraTabs,
         hideTabs,
         isGeneratingDocumentation,
         isGeneratingExplanation,
         onChangeDocumentation,
         onClickGenerateDocumentation,
         onClickGenerateExplanation,
         onRefreshQueryData,
         onRunaSetExplanation,
         onSetExplanation,
         queryResults,
         queryVersion,
         readOnly,
         setShowUpgradeModal,
      }: {
         allowDocGeneration?: boolean;
         collapsePanel?: () => void;
         expandPanel?: () => void;
         extraTabs?: Tab[];
         hideTabs?: Partial<Record<keyof typeof queryPanelTabs, boolean>>;
         isGeneratingDocumentation?: boolean;
         isGeneratingExplanation?: boolean;
         onChangeDocumentation?: (params: {
            description?: string;
            explanation?: string;
            question?: string;
            title?: string;
         }) => void;
         onClickGenerateDocumentation?: React.MouseEventHandler<HTMLButtonElement>;
         onClickGenerateExplanation?: React.MouseEventHandler<HTMLButtonElement>;
         onRefreshQueryData?: () => Promise<void>;
         onRunaSetExplanation?: (explanation: string) => void;
         onSetExplanation?: (explanation: string) => void;
         queryResults?: QueryReturn[];
         queryVersion: QueryVersion;
         readOnly?: boolean;
         setShowUpgradeModal?: () => void;
      },
      ref
   ): JSX.Element => {
      const containsPython = queryVersion?.steps
         ? queryVersion.steps.some((step) => step.type === StepType.PYTHON)
         : false;
      const activeTabs = {
         ...Object.fromEntries((extraTabs ?? []).map((t) => [t.title, t.title])),
         ...Object.fromEntries(
            Object.entries(queryPanelTabs).filter(
               ([id]) => !(hideTabs?.[id as keyof typeof queryPanelTabs] === true)
            )
         ),
      };

      const [currentStep] = useCurrentStep();
      const [stepFive, setStepFive] = useWalkthroughStep(walkthroughStep.CHART_TAB);
      const [stepTen, setStepTen] = useWalkthroughStep(walkthroughStep.VERSIONS);
      const [stepThree] = useWalkthroughStep(walkthroughStep.SUGGESTED_QUERY);
      const { expanded, setExpanded, queryTab, setQueryTab } = useQueryPanelContext();
      const workspace = useOptionalWorkspace();

      const { setEditResultsDataConnections, setEditResultsSchemaNames } = useCurrentQuery();

      const onRunSteps = useCallback(
         (steps: QueryStep[]) => {
            setEditResultsDataConnections(steps.map((step) => step.dataConnection || {}));
            setEditResultsSchemaNames(steps.map((step) => step.schemaName || ''));
         },
         [setEditResultsDataConnections, setEditResultsSchemaNames]
      );

      useImperativeHandle(ref, () => ({
         onRunSteps,
      }));

      const setSteps = () => {
         if (stepFive) {
            setStepFive();
         } else if (stepTen) {
            setStepTen();
         }
      };

      // if query result changes, focus results tab
      useEffect(() => {
         if (queryResults) setQueryTab('results');
      }, [queryResults, setQueryTab]);

      // If we add the second workflow step, focus to params
      const lastStepCount = useRef(queryVersion.steps.length);
      useEffect(() => {
         if (lastStepCount.current === 1 && queryVersion.steps.length === 2) {
            setQueryTab('params');
         }
         lastStepCount.current = queryVersion.steps.length;
      }, [queryVersion.steps.length, setQueryTab]);

      // Set active tab to docs when docs are generated.
      const lastQueryVersion = useRef(queryVersion);
      useEffect(() => {
         if (isGeneratingDocumentation) {
            setQueryTab('docs');
         }
         if (
            (queryVersion.generatedTitle &&
               lastQueryVersion.current.title !== queryVersion.title) ||
            (queryVersion.generatedDescription &&
               lastQueryVersion.current.description !== queryVersion.description) ||
            (queryVersion.generatedQuestion &&
               lastQueryVersion.current.query !== queryVersion.query)
         ) {
            lastQueryVersion.current = queryVersion;
         }
      }, [queryVersion, isGeneratingDocumentation, setQueryTab]);
      const isWalkthroughTab = (name: string) => {
         return (
            workspace?.status === WorkspaceStatus.PERSONAL_DEMO &&
            ((name === 'Chart' && stepFive) || (name === 'Versions' && stepTen))
         );
      };

      if (!queryVersion.query?.id) return <></>;

      const triggerExpand = (e: React.MouseEvent) => {
         e.preventDefault();
         if (
            setExpanded !== undefined &&
            expandPanel !== undefined &&
            collapsePanel !== undefined
         ) {
            if (expanded) {
               collapsePanel();
               setExpanded(false);
            } else {
               expandPanel();
               setExpanded(true);
            }
         }
      };

      return (
         <div className="d-flex flex-column h-100">
            <TabContainer
               activeKey={queryTab}
               onSelect={(t) => t && setQueryTab(t as keyof typeof queryPanelTabs)}
               transition={false}
            >
               <Nav
                  className="bottom-explore-tabs explore-tabs bg-secondary"
                  id="exploreTabNavQuery"
                  role="tablist"
               >
                  {Object.entries(activeTabs).map(([id, name]) =>
                     name ? (
                        <AiPulse
                           key={id}
                           on={
                              workspace?.status === WorkspaceStatus.PERSONAL_DEMO &&
                              (isWalkthroughTab(name) ? stepFive || stepTen || stepThree : false)
                           }
                           onClick={isWalkthroughTab(name) ? () => setSteps() : undefined}
                        >
                           <NavItem
                              bsPrefix="explore-tab"
                              className={`bottom-explore-tab ${queryTab === id ? 'active' : ''}`}
                           >
                              <NavLink
                                 className={`position-relative override-active-pointer plausible-event-name--queryPanel${name}`}
                                 eventKey={id}
                                 role="tab"
                              >
                                 {name}
                              </NavLink>
                           </NavItem>
                        </AiPulse>
                     ) : (
                        <div className="flex-grow-1" key={id} />
                     )
                  )}
                  <div className="bottom-explore-tab  explore-tab">
                     <button
                        className="expand-button position-relative override-active-pointer plausible-event-name--queryPanelChart nav-link"
                        onClick={triggerExpand}
                     >
                        {expanded ? (
                           <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem' }}>
                              <BiCollapseVertical />
                           </div>
                        ) : (
                           <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem' }}>
                              <RiExpandUpDownLine />
                           </div>
                        )}
                     </button>
                  </div>
               </Nav>
               <TabContent className="flex-grow-1 overflow-hidden bottom-query-panel-tab-content">
                  {(extraTabs ?? []).map((t, i) => (
                     <TabPane
                        className="h-100 overflow-auto p-2"
                        eventKey={t.title}
                        key={i}
                        mountOnEnter={true}
                     >
                        {t.body}
                     </TabPane>
                  ))}
                  <TabPane
                     className="h-100 overflow-auto p-2"
                     eventKey="runSource"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when trying to reach runSource.'}>
                        <RunSource queryVersion={queryVersion} readOnly={readOnly} />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane className="h-100 overflow-auto p-2" eventKey="admin" mountOnEnter={true}>
                     <ErrorBoundary title={'An error occurred when viewing admin options.'}>
                        <QueryAdmin queryVersion={queryVersion} />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-auto p-2"
                     eventKey="versions"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when viewing version history.'}>
                        <QueryVersionList
                           collapse={false}
                           currentVersion={queryVersion}
                           enabled={queryTab === 'versions'}
                           forkedFromVersion={queryVersion.query?.forkedFromVersion}
                           pageSize={10}
                           queryId={queryVersion.query?.id}
                           showDescription
                        />
                     </ErrorBoundary>
                  </TabPane>{' '}
                  <TabPane className="h-100 overflow-auto p-2" eventKey="logs" mountOnEnter={true}>
                     <ErrorBoundary title={'An error occurred when viewing logs.'}>
                        <QueryLogList
                           collapse={false}
                           currentVersion={queryVersion}
                           enabled={queryTab === 'logs'}
                           pageSize={10}
                           queryId={queryVersion.query.id}
                        />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-auto p-2"
                     eventKey="params"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when viewing parameters.'}>
                        <QueryParams />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-hidden"
                     eventKey="results"
                     key="results"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when retrieving results.'}>
                        <QueryResultsTabs
                           onRefreshQueryData={onRefreshQueryData}
                           queryResults={queryResults}
                        />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-hidden p-2"
                     eventKey="chart"
                     key="chart"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary
                        className="h-100"
                        title={'An error occurred with chart generation.'}
                     >
                        <QueryChart
                           allowChartGeneration={!readOnly}
                           containsPython={containsPython}
                           isCurrentPanel={queryTab === 'chart'}
                           queryReturn={
                              queryResults?.length
                                 ? queryResults[queryResults.length - 1]
                                 : undefined
                           }
                           queryVersion={queryVersion}
                           setShowUpgradeModal={setShowUpgradeModal}
                        />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-auto p-2"
                     eventKey="comments"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when viewing comments.'}>
                        <QueryComments queryVersion={queryVersion} />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane className="h-100 overflow-auto p-2" eventKey="docs" mountOnEnter={true}>
                     <ErrorBoundary title={'An error occurred when viewing documentation.'}>
                        <QueryDocs
                           allowDocGeneration={allowDocGeneration}
                           isGenerating={isGeneratingDocumentation}
                           isGeneratingExplanation={isGeneratingExplanation}
                           onChangeDocumentation={onChangeDocumentation}
                           onClickGenerateDocumentation={onClickGenerateDocumentation}
                           onClickGenerateExplanation={onClickGenerateExplanation}
                           onSetExplanation={onSetExplanation}
                           queryVersion={queryVersion}
                           readOnly={readOnly}
                           setShowUpgradeModal={setShowUpgradeModal}
                        />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane className="h-100 overflow-auto p-2 " eventKey="runa" mountOnEnter={true}>
                     <div className="d-flex h-100">
                        <ErrorBoundary title={'An error occurred with the Ask Runa service.'}>
                           <AskRuna
                              onRunaSetExplanation={onRunaSetExplanation}
                              setShowUpgradeModal={setShowUpgradeModal}
                           />
                        </ErrorBoundary>
                     </div>
                  </TabPane>
                  <TabPane
                     className="p-2 h-100 overflow-auto"
                     eventKey="suggestions"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'An error occurred when viewing suggestions.'}>
                        <QuerySuggestions
                           query={queryVersion}
                           step={
                              currentStep?.queryVersion.id === queryVersion.id
                                 ? currentStep?.queryStep
                                 : queryVersion.steps[0]
                           }
                           workspaceId={queryVersion.query.workspaceId}
                        />
                     </ErrorBoundary>
                  </TabPane>
                  <TabPane
                     className="h-100 overflow-hidden"
                     eventKey="requests"
                     mountOnEnter={true}
                  >
                     <ErrorBoundary title={'Error loading Requests content'}>
                        <RequestsTabContent queryVersion={queryVersion} />
                     </ErrorBoundary>
                  </TabPane>
               </TabContent>
            </TabContainer>
         </div>
      );
   }
);

export default QueryPanel;
