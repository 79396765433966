import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useInjection } from 'inversify-react';

import { DataChatService } from '../../services';
import { TYPES } from '../../types';
import { DataChatThread, DataChatThreadState } from '../../entities';
import { QueryKey, QueryKeyType, PersonRole } from '../../enums';
import { usePerson } from './personHooks';

import type { DataChatThreadsMetadata } from '../../services/DataChatService';

export const getDataChatThreadKey = (threadId: number | null) => {
   if (!threadId) {
      return [QueryKey.DataChatThreads, QueryKeyType.LIST];
   }
   return [QueryKey.DataChatThreads, QueryKeyType.GET, threadId];
};

export const getDataChatThreadsMetadataKey = (role: 'creator' | 'analyst') => [
   QueryKey.DataChatThreads,
   'metadata',
   role,
];

export const useListDataChatThreads = ({ workspaceId }: { workspaceId?: number } = {}) => {
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   const person = usePerson();
   return useQuery<DataChatThread[]>(
      getDataChatThreadKey(null),
      async () =>
         await service.list({
            role: person.role === PersonRole.ORG_BUSINESS_USER ? 'creator' : 'analyst',
            workspaceId,
         }),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: true,
         refetchOnMount: true,
         retry: false,
      }
   );
};

export const useFetchDataChatThreadsMetadata = () => {
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   const person = usePerson();
   const role = person.role === PersonRole.ORG_BUSINESS_USER ? 'creator' : 'analyst';
   return useQuery<DataChatThreadsMetadata>(
      getDataChatThreadsMetadataKey(role),
      async () => await service.fetchMetadata({ role }),
      {
         refetchOnWindowFocus: true,
         refetchOnMount: 'always',
         retry: false,
      }
   );
};

export const useGetDataChatThread = (id: number | null) => {
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   return useQuery<DataChatThread>(getDataChatThreadKey(id), async () => await service.get(id!), {
      enabled: !!id,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      retry: false,
   });
};

export const useAddDataChatMessage = () => {
   const queryClient = useQueryClient();
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   return useMutation({
      mutationFn: (thread: {
         id?: number;
         message: { content?: string; queryVersionId?: number };
         state?: DataChatThreadState;
      }) => {
         return thread.id
            ? service.patch(thread.id, {
                 messages: [thread.message],
                 state: thread.state,
              })
            : service.post({ message: thread.message.content! });
      },
      onSuccess: (thread, originalThread) => {
         if (!originalThread.id) {
            queryClient.invalidateQueries(getDataChatThreadKey(null));
         }
         queryClient.setQueryData(getDataChatThreadKey(thread.id!), (_: any) => thread);
      },
   });
};

export const usePatchDataChatThread = () => {
   const queryClient = useQueryClient();
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   return useMutation({
      mutationFn: ({
         id,
         data,
      }: {
         data: {
            assigneeId?: number | null;
            state?: DataChatThreadState;
            viewedByCreator?: true;
            workspaceId?: number;
         };
         id: number;
      }) => service.patch(id, data),
      onSuccess: (thread) => {
         queryClient.setQueryData(getDataChatThreadKey(thread.id!), (_: any) => thread);
      },
   });
};
