import classNames from 'classnames';
import { Image } from 'react-bootstrap';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { GlobalNavRail, Navbar, PageHeader, PageTab, PageTabs, ErrorBoundary } from '../components';
import { isDesktop } from '../services';
import logo from '../assets/img/runQL-logo.svg';

export const Page = ({
   action,
   children,
   className,
   header,
   headerPadding,
   nav,
   noPad,
   noScroll,
   onTabChange,
   toolbar,
   tabs,
   tabsClassName,
}: {
   action?: React.ReactNode;
   children?: React.ReactNode;
   className?: string;
   header?: React.ReactNode;
   headerPadding?: boolean;
   nav?: React.ReactNode;
   noPad?: boolean;
   noScroll?: boolean;
   onTabChange?: (tabKey: string | null) => void;
   tabs?: PageTab[];
   tabsClassName?: string;
   toolbar?: React.ReactNode;
}) => {
   noPad ??= !!tabs;
   headerPadding ??= true;
   return (
      <div
         className={classNames('d-flex flex-column h-100 overflow-hidden', className, {
            desktop: isDesktop(),
         })}
      >
         {isDesktop() && (
            <>
               <header className="header bg-light border-bottom-line fixed-top d-flex justify-content-center">
                  <Image alt="runQL Logo" src={logo} style={{ height: '19px' }} />
               </header>
               <div className="flex-grow-0 flex-shrink-0" style={{ height: '30px' }} />
            </>
         )}
         <div className="d-flex flex-row w-100 flex-grow-1 overflow-hidden">
            <GlobalNavRail />
            <div className="flex-grow-1 overflow-hidden d-flex flex-column">
               {header && <PageHeader>{header}</PageHeader>}
               <PanelGroup autoSaveId="page" direction="horizontal">
                  {nav && (
                     <ErrorBoundary>
                        <Panel
                           className="h-100 starting-block-panel"
                           defaultSize={15}
                           id="secondary"
                           minSize={5}
                           order={1}
                        >
                           <Navbar>
                              <div
                                 className="d-flex flex-column h-100 hide-scrollbar"
                                 style={{ flexGrow: 1, overflowY: 'auto' }}
                              >
                                 {nav}
                              </div>
                           </Navbar>
                        </Panel>
                        <PanelResizeHandle className="panelHandle vertical">
                           <div className="panelHandleLine" />
                        </PanelResizeHandle>
                     </ErrorBoundary>
                  )}
                  <Panel defaultSize={85} id="primary" minSize={45} order={2}>
                     <ErrorBoundary>
                        <div
                           className={classNames('h-100  w-100 query-page-background', {
                              'p-3': !noPad,
                              'overflow-auto': !noScroll && !tabs,
                           })}
                        >
                           {tabs && (
                              <PageTabs
                                 action={action}
                                 className={tabsClassName ?? 'px-3 justify-content-between'}
                                 noScroll={noScroll}
                                 onTabChange={onTabChange}
                                 tabs={tabs}
                                 toolbar={toolbar}
                              />
                           )}
                           {children}
                        </div>
                     </ErrorBoundary>
                  </Panel>
               </PanelGroup>
            </div>
         </div>
      </div>
   );
};

export default Page;
