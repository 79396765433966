import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BiChevronRight } from 'react-icons/bi';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import Select, { MultiValue, components, MultiValueGenericProps } from 'react-select';
import _keyBy from 'lodash/keyBy';

import {
   useDeleteWorkspaceConnectionMutation,
   useListDataConnectionSchemasQuery,
   useListWorkspaceConnectionsQuery,
   useNewWorkspaceConnectionMutation,
} from '../hooks/entities';
import { getErrorMessage, handleError } from '../utilities';
import LoadingError from './/UI/LoadingError';
import LoadingSpinner from './/UI/LoadingSpinner';
import { fetchSchemaRootsQueryKey } from '../components/SchemaTree';

import type { WorkspaceSchemaConnection, DataConnectionSchema } from '../entities';

type WorkspaceSchemaConnectionOption = { label: string; value: WorkspaceSchemaConnection };
type GroupedOption = {
   label: string;
   options: Array<WorkspaceSchemaConnectionOption>;
};

// Custom label for selected options
const MultiValueLabel = (props: MultiValueGenericProps<WorkspaceSchemaConnectionOption>) => {
   const { data } = props;

   return (
      <components.MultiValueLabel {...props}>
         {`${data.value.dataConnection.name} - ${data.label}`}
      </components.MultiValueLabel>
   );
};

const ManageWorkspaceConnectionsBody = ({
   allSchemas,
   attachedWorkspaceSchemaConnections,
   workspaceId,
   onClose,
}: {
   allSchemas: DataConnectionSchema[];
   attachedWorkspaceSchemaConnections: WorkspaceSchemaConnection[];
   onClose: () => void;
   workspaceId: number;
}) => {
   const queryClient = useQueryClient();

   const attachedWorkspaceSchemaConnectionsIndexed = useMemo(
      () =>
         _keyBy(
            attachedWorkspaceSchemaConnections,
            ({ dataConnectionId, schemaName }) => `${dataConnectionId}-${schemaName}`
         ),
      [attachedWorkspaceSchemaConnections]
   );

   const groupedOptions = useMemo(
      () =>
         Object.values(
            allSchemas
               .filter(
                  ({ isVisible, dataConnection, schemaName }) =>
                     isVisible ||
                     attachedWorkspaceSchemaConnectionsIndexed[
                        `${dataConnection.id}-${schemaName}`
                     ] !== undefined
               )
               .sort((a, b) => a.schemaName.localeCompare(b.schemaName))
               .reduce<Record<string, GroupedOption>>((acc, schema) => {
                  const key = schema.dataConnection.id!;

                  acc[key] ??= { label: schema.dataConnection.name ?? 'Unknown', options: [] };

                  acc[key].options.push({
                     label: schema.schemaName,
                     value: {
                        dataConnection: schema.dataConnection,
                        dataConnectionId: schema.dataConnection.id!,
                        schemaName: schema.schemaName,
                        workspaceId,
                     },
                  });
                  return acc;
               }, {})
         ).sort((a, b) => a.label.localeCompare(b.label)),
      [allSchemas, attachedWorkspaceSchemaConnectionsIndexed, workspaceId]
   );

   const [selectedValues, setSelectedValues] = useState<
      MultiValue<WorkspaceSchemaConnectionOption>
   >([]);
   useEffect(() => {
      const nextSelectedValues = groupedOptions
         .flatMap(({ options }) => options)
         .filter(
            ({ value }) =>
               attachedWorkspaceSchemaConnectionsIndexed[
                  `${value.dataConnectionId}-${value.schemaName}`
               ] !== undefined
         );
      setSelectedValues(nextSelectedValues);
   }, [attachedWorkspaceSchemaConnectionsIndexed, groupedOptions]);

   const deleteWorkspaceConnectionMutator = useDeleteWorkspaceConnectionMutation(workspaceId);
   const createWorkspaceConnectionMutator = useNewWorkspaceConnectionMutation();
   const [errorMessage, setErrorMessage] = useState<string | undefined>();
   const [showContactInfo, setShowContactInfo] = useState(false);
   const handleClickSave = async () => {
      if (selectedValues.length === 0) {
         setErrorMessage('Please select at least one schema');
         return;
      }

      setErrorMessage(undefined);
      setShowContactInfo(false);
      try {
         const workspaceSchemaConnectionsToCreate = selectedValues
            .filter(
               ({ value }) =>
                  attachedWorkspaceSchemaConnectionsIndexed[
                     `${value.dataConnectionId}-${value.schemaName}`
                  ] === undefined
            )
            .map(({ value }) => value);

         const selectedValuesIndexed = _keyBy(
            selectedValues,
            ({ value: { dataConnectionId, schemaName } }) => `${dataConnectionId}-${schemaName}`
         );

         const workspaceSchemaConnectionsToDelete = attachedWorkspaceSchemaConnections.filter(
            (workspaceSchemaConnection) =>
               selectedValuesIndexed[
                  `${workspaceSchemaConnection.dataConnectionId}-${workspaceSchemaConnection.schemaName}`
               ] === undefined
         );

         await Promise.all([
            ...workspaceSchemaConnectionsToCreate.map((workspaceSchemaConnection) =>
               createWorkspaceConnectionMutator.mutateAsync(workspaceSchemaConnection)
            ),
         ]);
         const deleteResult = await Promise.all([
            ...workspaceSchemaConnectionsToDelete.map((workspaceSchemaConnection) =>
               deleteWorkspaceConnectionMutator.mutateAsync(workspaceSchemaConnection)
            ),
         ]);
         const errorExists = deleteResult.find((entry) => entry !== null && 'error' in entry);
         if (errorExists) {
            setErrorMessage(errorExists.error);
            setShowContactInfo(true); // For now, ask them to contact us to delete connections that can't be deleted.
            // Reset options back to original after error
            const nextSelectedValues = groupedOptions
               .flatMap(({ options }) => options)
               .filter(
                  ({ value }) =>
                     attachedWorkspaceSchemaConnectionsIndexed[
                        `${value.dataConnectionId}-${value.schemaName}`
                     ] !== undefined
               );
            setSelectedValues(nextSelectedValues);
         } else {
            queryClient.invalidateQueries(
               fetchSchemaRootsQueryKey({ type: 'invalidate', data: { workspaceId } })
            );
            onClose();
         }
      } catch (err) {
         handleError(err);
      }
   };

   return (
      <>
         <Modal.Header className="border-0 mb-0 pb-0" closeButton={true}>
            Workspace Schemas
         </Modal.Header>
         <Modal.Body>
            <Select
               className="runql-select"
               classNamePrefix="runql-select"
               components={{ MultiValueLabel }}
               isMulti
               noOptionsMessage={() => 'No schemas'}
               onChange={(newValue) => {
                  setSelectedValues(newValue);
               }}
               options={groupedOptions}
               placeholder="Workspace schemas"
               value={selectedValues}
            />
            {errorMessage && (
               <div className="d-flex flex-column">
                  <span className="text-danger fs-10p d-flex">{errorMessage}</span>
                  {showContactInfo && (
                     <span className="text-danger fs-10p d-flex" style={{ gap: '3px' }}>
                        <>
                           If you would like to force delete this connection, please contact us at{' '}
                        </>
                        <a className="text-danger fs-10p d-flex" href="mailto:support@runql.com">
                           support@runql.com
                        </a>
                     </span>
                  )}
               </div>
            )}
         </Modal.Body>
         <Modal.Footer className="border-0 pt-0 justify-content-between">
            <div className="fs-12p ms-0">
               Add new data source in{' '}
               <Link to={'/sources'}>
                  Admin
                  <BiChevronRight size={12} />
                  Data Sources
               </Link>
            </div>
            <div className="me-0">
               <button className="btn btn-sm btn-primary" onClick={handleClickSave}>
                  Save
               </button>
            </div>
         </Modal.Footer>
      </>
   );
};

const ManageWorkspaceConnectionsBodyContainer = ({
   onClose,
   workspaceId,
}: {
   onClose: () => void;
   workspaceId: number;
}): JSX.Element => {
   const attachedWorkspaceSchemaConnectionsResult = useListWorkspaceConnectionsQuery({
      workspaceId: workspaceId,
      includeConnectionDetails: true,
   });

   const allSchemasResult = useListDataConnectionSchemasQuery();

   if (attachedWorkspaceSchemaConnectionsResult.isLoading || allSchemasResult.isLoading) {
      return <LoadingSpinner />;
   }

   if (!attachedWorkspaceSchemaConnectionsResult.data) {
      return (
         <LoadingError message={getErrorMessage(attachedWorkspaceSchemaConnectionsResult.error)} />
      );
   }

   if (!allSchemasResult.data) {
      return <LoadingError message={getErrorMessage(allSchemasResult.error)} />;
   }

   return (
      <ManageWorkspaceConnectionsBody
         allSchemas={allSchemasResult.data}
         attachedWorkspaceSchemaConnections={attachedWorkspaceSchemaConnectionsResult.data}
         onClose={onClose}
         workspaceId={workspaceId}
      />
   );
};

const ManageWorkspaceConnections = ({
   show,
   onClose,
   workspaceId,
}: {
   onClose: () => void;
   show: boolean;
   workspaceId: number;
}): JSX.Element => {
   return (
      <Modal centered={true} onHide={onClose} show={show}>
         {show ? (
            <ManageWorkspaceConnectionsBodyContainer onClose={onClose} workspaceId={workspaceId} />
         ) : (
            <LoadingSpinner />
         )}
      </Modal>
   );
};
export default ManageWorkspaceConnections;
