import { ColumnDefinition, ColumnModify, QueryBuilder } from './QueryBuilder';

export interface SnowflakeColumnModify extends ColumnModify {
   description?: string;
}
export enum SnowflakeDataType {
   ARRAY = 'ARRAY',
   BINARY = 'BINARY',
   BOOLEAN = 'BOOLEAN',
   CHAR = 'CHAR',
   CHARACTER = 'CHARACTER',
   DATE = 'DATE',
   DATETIME = 'DATETIME',
   DECIMAL = 'DECIMAL',
   DOUBLE = 'DOUBLE',
   FLOAT = 'FLOAT',
   GEOGRAPHY = 'GEOGRAPHY',
   GEOMETRY = 'GEOMETRY',
   NUMBER = 'NUMBER',
   OBJECT = 'OBJECT',
   REAL = 'REAL',
   STRING = 'STRING',
   TEXT = 'TEXT',
   TIME = 'TIME',
   TIMESTAMP = 'TIMESTAMP',
   TIMESTAMP_LTZ = 'TIMESTAMP_LTZ',
   TIMESTAMP_NTZ = 'TIMESTAMP_NTZ',
   TIMESTAMP_TZ = 'TIMESTAMP_TZ',
   VARBINARY = 'VARBINARY',
   VARCHAR = 'VARCHAR',
   VARIANT = 'VARIANT',
}

export const SnowflakeNumericColumns = [
   SnowflakeDataType.NUMBER,
   SnowflakeDataType.DECIMAL,
   SnowflakeDataType.FLOAT,
   SnowflakeDataType.DOUBLE,
   SnowflakeDataType.REAL,
];
export interface SnowflakeColumnModify extends ColumnModify {
   comment?: string;
   extra?: string;
}

export class SnowflakeQueryBuilder extends QueryBuilder {
   public beginTransaction(): string {
      return 'BEGIN TRANSACTION;';
   }
   public isTypeNumeric(column: ColumnDefinition): boolean {
      return SnowflakeNumericColumns.includes(column.dataType as SnowflakeDataType);
   }

   public update({
      updates,
      catalog,
      table,
      schema,
      where,
   }: {
      catalog?: string;
      schema?: string;
      table: string;
      updates: Record<string, ColumnDefinition>;
      where: Record<string, any>;
   }): string {
      // Building SET clause for the update
      const setClause = Object.entries(updates)
         .map(([column, value]) => {
            return `${this.wrapIdentifier(column)} = ${this.getQuotedValue(value.data, value)}`;
         })
         .join(', ');

      return `UPDATE ${this.qualifyTable(
         table,
         schema,
         catalog
      )} SET ${setClause} WHERE ${this.buildWhereClause(where)};`;
   }

   public insert({
      catalog,
      table,
      schema,
      insertData,
   }: {
      catalog?: string; // Not all DBMS support catalog
      insertData: Record<string, any>;
      schema?: string;
      table: string;
   }): string {
      const columns = Object.keys(insertData)
         .map((col) => this.wrapIdentifier(col))
         .join(', ');
      const values = Object.values(insertData)
         .map((v) => this.getQuotedValue(v))
         .join(', ');

      return `INSERT INTO ${this.qualifyTable(
         table,
         schema,
         catalog
      )} (${columns}) VALUES (${values});`;
   }

   public delete({
      catalog,
      table,
      schema,
      where,
   }: {
      catalog?: string; // Not all DBMS support catalog
      schema?: string;
      table: string;
      where: Record<string, any>;
   }): string {
      return `DELETE FROM ${this.qualifyTable(
         table,
         schema,
         catalog
      )} WHERE ${this.buildWhereClause(where)};`;
   }

   public alterTable({
      catalog,
      columns,
      table,
      schema,
   }: {
      catalog?: string;
      columns: Record<string, { definition?: ColumnDefinition; modify: SnowflakeColumnModify }>;
      schema?: string;
      table: string;
   }): string {
      const alterTable = `ALTER TABLE ${this.qualifyTable(table, schema, catalog)}`;

      const columnChanges = Object.entries(columns).flatMap(([column, { modify, definition }]) => {
         const changeScripts: string[] = [];
         const dataType = modify.dataType ?? definition?.dataType;
         const defaultVal =
            modify.default !== undefined
               ? `${modify.default}`
               : definition?.default !== undefined
               ? `${definition.default}`
               : '';
         const nullable =
            modify.nullable !== undefined
               ? modify.nullable
                  ? 'DROP NOT NULL'
                  : 'SET NOT NULL'
               : '';
         const comment =
            modify.comment !== undefined
               ? `${modify.comment}`
               : definition?.comment !== undefined
               ? `${definition.comment}`
               : '';

         if (modify.isNew) {
            changeScripts.push(
               `${alterTable} ADD COLUMN ${this.wrapIdentifier(column)} ${dataType ?? ''} ${
                  modify.nullable === false ? 'NOT NULL' : ''
               }${defaultVal ? ` DEFAULT '${defaultVal}'` : ''}${
                  comment ? ` COMMENT '${comment}'` : ''
               }`
            );
         } else if (modify.drop) {
            changeScripts.push(`${alterTable} DROP COLUMN ${this.wrapIdentifier(column)}`);
         } else {
            if (modify.rename && modify.rename !== column) {
               changeScripts.push(
                  `${alterTable} RENAME COLUMN ${this.wrapIdentifier(
                     column
                  )} TO ${this.wrapIdentifier(modify.rename)}`
               );
            }
            if (dataType) {
               changeScripts.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(
                     modify.rename ?? column
                  )} SET DATA TYPE ${dataType}`
               );
            }

            if (defaultVal) {
               changeScripts.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(
                     modify.rename ?? column
                  )} SET DEFAULT '${defaultVal}'`
               );
            }
            if (nullable) {
               changeScripts.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(
                     modify.rename ?? column
                  )} ${nullable}`
               );
            }

            if (comment) {
               changeScripts.push(
                  `${alterTable} COMMENT COLUMN ${this.wrapIdentifier(
                     modify.rename ?? column
                  )} IS '${comment}'`
               );
            }
         }

         return changeScripts;
      });

      if (columnChanges.length > 0) {
         return columnChanges.join(';\n') + ';';
      }
      return '';
   }

   public endTransaction(): string {
      return '';
   }

   public explainQuery(query: string, analyze?: boolean): string {
      return `EXPLAIN USING TEXT ${query}`;
   }

   // -- Delete Table

   protected ifExists = 'IF EXISTS';

   // -- Create Table

   protected increments(
      name: string = 'id',
      { nullable = false, primaryKey = true }: { nullable?: boolean; primaryKey?: boolean } = {}
   ) {
      let columnDefinition = `${this.wrapIdentifier(name)} INT AUTOINCREMENT`;

      if (!nullable) {
         columnDefinition += ' NOT NULL';
      }
      if (primaryKey) {
         columnDefinition += ' PRIMARY KEY';
      }

      return columnDefinition;
   }

   public deleteTable({
      catalog,
      force = false,
      schema,
      table,
   }: {
      catalog?: string;
      force?: boolean;
      schema: string;
      table: string;
   }) {
      const statements = [
         `${`DROP TABLE ${this.ifExists}`.trim()} ${this.qualifyTable(table, schema, catalog)};`,
      ];

      if (!this.supportsDisablingForeignKeyChecks) {
         return statements;
      }

      return force ? this.disableForeignKeyChecks(statements) : statements;
   }

   public createTable({
      catalog,
      schema,
      table,
   }: {
      catalog?: string;
      schema: string;
      table: string;
   }) {
      return `CREATE TABLE ${this.qualifyTable(table, schema, catalog)} (
  ${this.increments()}
);`;
   }
}
