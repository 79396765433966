import { Image, Stack } from 'react-bootstrap';
import classNames from 'classnames';
import logo from '../assets/img/runQL-logo.svg';
import Footer from '../components/Footer';
import { ThemeContext } from '../components/ThemeContext';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { isDesktop } from '../services';
import React from 'react';

const PublicPage = ({ children }: { children: ReactNode }): React.JSX.Element => {
   const themeContext = useContext(ThemeContext);
   const [isDarkMode, setIsDarkMode] = useState(themeContext?.mode === 'dark');

   useEffect(() => {
      setIsDarkMode(themeContext?.mode === 'dark');
   }, [themeContext]);

   const handleToggle = () => {
      if (themeContext && themeContext.toggleMode) {
         themeContext.toggleMode();
      }
      setIsDarkMode((prevState) => !prevState);
   };

   return (
      <Stack className={classNames('h-100', { desktop: isDesktop() })}>
         <header className="header navbar navbar-expand-lg bg-light border-bottom-line shadow-sm fixed-top">
            <div className="container-fluid d-flex justify-content-between align-items-center fs-sm">
               <div className="d-flex justify-content-start align-items-center w-100">
                  {isDesktop() && <div className="flex-grow-1"></div>}
                  <Image alt="runQL Logo" src={logo} width="70" />
                  <div className="flex-grow-1 d-flex justify-content-end">
                     {!isDesktop() && (
                        <div className="form-check form-switch mode-switch pe-lg-1">
                           <input
                              checked={isDarkMode}
                              className="form-check-input"
                              id="theme-mode"
                              onChange={(e): void => {
                                 handleToggle();
                                 const html = document.getElementsByTagName('html')[0];
                                 if (e.target.checked) {
                                    html.classList.add('dark-mode');
                                    window.localStorage.setItem('mode', 'dark');
                                 } else {
                                    html.classList.remove('dark-mode');
                                    window.localStorage.setItem('mode', 'light');
                                 }
                              }}
                              type="checkbox"
                           />
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </header>

         <section className="position-relative mt-5 p-2 p-md-5 d-flex flex-grow-1 justify-content-center align-items-start overflow-y-auto">
            <div>{children}</div>
         </section>
         <Footer />
      </Stack>
   );
};

export default PublicPage;
