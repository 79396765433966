import { memo, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, Spinner, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { QueryReturn } from '../interfaces';
import { getShorterDateTimeString, IconPinned, IconUnpinned } from '../utilities';
import { QueryResults } from './QueryResults';
import { useCurrentQuery } from '../hooks';

const TabHeader = ({
   active,
   queryResults,
   pinned,
   updatePin,
   eventKey,
   timestamp,
}: {
   active?: boolean;
   eventKey: string;
   pinned?: boolean;
   queryResults: QueryReturn[];
   timestamp: string;
   updatePin: (result: QueryReturn[], pinned: boolean) => void;
}): JSX.Element => {
   return (
      <NavItem className={`explore-tab ${active ? 'active' : ''}`}>
         <NavLink
            className="position-relative override-active-pointer"
            eventKey={eventKey}
            role="tab"
         >
            {getShorterDateTimeString(timestamp)}
            <div className="ps-1 text-muted">
               {pinned === true ? (
                  <IconPinned
                     className="active-background-on-hover"
                     onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updatePin(queryResults, false);
                     }}
                     size={14}
                     title="Remove this pinned tab"
                  />
               ) : (
                  <IconUnpinned
                     className="active-background-on-hover"
                     onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updatePin(queryResults, true);
                     }}
                     size={14}
                     title="Pin this tab (result set) for comparison"
                  />
               )}
            </div>
         </NavLink>
      </NavItem>
   );
};

const QueryResultsTabs = memo(
   ({
      onRefreshQueryData,
      queryResults,
   }: {
      onRefreshQueryData?: () => Promise<void>;
      queryResults?: QueryReturn[];
   }): JSX.Element => {
      const [activeResultTab, setActiveResultTab] = useState<string | undefined>(undefined);
      const [pinnedResults, setPinnedResults] = useState<QueryReturn[][]>([]);
      const [activeResultStep, setActiveResultStep] = useState<number>(0);
      const { editResultsDataConnections, editResultsSchemaNames } = useCurrentQuery();

      const getTimestamp = (queryResults?: QueryReturn[]): string | undefined => {
         return queryResults?.[queryResults?.length - 1]?.timestamp.toString();
      };

      const currentTimestamp = getTimestamp(queryResults) ?? '';
      const currentLength = queryResults?.length || 0;
      const currentUnpinned =
         currentTimestamp !== getTimestamp(pinnedResults[pinnedResults.length - 1]);
      const allResults = [
         ...pinnedResults,
         ...(currentUnpinned && queryResults ? [queryResults] : []),
      ];

      useEffect(() => {
         if (!currentTimestamp) return;
         setActiveResultTab(currentTimestamp);
         setActiveResultStep(currentLength - 1);
      }, [currentTimestamp, currentLength]);

      const updatePin = (result: QueryReturn[], pinned: boolean): void => {
         if (pinned) {
            setPinnedResults((pinnedResults) => [...pinnedResults, result]);
         } else {
            setPinnedResults((pinnedResults) =>
               pinnedResults.filter(
                  (pinnedResult) => getTimestamp(pinnedResult) !== getTimestamp(result)
               )
            );
            setActiveResultTab(currentTimestamp);
         }
      };
      if (queryResults === undefined) {
         return (
            <div className="p-2">
               <i>Run this query to see data results.</i>
            </div>
         );
      }
      if (queryResults.length === 0) {
         return (
            <div className="p-2">
               <Spinner />
            </div>
         );
      }
      return (
         <div className="d-flex flex-column h-100 pt-2">
            <TabContainer
               activeKey={activeResultTab}
               onSelect={(eventKey) => {
                  setActiveResultTab(eventKey ?? undefined);
                  const match = allResults.find((result) => getTimestamp(result) === eventKey);
                  if (match && match.length - 1 < activeResultStep) {
                     setActiveResultStep(match.length - 1);
                  }
               }}
               transition={false}
            >
               <Nav className="fs-10p resultset-explore-tabs text-nowrap" role="tablist">
                  {allResults.map((queryResults, i) => (
                     <TabHeader
                        active={activeResultTab === getTimestamp(queryResults)}
                        eventKey={getTimestamp(queryResults)!}
                        key={getTimestamp(queryResults)}
                        pinned={i < pinnedResults.length}
                        queryResults={queryResults}
                        timestamp={getTimestamp(queryResults) ?? ''}
                        updatePin={updatePin}
                     />
                  ))}
               </Nav>
               <TabContent className="flex-grow-1 overflow-hidden">
                  {allResults.map((results, index) => (
                     <TabPane
                        className="d-flex flex-column h-100"
                        eventKey={getTimestamp(results)}
                        key={getTimestamp(results)}
                     >
                        {results.length === 1 ? (
                           <QueryResults
                              dataConnection={editResultsDataConnections?.[index]}
                              onRefreshQueryData={onRefreshQueryData}
                              queryReturn={results[0]}
                              schemaName={editResultsSchemaNames?.[index]}
                           />
                        ) : (
                           <TabContainer
                              activeKey={activeResultStep}
                              onSelect={(eventKey) => {
                                 setActiveResultStep(eventKey ? parseInt(eventKey) : 0);
                              }}
                              transition={false}
                           >
                              <Nav className="fs-10p text-nowrap steps-row" role="tablist">
                                 {results.map((_, i) => (
                                    <NavItem
                                       className={`explore-tab ${
                                          activeResultStep === i ? 'active' : ''
                                       }`}
                                       key={i}
                                    >
                                       <NavLink eventKey={i.toString()} role="tab">
                                          Step {i + 1}
                                       </NavLink>
                                    </NavItem>
                                 ))}
                              </Nav>
                              <TabContent className="flex-grow-1 overflow-hidden">
                                 {results.map((result, i) => (
                                    <TabPane className="h-100" eventKey={i.toString()} key={i}>
                                       <QueryResults
                                          dataConnection={editResultsDataConnections?.[i]}
                                          onRefreshQueryData={onRefreshQueryData}
                                          queryReturn={result}
                                          schemaName={editResultsSchemaNames?.[i]}
                                       />
                                    </TabPane>
                                 ))}
                              </TabContent>
                           </TabContainer>
                        )}
                     </TabPane>
                  ))}
               </TabContent>
            </TabContainer>
         </div>
      );
   }
);
export default QueryResultsTabs;
