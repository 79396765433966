import { Spinner } from 'react-bootstrap';
import { QueryReviewPage } from '../components';
import LoadingError from '../components/UI/LoadingError';
import { MdCheck } from 'react-icons/md';
import { QueryVersion } from '../entities';
import { getErrorMessage, useTitle } from '../utilities';
import { useListQueryQuery, useQueryApproval } from '../hooks';

const CertifyButton = ({ queryVersion }: { queryVersion: QueryVersion }) => {
   const { approve, saving } = useQueryApproval(queryVersion);
   return (
      <button className="btn btn-sm btn-success" onClick={approve}>
         {saving ? (
            <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
         ) : (
            <>
               <MdCheck />
               &nbsp;Certify
            </>
         )}
      </button>
   );
};

export function QueryReview() {
   useTitle('Certification Requests');

   const queries = useListQueryQuery({
      listOptions: {
         expandedPersonData: true,
         includeDataConnectionDetails: true,
         version: 'review',
         includeWorkspace: true,
         includeParent: true,
      },
   });

   if (queries.isError) return <LoadingError message={getErrorMessage(queries.error)} />;

   const queryVersions = queries.data?.items ?? [];

   return (
      <QueryReviewPage
         defaultTab="comments"
         diffVersion={(queryVersion) => queryVersion.query?.approvedVersion}
         expandDetails={true}
         extraActions={(queryVersion) => [
            <CertifyButton key="approve" queryVersion={queryVersion} />,
         ]}
         hideTabs={{ logs: true }}
         queryVersions={queryVersions}
         upgradeMessage="Certify and approve queries for all users in your organization."
      >
         {queries.data?.items.length === 0 && <p>No certification requests.</p>}
      </QueryReviewPage>
   );
}

export default QueryReview;
