import { injectable } from 'inversify';
import { toast } from 'react-toastify';

export class ApiError extends Error {
   constructor(public status: number, message: string) {
      super(message);
   }
}

export class SeeOther extends ApiError {
   constructor(details: object) {
      super(303, 'See Other');
      this.details = details;
   }

   public details: any;
}

const IDEMPOTENT_REQUEST_METHODS = ['GET', 'DELETE'];
const RETRY_ATTEMPTS = process.env.NODE_ENV === 'development' ? 5 : 4;
const RETRY_DELAY = 1000;
const RECONNECTING_TOAST_ID = 'reconnecting';

@injectable()
export class ApiService {
   public readonly endpoint: string;

   constructor() {
      if (!process.env.REACT_APP_API_URI) {
         throw Error('API endpoint is undefined');
      }
      this.endpoint = process.env.REACT_APP_API_URI;
   }

   get<T>(
      route: string,
      params?: Record<string, string>,
      headers?: Record<string, string>
   ): Promise<T | null> {
      return this.fetch(route, 'GET', params, headers);
   }

   post<T>(
      route: string,
      body: any,
      params?: Record<string, string>,
      headers?: Record<string, string>
   ): Promise<T | null> {
      return this.fetch<T>(route, 'POST', params, headers, body);
   }

   put<T>(
      route: string,
      body: any,
      params?: Record<string, string>,
      headers?: Record<string, string>
   ): Promise<T | null> {
      return this.fetch<T>(route, 'PUT', params, headers, body);
   }

   patch<T>(
      route: string,
      body: any,
      params?: Record<string, string>,
      headers?: Record<string, string>
   ): Promise<T | null> {
      return this.fetch<T>(route, 'PATCH', params, headers, body);
   }

   delete<T>(
      route: string,
      params?: Record<string, string>,
      headers?: Record<string, string>,
      body?: any
   ): Promise<T | null> {
      return this.fetch<T>(route, 'DELETE', params, headers, body);
   }

   private isIdempotentRequest(method?: string) {
      return method !== undefined && IDEMPOTENT_REQUEST_METHODS.includes(method);
   }

   private retryableResponseCode(code?: number) {
      // No response code = network error. We should retry on those.
      return code === undefined || code < 200 || (code >= 500 && code <= 599);
   }

   private shouldRetry(
      alwaysRetry: boolean,
      retryCount: number,
      init?: RequestInit,
      response?: Response
   ) {
      if (!init) {
         // We don't know if it's idempotent.
         return false;
      }
      return (
         retryCount > 0 &&
         (alwaysRetry || this.isIdempotentRequest(init.method)) &&
         this.retryableResponseCode(response?.status)
      );
   }

   private async fetchWithRetry(
      input: RequestInfo | URL,
      retryCount: number,
      retryDelay: number,
      alwaysRetry: boolean,
      init?: RequestInit
   ): Promise<Response> {
      try {
         const response = await fetch(input, init);
         if (this.shouldRetry(alwaysRetry, retryCount, init, response)) {
            await new Promise((res) => setTimeout(res, retryDelay));
            return await this.fetchWithRetry(
               input,
               retryCount - 1,
               retryDelay * 2,
               alwaysRetry,
               init
            );
         }

         toast.dismiss(RECONNECTING_TOAST_ID);
         return response;
      } catch (err) {
         if (this.shouldRetry(alwaysRetry, retryCount, init, undefined)) {
            toast.warn('Network error. Retrying request...', {
               autoClose: false,
               toastId: RECONNECTING_TOAST_ID,
            });
            await new Promise((res) => setTimeout(res, retryDelay));
            return await this.fetchWithRetry(
               input,
               retryCount - 1,
               retryDelay * 2,
               alwaysRetry,
               init
            );
         }

         toast.dismiss(RECONNECTING_TOAST_ID);
         throw err;
      }
   }

   /*
   retryOverride:
   true = always retry if request fails.
   false = never retry if request fails, throw error immediately.
   undefined = possibly retry if request fails based on certain criteria such as method and response.
   */
   private async fetch<T>(
      route: string,
      method: string,
      params?: Record<string, string>,
      headers?: Record<string, string>,
      body?: any,
      retryOverride?: boolean
   ): Promise<T | null> {
      const url = new URL(route, this.endpoint);

      url.search = new URLSearchParams(params).toString();

      if (!headers) {
         headers = {};
      }

      let requestBody = body;
      if (body) {
         if (body instanceof FormData) {
            requestBody = body;
         } else {
            requestBody = JSON.stringify(body);

            const contentType = headers['Content-Type'];
            if (!contentType) {
               headers['Content-Type'] = 'application/json';
            }
         }
      }
      let response;
      if (retryOverride === false) {
         // retryOverride = false: we NEVER want to retry.
         response = await fetch(url, {
            method,
            body: requestBody,
            headers,
            credentials: 'include',
         });
      } else {
         // retryOverride = true: we always want to retry if request fails.
         // retryOverride = undefined: we want to retry if request fails and it meets the criteria for retryable.
         response = await this.fetchWithRetry(url, RETRY_ATTEMPTS, RETRY_DELAY, !!retryOverride, {
            method,
            body: requestBody,
            headers,
            credentials: 'include',
         });
      }

      if (response.status === 401) {
         const redirectTo = window.location.pathname;
         window.history.replaceState({ redirectTo }, '', `/signin`);
         window.location.reload();
         return null;
      }

      if (response.status === 303) {
         throw new SeeOther(await response.json());
      }

      if (!response.ok) {
         let message = undefined;
         try {
            const responseJson = await response.json();
            if (responseJson.validationResult && responseJson.validationResult.length > 0) {
               // include validation messages in error message
               message = `${responseJson.message}: `;
               message += responseJson.validationResult.map((result: any) => result.msg).join('\n');
            } else {
               message = responseJson.message;
            }
         } catch (e) {} // Wasn't JSON.
         console.error(
            `API request failed with status '${response.status}' and message '${
               message ?? response.statusText
            }'.`
         );
         throw new ApiError(response.status, message ?? response.statusText);
      }

      if (response.status === 204 || response.headers.get('Content-Length') === '0') {
         return null;
      }

      const contentType = response.headers.get('Content-Type');
      if (!contentType || contentType.startsWith('application/json')) {
         const data = await response.json();
         return data as T;
      }
      return (await response.text()) as T;
   }

   toFormData(obj: Record<string, any>, formData = new FormData(), parentKey = '') {
      for (const key in obj) {
         if (obj.hasOwnProperty(key)) {
            const fullKey = parentKey ? `${parentKey}[${key}]` : key;
            const value = obj[key];
            if (value === undefined) continue;

            if (typeof value === 'boolean') {
               formData.append(fullKey, value ? 'true' : 'false');
            } else if (value instanceof Date) {
               formData.append(fullKey, value.toISOString());
            } else if (value instanceof Array) {
               value.forEach((item, index) => {
                  const arrayKey = `${fullKey}[${index}]`;
                  if (typeof item === 'object' && item !== null) {
                     this.toFormData(item, formData, arrayKey);
                  } else {
                     formData.append(arrayKey, item);
                  }
               });
            } else if (value instanceof Object && !(value instanceof File)) {
               this.toFormData(value, formData, fullKey);
            } else {
               formData.append(fullKey, value);
            }
         }
      }
      return formData;
   }
}
