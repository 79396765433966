import {
   ColumnDefinition,
   ColumnModify,
   QueryBuilder,
   RelationDefinition,
   RelationModify,
} from './QueryBuilder';

export interface DatabricksColumnModify extends ColumnModify {
   description?: string;
}
export enum DatabricksDataType {
   ARRAY = 'ARRAY',
   BIGINT = 'BIGINT',
   BINARY = 'BINARY',
   BOOLEAN = 'BOOLEAN',
   BYTE = 'BYTE',
   CHAR = 'CHAR',
   CHARACTER = 'CHARACTER',
   DATE = 'DATE',
   DECIMAL = 'DECIMAL',
   DOUBLE = 'DOUBLE',
   FLOAT = 'FLOAT',
   INT = 'INT',
   LONG = 'LONG',
   SHORT = 'SHORT',
   SMALLINT = 'SMALLINT',
   STRING = 'STRING',
   TIMESTAMP = 'TIMESTAMP',
   TINYINT = 'TINYINT',
   VARBINARY = 'VARBINARY',
   VARCHAR = 'STRING',
}

export const DatabricksNumericColumns = [
   DatabricksDataType.BIGINT,
   DatabricksDataType.BYTE,
   DatabricksDataType.DECIMAL,
   DatabricksDataType.DOUBLE,
   DatabricksDataType.FLOAT,
   DatabricksDataType.INT,
   DatabricksDataType.LONG,
   DatabricksDataType.SHORT,
   DatabricksDataType.SMALLINT,
   DatabricksDataType.TINYINT,
];

export interface DatabricksColumnModify extends ColumnModify {
   comment?: string;
   extra?: string;
}

export class DatabricksQueryBuilder extends QueryBuilder {
   public beginTransaction(): string {
      return '';
   }
   public wrapIdentifier(identifier: string): string {
      return `\`${identifier}\``;
   }
   public isTypeNumeric(column: ColumnDefinition): boolean {
      return DatabricksNumericColumns.includes(column.dataType as DatabricksDataType);
   }

   public qualifyTable(table: string, schema?: string, catalog?: string): string {
      const catalogIdentifier = catalog ? `${this.wrapIdentifier(catalog)}.` : '';
      return schema
         ? `${catalogIdentifier}${this.wrapIdentifier(schema)}.${this.wrapIdentifier(table)}`
         : this.wrapIdentifier(table);
   }

   public update({
      updates,
      catalog,
      table,
      schema,
      where,
   }: {
      catalog?: string;
      schema?: string;
      table: string;
      updates: Record<string, ColumnDefinition>;
      where: Record<string, any>;
   }): string {
      // Building SET clause for the update
      const setClause = Object.entries(updates)
         .map(([column, value]) => {
            return `${this.wrapIdentifier(column)} = ${this.getQuotedValue(value.data, value)}`;
         })
         .join(', ');

      return `UPDATE ${this.qualifyTable(
         table,
         schema,
         catalog
      )} SET ${setClause} WHERE ${this.buildWhereClause(where)};`;
   }

   public insert({
      catalog,
      table,
      schema,
      insertData,
   }: {
      catalog?: string; // Not all DBMS support catalog
      insertData: Record<string, any>;
      schema?: string;
      table: string;
   }): string {
      const columns = Object.keys(insertData)
         .map((col) => this.wrapIdentifier(col))
         .join(', ');
      const values = Object.values(insertData)
         .map((v) => this.getQuotedValue(v))
         .join(', ');

      return `INSERT INTO ${this.qualifyTable(
         table,
         schema,
         catalog
      )} (${columns}) VALUES (${values});`;
   }

   public delete({
      catalog,
      table,
      schema,
      where,
   }: {
      catalog?: string;
      schema?: string;
      table: string;
      where: Record<string, any>;
   }): string {
      return `DELETE FROM ${this.qualifyTable(
         table,
         schema,
         catalog
      )} WHERE ${this.buildWhereClause(where)};`;
   }

   public alterTable({
      catalog,
      columns,
      table,
      schema,
   }: {
      catalog?: string;
      columns: Record<string, { definition?: ColumnDefinition; modify: DatabricksColumnModify }>;
      schema?: string;
      table: string;
   }): string {
      const alterTable = `ALTER TABLE ${this.qualifyTable(table, schema, catalog)}`;

      const columnChanges = Object.entries(columns).flatMap(([column, { modify, definition }]) => {
         const changeScripts: string[] = [];
         const dataType = modify.dataType ?? definition?.dataType;
         const defaultVal =
            modify.default !== undefined
               ? `${modify.default}`
               : definition?.default !== undefined
               ? `${definition.default}`
               : '';
         const nullable =
            modify.nullable !== undefined
               ? modify.nullable
                  ? 'DROP NOT NULL'
                  : 'SET NOT NULL'
               : '';
         const comment =
            modify.comment !== undefined
               ? `${modify.comment}`
               : definition?.comment !== undefined
               ? `${definition.comment}`
               : '';

         if (modify.isNew) {
            changeScripts.push(
               `${alterTable} ADD COLUMN ${this.wrapIdentifier(column)} ${dataType ?? ''}`
            );

            if (modify.default) {
               changeScripts.push(
                  `${alterTable} SET TBLPROPERTIES('delta.feature.allowColumnDefaults' = 'supported')`
               );
               changeScripts.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(
                     column
                  )} SET DEFAULT '${defaultVal}'`
               );
            }
            if (modify.comment) {
               changeScripts.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(column)} COMMENT '${comment}'`
               );
            }
            if (nullable) {
               changeScripts.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(column)} ${nullable}`
               );
            }
         } else if (modify.drop) {
            changeScripts.push(`${alterTable} DROP COLUMN ${this.wrapIdentifier(column)}`);
         } else {
            if (modify.rename && modify.rename !== column) {
               changeScripts.push(
                  `${alterTable} RENAME COLUMN ${this.wrapIdentifier(
                     column
                  )} TO ${this.wrapIdentifier(modify.rename)}`
               );
            }

            if (modify.default) {
               changeScripts.push(
                  `${alterTable} SET TBLPROPERTIES('delta.feature.allowColumnDefaults' = 'supported')`
               );
               changeScripts.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(
                     modify.rename ?? column
                  )} SET DEFAULT '${defaultVal}'`
               );
            }
            if (modify.comment) {
               changeScripts.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(
                     modify.rename ?? column
                  )} COMMENT '${comment}'`
               );
            }
            if (nullable) {
               changeScripts.push(
                  `${alterTable} ALTER COLUMN ${this.wrapIdentifier(
                     modify.rename ?? column
                  )} ${nullable}`
               );
            }
         }

         return changeScripts;
      });

      if (columnChanges.length > 0) {
         return columnChanges.join(';\n') + ';';
      }
      return '';
   }

   public alterRelation({
      catalog,
      relations,
      table,
      schema,
   }: {
      catalog?: string;
      relations: Record<string, { definition?: RelationDefinition; modify: RelationModify }>;
      schema?: string;
      table: string;
   }): string {
      const alterTable = `ALTER TABLE ${this.qualifyTable(table, schema, catalog)}\n`;
      const relationChanges = Object.entries(relations).map(([relation, { modify }]) => {
         const changeScripts: string[] = [];
         if (modify.isNew) {
            const foreignKeyColumns = modify.columns
               ?.map((col) => this.wrapIdentifier(col))
               .join(', ');
            const referencedColumns = modify.fkColumns
               ?.map((col) => this.wrapIdentifier(col))
               .join(', ');
            const fkTable = modify.fkTable || '';

            changeScripts.push(
               `ADD CONSTRAINT ${this.wrapIdentifier(relation)} FOREIGN KEY (${foreignKeyColumns})
               REFERENCES ${this.qualifyTable(fkTable, schema, catalog)} (${referencedColumns})
               ON UPDATE ${modify.onUpdate} ON DELETE ${modify.onDelete}`
            );
         } else if (modify.drop) {
            changeScripts.push(`DROP CONSTRAINT ${this.wrapIdentifier(relation)}`);
         }
         return changeScripts.join('\n');
      });

      if (relationChanges.length > 0) {
         return `${alterTable} ${relationChanges.join('\n')};`;
      }
      return '';
   }

   public endTransaction(): string {
      return '';
   }

   public explainQuery(query: string, analyze?: boolean): string {
      if (!analyze) return `EXPLAIN ${query}`;
      return `EXPLAIN EXTENDED ${query}`;
   }

   // -- Delete Table

   protected ifExists = 'IF EXISTS';

   // -- Create Table

   protected increments(
      name: string = 'id',
      { nullable = false, primaryKey = true }: { nullable?: boolean; primaryKey?: boolean } = {}
   ) {
      let columnDefinition = `${this.wrapIdentifier(
         name
      )} BIGINT GENERATED BY DEFAULT AS IDENTITY (START WITH 1 INCREMENT BY 1)`;

      if (primaryKey) {
         columnDefinition += ' PRIMARY KEY';
      }

      return columnDefinition;
   }

   public deleteTable({
      catalog,
      force = false,
      schema,
      table,
   }: {
      catalog?: string;
      force?: boolean;
      schema: string;
      table: string;
   }) {
      const statements = [
         `${`DROP TABLE ${this.ifExists}`.trim()} ${this.qualifyTable(table, schema, catalog)};`,
      ];

      if (!this.supportsDisablingForeignKeyChecks) {
         return statements;
      }

      return force ? this.disableForeignKeyChecks(statements) : statements;
   }

   public createTable({
      catalog,
      schema,
      table,
   }: {
      catalog?: string;
      schema: string;
      table: string;
   }) {
      return `CREATE TABLE ${this.qualifyTable(table, schema, catalog)} (
  ${this.increments()}
);`;
   }
}
