import { QueryBuilder } from './QueryBuilder';

export enum PostgresDataType {
   BIGINT = 'BIGINT',
   BIGSERIAL = 'BIGSERIAL',
   BIT = 'BIT',
   BIT_VARYING = 'BIT VARYING',
   BOOLEAN = 'BOOLEAN',
   BOX = 'BOX',
   BYTEA = 'BYTEA',
   CHARACTER = 'CHARACTER',
   CHARACTER_VARYING = 'CHARACTER VARYING',
   CIDR = 'CIDR',
   CIRCLE = 'CIRCLE',
   DATE = 'DATE',
   DOUBLE_PRECISION = 'DOUBLE PRECISION',
   INET = 'INET',
   INTEGER = 'INTEGER',
   INTERVAL = 'INTERVAL',
   JSON = 'JSON',
   JSONB = 'JSONB',
   LINE = 'LINE',
   LSEG = 'LSEG',
   MACADDR = 'MACADDR',
   MACADDR8 = 'MACADDR8',
   MONEY = 'MONEY',
   NUMERIC = 'NUMERIC',
   PATH = 'PATH',
   PG_LSN = 'PG_LSN',
   PG_SNAPSHOT = 'PG_SNAPSHOT',
   POINT = 'POINT',
   POLYGON = 'POLYGON',
   REAL = 'REAL',
   SERIAL = 'SERIAL',
   SMALLINT = 'SMALLINT',
   SMALLSERIAL = 'SMALLSERIAL',
   TEXT = 'TEXT',
   TIMESTAMP_WITHOUT_TIME_ZONE = 'TIMESTAMP WITHOUT TIME ZONE',
   TIMESTAMP_WITH_TIME_ZONE = 'TIMESTAMP WITH TIME ZONE',
   TIME_WITHOUT_TIME_ZONE = 'TIME WITHOUT TIME ZONE',
   TIME_WITH_TIME_ZONE = 'TIME WITH TIME ZONE',
   TSQUERY = 'TSQUERY',
   TSVECTOR = 'TSVECTOR',
   TXID_SNAPSHOT = 'TXID_SNAPSHOT',
   UUID = 'UUID',
   XML = 'XML',
}

export class PostgresQueryBuilder extends QueryBuilder {
   public explainQuery(query: string, analyze?: boolean): string {
      if (!analyze) return `EXPLAIN ${query}`;
      return `EXPLAIN ANALYZE ${query}`;
   }
   // -- Delete Table

   protected ifExists = 'IF EXISTS';

   // -- Create Table

   protected increments(
      name: string = 'id',
      { nullable = true, primaryKey = true }: { nullable?: boolean; primaryKey?: boolean } = {}
   ) {
      return `${this.wrapIdentifier(name)} integer GENERATED BY DEFAULT AS IDENTITY${
         nullable || primaryKey ? ' NOT NULL' : ''
      }${primaryKey ? ' PRIMARY KEY' : ''}`;
   }
}
