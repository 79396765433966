import {
   ColumnDefinition,
   ColumnModify,
   QueryBuilder,
   RelationDefinition,
   RelationModify,
} from './QueryBuilder';

export enum OracleDataType {
   BFILE = 'BFILE',
   BINARY_DOUBLE = 'BINARY_DOUBLE',
   BINARY_FLOAT = 'BINARY_FLOAT',
   BINARY_ROWID = 'BINARY ROWID',
   BLOB = 'BLOB',
   CFILE = 'CFILE',
   CHAR = 'CHAR',
   CLOB = 'CLOB',
   CONTENT_POINTER = 'CONTENT POINTER',
   CONTINGUOUS_ARRAY = 'CONTIGUOUS ARRAY',
   DATE = 'DATE',
   DECIMAL = 'DECIMAL',
   DOUBLE_PRECISION = 'DOUBLE PRECISION',
   FLOAT = 'FLOAT',
   INTEGER = 'INTEGER',
   INTERVAL_DAY_TO_SECOND = 'INTERVAL DAY TO SECOND',
   INTERVAL_YEAR_TO_MONTH = 'INTERVAL YEAR TO MONTH',
   JSON = 'JSON',
   LOB_POINTER = 'LOB POINTER',
   LONG = 'LONG',
   LONG_RAW = 'LONG RAW',
   NAMED_COLLECTION = 'NAMED COLLECTION',
   NAMED_OBJECT = 'NAMED OBJECT',
   NCHAR = 'NCHAR',
   NCLOB = 'NCLOB',
   NUMBER = 'NUMBER',
   NVARCHAR2 = 'NVARCHAR2',
   OCTET = 'OCTET',
   OID = 'OID',
   RAW = 'RAW',
   REAL = 'REAL',
   REF = 'REF',
   REF_CURSOR = 'REF CURSOR',
   ROWID = 'ROWID',
   SMALLINT = 'SMALLINT',
   TABLE = 'TABLE',
   TIME = 'TIME',
   TIMESTAMP = 'TIMESTAMP',
   TIMESTAMP_WITH_LOCAL_TIME_ZONE = 'TIMESTAMP WITH LOCAL TIME ZONE',
   TIMESTAMP_WITH_LOCAL_TZ = 'TIMESTAMP WITH LOCAL TZ',
   TIMESTAMP_WITH_TIME_ZONE = 'TIMESTAMP WITH TIME ZONE',
   TIMESTAMP_WITH_TZ = 'TIMESTAMP WITH TZ',
   TIME_WITH_TZ = 'TIME WITH TZ',
   UROWID = 'UROWID',
   VARCHAR = 'VARCHAR',
   VARCHAR2 = 'VARCHAR2',
   VARRAY = 'VARRAY',
   VARYING_ARRAY = 'VARYING ARRAY',
}

export class OracleQueryBuilder extends QueryBuilder {
   public beginTransaction(): string {
      return '';
   }

   public endTransaction(): string {
      return 'COMMIT;';
   }

   // -- Create Table

   protected increments(
      name: string = 'id',
      { nullable = true, primaryKey = true }: { nullable?: boolean; primaryKey?: boolean } = {}
   ) {
      return `${this.wrapIdentifier(name)} NUMBER GENERATED BY DEFAULT AS IDENTITY${
         nullable || primaryKey ? ' NOT NULL' : ''
      }${primaryKey ? ' PRIMARY KEY' : ''}`;
   }

   public alterTable({
      catalog,
      columns,
      table,
      schema,
   }: {
      catalog?: string; // Not all DBMS support catalog
      columns: Record<string, { definition?: ColumnDefinition; modify: ColumnModify }>;
      schema?: string;
      table: string;
   }): string {
      const columnChanges = Object.entries(columns).map(([column, { modify }]) => {
         const changeScripts: string[] = [];
         if (modify.isNew) {
            const nullable = modify.nullable ? '' : 'NOT NULL';
            const defaultValue = modify.default ? `DEFAULT ${modify.default} ` : '';
            changeScripts.push(
               `ALTER TABLE ${this.qualifyTable(table, schema)} ADD ${column} ${
                  modify.dataType
               } ${defaultValue}${nullable}`
            );
         } else if (modify.drop) {
            changeScripts.push(
               `ALTER TABLE ${this.qualifyTable(table, schema)} DROP COLUMN ${column}`
            );
         } else {
            const displayDataType = modify.dataType ? `${modify.dataType} ` : '';
            const displayDefault =
               modify.default !== undefined
                  ? `DEFAULT ${modify.default === '' ? '""' : modify.default} `
                  : '';
            const displayNullable =
               modify.nullable !== undefined ? (modify.nullable ? 'NULL' : 'NOT NULL') : '';
            if (displayDataType || displayDefault || displayNullable) {
               changeScripts.push(
                  `ALTER TABLE ${this.qualifyTable(
                     table,
                     schema
                  )} MODIFY ${column} ${displayDataType}${displayDefault}${displayNullable}`
               );
            }
            if (modify.rename) {
               changeScripts.push(
                  `ALTER TABLE ${this.qualifyTable(table, schema)} RENAME COLUMN ${column} TO ${
                     modify.rename
                  }`
               );
            }
         }
         return changeScripts.join(';\n');
      });

      if (columnChanges.length > 0) {
         return `${columnChanges.join(',\n')};`;
      }
      return '';
   }

   public alterRelation({
      relations,
      table,
      schema,
   }: {
      relations: Record<string, { definition?: RelationDefinition; modify: RelationModify }>;
      schema?: string;
      table: string;
   }): string {
      const relationChanges = Object.entries(relations).map(([relation, { modify }]) => {
         const changeScripts: string[] = [];

         // If it's a new relation, create the foreign key constraint
         if (modify.isNew) {
            const foreignKeyColumns = modify.columns?.join(', ');
            const referencedColumns = modify.fkColumns?.join(', ');
            const fkTable = this.wrapIdentifier(modify.fkTable || '');

            const displayOnDelete =
               modify.onDelete !== undefined && modify.onDelete !== 'NO ACTION'
                  ? `ON DELETE ${modify.onDelete}`
                  : '';

            changeScripts.push(
               `ALTER TABLE ${this.qualifyTable(table, schema)}
   ADD CONSTRAINT "${relation}" FOREIGN KEY ("${foreignKeyColumns}") REFERENCES ${this.qualifyTable(
                  fkTable.replaceAll('"', ''),
                  schema
               )} ("${referencedColumns}")${displayOnDelete}`
            );
         }
         // If the relation should be dropped
         else if (modify.drop) {
            changeScripts.push(`ALTER TABLE ${this.qualifyTable(table, schema)}
   DROP CONSTRAINT ${this.wrapIdentifier(relation)}`);
         }
         return changeScripts.join('\n');
      });

      if (relationChanges.length > 0) {
         return `${relationChanges.join('\n')};`;
      }
      return '';
   }

   public explainQuery(query: string, analyze?: boolean): string {
      return `EXPLAIN PLAN FOR ${query}; SELECT * FROM TABLE(DBMS_XPLAN.DISPLAY);`;
   }
   // -- Fetch Table Content

   public fetchTableContent(
      {
         catalog,
         schema,
         table,
      }: {
         catalog?: string;
         schema: string;
         table: string;
      },
      { limit = 10_000 }: { limit?: number | null } = {}
   ) {
      if (limit === null) {
         return super.fetchTableContent({ catalog, schema, table }, { limit });
      }

      return `SELECT *
FROM (
  SELECT *
  FROM ${this.qualifyTable(table, schema, catalog)}
)
WHERE ROWNUM <= ${limit};`;
   }
}
