import { useState } from 'react';
import classNames from 'classnames';
import { Nav, NavItem, NavLink, Stack, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { AiPulse } from './AiSparkles';

export type PageTab = {
   content: React.ReactNode;
   pulse?: boolean;
   title: string;
};
export const PageTabs = ({
   action,
   className,
   noScroll,
   onTabChange,
   tabs,
   toolbar,
}: {
   action?: React.ReactNode;
   className?: string;
   noScroll?: boolean;
   onTabChange?: (tabKey: string | null) => void;
   tabs: PageTab[];
   toolbar?: React.ReactNode;
}): JSX.Element => {
   const [tab, setTab] = useState('0');
   return (
      <TabContainer activeKey={tab} onSelect={(t) => t && setTab(t)} transition={false}>
         <div className="h-100 d-flex flex-column overflow-hidden">
            <Stack
               className={classNames('align-items-end w-100', className)}
               direction="horizontal"
               gap={2}
            >
               <Stack className="d-flex flex-nowrap align-items-center" gap={2}>
                  {toolbar && <div>{toolbar}</div>}

                  <Nav
                     className="d-flex flex-row flex-nowrap align-items-center"
                     onSelect={onTabChange}
                     role="tablist"
                  >
                     <Stack className="nav-tabs-alt d-flex flex-row" direction="horizontal" gap={2}>
                        {tabs.map((t, i) => (
                           <AiPulse key={i} on={t.pulse}>
                              <NavItem className={tab === i.toString() ? 'active' : ''} key={i}>
                                 <NavLink
                                    className="position-relative override-active-pointer"
                                    eventKey={i.toString()}
                                    role={'tab'}
                                 >
                                    {t.title}
                                 </NavLink>
                              </NavItem>
                           </AiPulse>
                        ))}
                     </Stack>
                  </Nav>
               </Stack>
               {action}
            </Stack>

            <TabContent className="flex-grow-1 overflow-hidden">
               {tabs.map((t, i) => (
                  <TabPane
                     className={classNames('h-100 p-3 pb-0', { 'overflow-auto': !noScroll })}
                     eventKey={i}
                     key={i}
                  >
                     {t.content}
                  </TabPane>
               ))}
            </TabContent>
         </div>
      </TabContainer>
   );
};

export default PageTabs;
