import { type CurrentExplorerResult } from '../../services/PersonService';
import { useGetAuthorizedExplorerQuery } from '../accountHooks';
import { Person } from '../../entities';

// This will always return a value in Authed components.
export const useExplorer = () => {
   // This mess can be cleaned up once we update the web entities with required
   // types.
   return useGetAuthorizedExplorerQuery({ storePersonId: true }).data!
      .person as unknown as CurrentExplorerResult;
};

export const usePerson = (): Person => {
   const explorer = useExplorer();
   return {
      ...explorer,
      created: new Date(explorer.created),
   };
};
