import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Stack } from 'react-bootstrap';

import { WEB_VERSION } from '../version';
import { Button } from '../components/Button';

// versionCheck.ts
export async function fetchCurrentVersion(): Promise<string | null> {
   try {
      const response = await fetch('/version.json', { cache: 'no-store' });
      const data = (await response.json()) as { version: string };
      return data.version;
   } catch (error) {
      if (process.env.NODE_ENV !== 'development') {
         console.error('Failed to fetch version file:', error);
      }
      return null;
   }
}

const TOAST_ID = '__RUNQL_UPDATE_AVAILABLE__';

const ToastContainer = ({ children }: React.PropsWithChildren<{}>) => (
   <Stack className="align-items-center py-1 px-2" gap={1}>
      {children}
   </Stack>
);

export function useVersionCheck() {
   const [ready, setReady] = useState(false);
   const [upgrade, setUpgrade] = useState<string>();
   const [desktopUpdate, setDesktopUpdate] = useState<string>();
   const desktopUpdateMetaRef = useRef({
      isDownloaded: false,
      numChecksWhenAvailable: 0,
   });

   useEffect(() => {
      const checkVersion = async () => {
         // -- Check for desktop update

         if (
            globalThis.runql?.checkForUpdates !== undefined &&
            !desktopUpdateMetaRef.current.isDownloaded
         ) {
            const desktopUpdateCheckResult = await globalThis.runql.checkForUpdates().catch(() => {
               // There is a bug in desktop clients <= v1.0.14. When an update is available,
               // checkForUpdates() is supposed to resolve with an UpdateCheckResult object
               // containing a downloadPromise, but the downloadPromise can't be serialized and sent
               // over the IPC bridge. As a result, checkForUpdates() throws and `setReady(true)`
               // wasn't being called, preventing the app from initializing properly. We are
               // catching the error as a temporary workaround to unblock users. The update should
               // still download in the background, and when complete, if the user restarts the app,
               // the update will be installed.
               return null;
            });

            // This check is required to maintain backwards compatibility with older desktop client
            // versions where checkForUpdates resolves with UpdateCheckResult | null.
            if (typeof desktopUpdateCheckResult?.type === 'string') {
               if (desktopUpdateCheckResult.type === 'updateDownloaded') {
                  desktopUpdateMetaRef.current.isDownloaded = true;
                  setReady(true);
                  setDesktopUpdate(desktopUpdateCheckResult.version);
                  return;
               }

               // If a desktop update is available, then we do not want to also check for a web
               // update. But after 3 checks, we'll resume checking for web updates in case there
               // was an issue downloading the desktop update.
               if (
                  desktopUpdateCheckResult.type === 'updateAvailable' &&
                  desktopUpdateMetaRef.current.numChecksWhenAvailable < 3
               ) {
                  desktopUpdateMetaRef.current.numChecksWhenAvailable += 1;
                  setReady(true);
                  return;
               }
            }
         }

         // -- Check for web update

         const latestVersion = await fetchCurrentVersion();
         if (latestVersion && latestVersion !== WEB_VERSION) {
            setUpgrade(latestVersion);
         } else {
            setReady(true);
         }
      };

      checkVersion();
      const intervalId = setInterval(checkVersion, 1000 * 60 * 5);

      return () => clearInterval(intervalId);
   }, []);

   useEffect(() => {
      if (process.env.REACT_APP_APP_ENV === 'staging') {
         setReady(true);
         return;
      }
      if (desktopUpdate !== undefined) {
         toast(
            <ToastContainer>
               <span className="fw-semibold">A new version of runQL is available!</span>
               <span>Please restart to install the latest version</span>
               <Button
                  className="align-self-stretch"
                  onClick={() => {
                     globalThis.runql?.restartAndInstall();
                  }}
               >
                  Restart
               </Button>
            </ToastContainer>,
            {
               autoClose: false,
               toastId: TOAST_ID,
            }
         );
         return;
      }

      if (upgrade !== undefined) {
         // We only auto-refresh once for a given version (via else block below), just in case
         // something is wrong with the update process (e.g. version.txt is out of sync or there's a
         // caching issue).
         const attempt = localStorage.getItem('upgrade-attempt');
         if (ready || attempt === upgrade) {
            toast(
               <ToastContainer>
                  <span className="fw-semibold">runQL just got better!</span>
                  <Button
                     className="align-self-stretch"
                     onClick={() => {
                        window.location.reload();
                     }}
                  >
                     Update Now
                  </Button>
               </ToastContainer>,
               {
                  autoClose: false,
                  toastId: TOAST_ID,
               }
            );
            setReady(true);
         } else {
            // If we detect an upgrade on initial load, simply refresh
            localStorage.setItem('upgrade-attempt', upgrade);
            window.location.reload();
         }
      }
   }, [ready, upgrade, desktopUpdate]);

   return ready;
}
