"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readSchemaTargetSchema = exports.schemaItemSchema = exports.SchemaItemType = exports.SSHAuthenticationMethod = exports.SSLMode = exports.ColumnNull = exports.DBMS_ANALYZE_SUPPORTED = exports.DBMS_EXPLAIN_SUPPORTED = exports.DBMS_SCHEMA_SELECT_SUPPORTED = exports.DBMS_DATABASE_REQUIRED = exports.DBMS_HOST_REQUIRED = exports.DBMS = void 0;
const zod_1 = require("zod");
var DBMS;
(function (DBMS) {
    DBMS["CHAT_GPT"] = "Chat GPT";
    DBMS["Federated"] = "Federated";
    DBMS["Hugging_Face"] = "Hugging Face";
    DBMS["MySQL"] = "MySQL";
    DBMS["Postgres"] = "Postgres";
    DBMS["Redshift"] = "Redshift";
    DBMS["Snowflake"] = "Snowflake";
    DBMS["MSSQL"] = "MSSQL";
    DBMS["Neo4j"] = "Neo4j";
    DBMS["Big_Query"] = "Big Query";
    DBMS["Databricks"] = "Databricks";
    DBMS["Oracle"] = "Oracle";
    DBMS["Trino"] = "Trino";
    DBMS["MongoDB"] = "MongoDB";
    DBMS["Python"] = "Python";
})(DBMS || (exports.DBMS = DBMS = {}));
exports.DBMS_HOST_REQUIRED = [
    DBMS.MySQL,
    DBMS.Redshift,
    DBMS.Postgres,
    DBMS.Hugging_Face,
    DBMS.MSSQL,
    DBMS.Neo4j,
    DBMS.Databricks,
    DBMS.MongoDB,
];
exports.DBMS_DATABASE_REQUIRED = [DBMS.Postgres, DBMS.Oracle];
exports.DBMS_SCHEMA_SELECT_SUPPORTED = [
    DBMS.Databricks,
    DBMS.MySQL,
    DBMS.Neo4j,
    DBMS.Oracle,
    DBMS.Postgres,
    DBMS.Snowflake,
    DBMS.Trino,
    DBMS.MongoDB,
];
exports.DBMS_EXPLAIN_SUPPORTED = [
    DBMS.Postgres,
    DBMS.MySQL,
    DBMS.MSSQL,
    DBMS.Oracle,
    DBMS.Databricks,
    DBMS.Redshift,
    DBMS.Snowflake,
];
exports.DBMS_ANALYZE_SUPPORTED = [
    DBMS.Postgres,
    DBMS.MySQL,
    //DBMS.MSSQL, MSSQL should be able to support this but the library we use doesn't support it
    DBMS.Databricks,
];
var ColumnNull;
(function (ColumnNull) {
    ColumnNull["NULL"] = "NULL";
    ColumnNull["NOT_NULL"] = "NOT NULL";
})(ColumnNull || (exports.ColumnNull = ColumnNull = {}));
var SSLMode;
(function (SSLMode) {
    SSLMode[SSLMode["OPTIONAL"] = 1] = "OPTIONAL";
    SSLMode[SSLMode["VALIDATED"] = 2] = "VALIDATED";
    // We could add support for not validating the certificate if needed.
})(SSLMode || (exports.SSLMode = SSLMode = {}));
var SSHAuthenticationMethod;
(function (SSHAuthenticationMethod) {
    SSHAuthenticationMethod[SSHAuthenticationMethod["PASSWORD"] = 1] = "PASSWORD";
    SSHAuthenticationMethod[SSHAuthenticationMethod["KEY_FILE"] = 2] = "KEY_FILE";
})(SSHAuthenticationMethod || (exports.SSHAuthenticationMethod = SSHAuthenticationMethod = {}));
var SchemaItemType;
(function (SchemaItemType) {
    SchemaItemType["CATALOG"] = "catalog";
    SchemaItemType["SCHEMA"] = "schema";
    SchemaItemType["TABLE"] = "table";
    SchemaItemType["COLUMN"] = "column";
    SchemaItemType["INDEX"] = "index";
    SchemaItemType["STORED_PROCEDURE"] = "storedProcedure";
    SchemaItemType["TRIGGER"] = "trigger";
    SchemaItemType["RELATION"] = "relation";
})(SchemaItemType || (exports.SchemaItemType = SchemaItemType = {}));
exports.schemaItemSchema = zod_1.z.object({
    type: zod_1.z.nativeEnum(SchemaItemType),
    catalogName: zod_1.z.string().nullable().optional(),
    columnCharSet: zod_1.z.string().nullable().optional(),
    columnCollation: zod_1.z.string().nullable().optional(),
    columnComment: zod_1.z.string().nullable().optional(),
    columnDefault: zod_1.z.string().nullable().optional(),
    columnDefaultConstraint: zod_1.z.string().nullable().optional(),
    columnExtra: zod_1.z.string().nullable().optional(),
    columnKey: zod_1.z.string().nullable().optional(),
    columnName: zod_1.z.string().nullable().optional(),
    columnNullable: zod_1.z.nativeEnum(ColumnNull).nullable().optional(),
    columnType: zod_1.z.string().nullable().optional(),
    columnCount: zod_1.z.number().nullable().optional(),
    columnIsPrimaryKey: zod_1.z.union([
        zod_1.z.number().optional(),
        zod_1.z.boolean().transform((value) => (value ? 1 : 0)),
    ]),
    columnOrder: zod_1.z.number().nullable().optional(),
    schemaDefaultCharSet: zod_1.z.string().nullable().optional(),
    schemaDefaultCollation: zod_1.z.string().nullable().optional(),
    schemaName: zod_1.z.string().nullable().optional(),
    schemaRelation: zod_1.z.string().nullable().optional(),
    tableCharSet: zod_1.z.string().nullable().optional(),
    tableCollation: zod_1.z.string().nullable().optional(),
    tableEngine: zod_1.z.string().nullable().optional(),
    tableName: zod_1.z.string().nullable().optional(),
    isView: zod_1.z.union([
        zod_1.z.number().nullable().optional(),
        zod_1.z.boolean().transform((value) => (value ? 1 : 0)),
    ]),
    indexName: zod_1.z.string().nullable().optional(),
    indexNonUnique: zod_1.z.union([
        zod_1.z.number().nullable().optional(),
        zod_1.z.boolean().transform((value) => (value ? 1 : 0)),
    ]),
    indexType: zod_1.z.string().nullable().optional(),
    relationDeleteRule: zod_1.z.string().nullable().optional(),
    relationName: zod_1.z.string().nullable().optional(),
    relationColumnNames: zod_1.z.array(zod_1.z.string()).nullable().optional(),
    relationReferencedColumnNames: zod_1.z.array(zod_1.z.string()).nullable().optional(),
    relationReferencedTableName: zod_1.z.string().nullable().optional(),
    relationUpdateRule: zod_1.z.string().nullable().optional(),
    relationCatalogName: zod_1.z.string().nullable().optional(),
    relationSchema: zod_1.z.string().nullable().optional(),
    storedProcedureName: zod_1.z.string().nullable().optional(),
    storedProcedureType: zod_1.z.string().nullable().optional(),
    storedProcedureComment: zod_1.z.string().nullable().optional(),
    storedProcedureDefinition: zod_1.z.string().nullable().optional(),
    storedProcedureDataType: zod_1.z.string().nullable().optional(),
    triggerName: zod_1.z.string().nullable().optional(),
    triggerTiming: zod_1.z.string().nullable().optional(),
    triggerEvent: zod_1.z.string().nullable().optional(),
    triggerTarget: zod_1.z.string().nullable().optional(),
    triggerDefinition: zod_1.z.string().nullable().optional(),
});
const catalogTargetSchema = zod_1.z.object({
    type: zod_1.z.literal("catalog"),
    catalogName: zod_1.z.string(),
});
const tableTargetSchema = zod_1.z.object({
    type: zod_1.z.literal("table"),
    catalogName: zod_1.z.string().optional(),
    schemaName: zod_1.z.string(),
    tableName: zod_1.z.string(),
});
exports.readSchemaTargetSchema = zod_1.z.union([
    catalogTargetSchema,
    tableTargetSchema,
]);
