import {
   ColumnDefinition,
   ColumnModify,
   QueryBuilder,
   RelationDefinition,
   RelationModify,
} from './QueryBuilder';

export interface RedshiftColumnModify extends ColumnModify {
   comment?: string;
}

export enum RedshiftDataType {
   BIGINT = 'BIGINT',
   BOOLEAN = 'BOOLEAN',
   CHAR = 'CHAR',
   DATE = 'DATE',
   DECIMAL = 'DECIMAL',
   DOUBLE_PRECISION = 'DOUBLE PRECISION',
   GEOGRAPHY = 'GEOGRAPHY',
   GEOMETRY = 'GEOMETRY',
   HLLSKETCH = 'HLLSKETCH',
   INTEGER = 'INTEGER',
   INTERVAL_DS = 'INTERVAL DAY TO SECOND',
   INTERVAL_YM = 'INTERVAL YEAR TO MONTH',
   REAL = 'REAL',
   SMALLINT = 'SMALLINT',
   SUPER = 'SUPER',
   TIME = 'TIME',
   TIMESTAMP = 'TIMESTAMP',
   TIMESTAMPTZ = 'TIMESTAMPTZ',
   TIMETZ = 'TIMETZ',
   VARBYTE = 'VARBYTE',
   VARCHAR = 'VARCHAR',
}

export class RedshiftQueryBuilder extends QueryBuilder {
   protected ifExists = 'IF EXISTS';

   public explainQuery(query: string, analyze?: boolean): string {
      return `EXPLAIN ${query}`;
   }

   // -- Create Table

   protected increments(
      name: string = 'id',
      { nullable = true, primaryKey = true }: { nullable?: boolean; primaryKey?: boolean } = {}
   ) {
      return `${this.wrapIdentifier(name)} integer identity (1, 1)${
         primaryKey ? ' primary key' : ''
      }${nullable || primaryKey ? ' not null' : ''}`;
   }

   public alterTable({
      catalog,
      columns,
      table,
      schema,
   }: {
      catalog?: string;
      columns: Record<string, { definition?: ColumnDefinition; modify: RedshiftColumnModify }>;
      schema?: string;
      table: string;
   }): string {
      const extraQueries: string[] = [];
      const alterTableBase = `ALTER TABLE ${this.qualifyTable(table, schema)}\n`;
      const alterTableQueries = Object.entries(columns).map(
         ([columnName, { definition, modify }]) => {
            const parts: string[] = [];
            if (modify.isNew) {
               const nullable = modify.nullable ? 'NULL' : 'NOT NULL';
               const defaultValue = modify.default ? `DEFAULT ${modify.default}` : '';
               parts.push(
                  `ADD COLUMN ${this.wrapIdentifier(columnName)} ${
                     modify?.dataType
                  } ${nullable} ${defaultValue}`
               );

               if (modify.comment) {
                  extraQueries.push(
                     `COMMENT ON COLUMN ${this.qualifyTable(table, schema)}.${this.wrapIdentifier(
                        columnName
                     )} IS '${modify.comment}'`
                  );
               }
            } else if (modify.drop) {
               parts.push(`DROP COLUMN ${this.wrapIdentifier(columnName)}`);
            } else {
               if (modify.dataType) {
                  parts.push(
                     `ALTER COLUMN ${this.wrapIdentifier(columnName)} TYPE ${modify.dataType}`
                  );
               }

               if (modify.nullable) {
                  console.warn('Modifying nullable is not supported in Redshift');
               }

               if (modify.default) {
                  console.warn('Modifying default values is not supported in Redshift');
               }

               if (modify.comment) {
                  extraQueries.push(
                     `COMMENT ON COLUMN ${this.qualifyTable(table, schema)}.${this.wrapIdentifier(
                        columnName
                     )} IS '${modify.comment}'`
                  );
               }

               if (modify.rename) {
                  parts.push(
                     `RENAME COLUMN ${this.wrapIdentifier(columnName)} TO ${this.wrapIdentifier(
                        modify.rename
                     )};`
                  );
               }
            }

            return parts.join('\n');
         }
      );

      let result = '';
      if (alterTableQueries.length > 0) {
         result += alterTableBase + alterTableQueries.join('\n') + ';';
      }
      if (extraQueries.length > 0) {
         if (result.length > 0) result += '\n';
         result += extraQueries.join(';\n');
         if (extraQueries.length === 1) result += ';';
      }

      return result;
   }

   public alterRelation({
      catalog,
      relations,
      table,
      schema,
   }: {
      catalog?: string;
      relations: Record<string, { definition?: RelationDefinition; modify: RelationModify }>;
      schema?: string;
      table: string;
   }): string {
      const alterTable = `ALTER TABLE ${this.qualifyTable(table, schema)}\n`;
      const relationChanges = Object.entries(relations).map(([relation, { modify }]) => {
         const changeScripts: string[] = [];

         // If it's a new relation, create the foreign key constraint
         if (modify.isNew) {
            const foreignKeyColumns = modify.columns
               ?.map((col) => this.wrapIdentifier(col))
               .join(', ');
            const referencedColumns = modify.fkColumns
               ?.map((col) => this.wrapIdentifier(col))
               .join(', ');
            const fkTable = this.wrapIdentifier(modify.fkTable || '');

            changeScripts.push(
               `ADD CONSTRAINT ${this.wrapIdentifier(relation)} FOREIGN KEY (${foreignKeyColumns}) 
               REFERENCES ${fkTable} (${referencedColumns})`
            );
         }
         // If the relation should be dropped
         else if (modify.drop) {
            changeScripts.push(`DROP CONSTRAINT ${this.wrapIdentifier(relation)}`);
         }
         return changeScripts.join('\n');
      });

      if (relationChanges.length > 0) {
         return `${alterTable} ${relationChanges.join('\n')};`;
      }
      return '';
   }
}
