import { useState, useCallback } from 'react';
import { Form, InputGroup, Stack } from 'react-bootstrap';
import Loader from 'react-spinners/PulseLoader';
import { useInjection } from 'inversify-react';

import { SearchService } from '../services';
import { TYPES } from '../types';
import { QueryVersion } from '../entities';
import { AnswerCard } from '../components';

export const AnswersLite = ({ onRunQuery }: { onRunQuery?: (q: QueryVersion) => void }) => {
   const [query, setQuery] = useState<string>('');
   const [loading, setLoading] = useState(false);
   const [results, setResults] = useState<QueryVersion[]>();
   const searchService = useInjection<SearchService>(TYPES.searchService);

   const search = useCallback(
      async (query: string) => {
         setLoading(true);
         setResults(undefined);
         let results;
         try {
            results = await searchService.answers(query);
            setResults(results);
         } catch (e) {
            console.error(e);
         } finally {
            setLoading(false);
         }
      },
      [searchService]
   );

   return (
      <div className="h-100 d-flex flex-column justify-content-end">
         <div className="p-3">
            <Stack
               className="align-items-center justify-content-center"
               direction="horizontal"
               gap={2}
            >
               <Form
                  className="w-100 align-items-center"
                  onSubmit={(e) => {
                     e.preventDefault();
                     search(query);
                  }}
               >
                  <InputGroup>
                     <Form.Control
                        autoFocus
                        className="small-form-control-input"
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Search your organization's data answers"
                        value={query}
                     />
                     <button
                        className="btn btn-xs btn-primary plausible-event-name--askRuna"
                        type="submit"
                     >
                        Search
                     </button>
                  </InputGroup>
               </Form>
            </Stack>
         </div>
         <Stack className="p-3 overflow-auto" gap={3}>
            {loading && <Loader color="#6366f1" size={6} />}
            {results?.length === 0 && <div>No results found.</div>}
            {results && (
               <div className="answers-grid">
                  {results.map((q) => (
                     <AnswerCard key={q.id} onClick={onRunQuery} queryVersion={q} />
                  ))}
               </div>
            )}
         </Stack>
      </div>
   );
};

export default AnswersLite;
