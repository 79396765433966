import { memo, useMemo } from 'react';

import { formatQuery } from '@runql/util';

import { DBMS } from '../../enums/dbms';
import { QueryInlineComment } from '../../entities';

const CodeViewer = memo(
   ({
      dialect,
      query,
      maxLines,
      schema,
      comments, // deprecated
   }: {
      comments?: QueryInlineComment[];
      dialect?: DBMS;
      maxLines?: number;
      query?: string;
      schema?: Record<string, string[]>;
   }): JSX.Element => {
      query ??= '';

      const wrapKeywords = (text: string) => {
         const sqlKeywords =
            /\b(SELECT|FROM|WHERE|INSERT|UPDATE|DELETE|JOIN|INNER|OUTER|LEFT|RIGHT|AND|OR|NOT|NULL|VALUES|INTO|SET|ON|ORDER BY|GROUP BY|LIMIT|DESC|ASC|IN|COUNT|DISTINCT)\b|([()*\d])|('[^']*')/gi;
         return text.replace(
            sqlKeywords,
            (match) =>
               `<span class="query-${
                  match === '*' ? 'star' : match.startsWith("'") ? 'literal' : 'keyword'
               }">${match}</span>`
         );
      };

      const formattedQuery = useMemo(() => {
         const newQuery = maxLines ? formatQuery(query) : query;
         return newQuery
            .split('\n')
            .map((line) => line.split(/(?<=^|\s)--/))
            .map(([query, comment], index) => [
               wrapKeywords(query),
               comment?.trim() || comments?.find((c) => c.line === index)?.comment,
            ]);
      }, [query, comments, maxLines]);

      if (comments && !Array.isArray(comments)) {
         comments = [comments];
      }

      return (
         <div style={{ position: 'relative' }}>
            <div className="dimmed-query">
               <code className={`cm-editor code-viewer ${maxLines ? 'collapsed-code-viewer' : ''}`}>
                  {formattedQuery
                     .filter(([query]) => !maxLines || query)
                     .slice(0, maxLines) // if maxLines is undefined, it will return all lines
                     .map(([query, comment], index) => (
                        <div className="query-line" key={index}>
                           {query && <span dangerouslySetInnerHTML={{ __html: query }} />}
                           {comment && (
                              <span className="query-comment">&nbsp;--&nbsp;{comment}</span>
                           )}
                        </div>
                     ))}
               </code>
            </div>
         </div>
      );
   }
);
export default CodeViewer;
