import { useInjection } from 'inversify-react';
import { useEffect, useRef, useState } from 'react';
import { Stack } from 'react-bootstrap';
import Loader from 'react-spinners/PulseLoader';

import { QueryVersion, QueryStep, QuerySuggestion, WorkspaceStatus } from '../entities';
import QueryWidget from './QueryWidget';
import { AiPulse } from './AiSparkles';
import { useDebounce } from '../hooks/debounce';
import { SearchService } from '../services';
import { TYPES } from '../types';
import { useWalkthroughStep } from '../hooks/walkthrough';
import { walkthroughStep } from '../entities/Walkthrough';
import { useWorkspace } from '../hooks';

const START_SEARCH_CHAR_COUNT: number = 5;
// Restrict suggestions to within 30% of the top score
const SCORE_THRESHOLD = 0.7;

function queryLongEnough(query?: string) {
   if (query === undefined || !query) return false;
   return query.replace(/select|from/gi, '').replace(/\s/g, '').length >= START_SEARCH_CHAR_COUNT;
}

export const QuerySuggestions = ({
   query,
   step,
   workspaceId,
}: {
   query?: QueryVersion;
   step?: QueryStep;
   workspaceId: number;
}): JSX.Element => {
   const searchService = useInjection<SearchService>(TYPES.searchService);

   const [suggestionsLoading, setSuggestionsLoading] = useState(false);
   const [suggestions, setSuggestions] = useState<QuerySuggestion[]>([]);

   const queryDebounced = useDebounce(step?.queryText ?? '', 1000);
   const currentQueryForSuggestions = useRef<string>();
   const workspace = useWorkspace();

   const [stepThree, setStepThree] = useWalkthroughStep(walkthroughStep.SUGGESTED_QUERY);

   const queryText = step?.queryText;
   useEffect(() => {
      if (!queryLongEnough(queryText)) {
         setSuggestionsLoading(false);
         currentQueryForSuggestions.current = '';
         return;
      }
      setSuggestionsLoading(queryText !== currentQueryForSuggestions.current);
   }, [queryText]);
   useEffect(() => {
      if (!queryDebounced || !queryLongEnough(queryDebounced)) {
         currentQueryForSuggestions.current = queryDebounced;
         return;
      }
      searchService
         .querySuggest({
            workspaceId,
            query: queryDebounced,
            dataConnectionId: step?.dataConnectionId,
         })
         .then((val) => {
            currentQueryForSuggestions.current = queryDebounced;
            // Remove suggestions that match the current query or that are for the same saved query
            let suggestions = val.filter(
               (s) =>
                  s.query?.queryId !== undefined &&
                  s.query.queryId !== query?.id &&
                  (s.query.steps.length > 1 || s.query.steps[0]?.queryText !== queryDebounced)
            );
            const scoreThreshold = (suggestions[0]?.score ?? 0) * SCORE_THRESHOLD;
            // Always include the top 3 unless their score is below 1 (poor match)
            suggestions = suggestions.filter(
               (s, i) => s.score && s.score > 1 && (i < 3 || s.score >= scoreThreshold)
            );
            if (suggestions.length > 0) {
               setSuggestions(suggestions);
            }
            setSuggestionsLoading(false);
         });
   }, [query?.id, queryDebounced, workspaceId, searchService, step?.dataConnectionId]);
   return (
      <Stack gap={3}>
         <Stack className="dimmed-queries" gap={3}>
            {suggestionsLoading && <Loader color="#6366f1" size={6} />}
            {suggestions.length > 0 &&
               queryDebounced?.length > 0 &&
               suggestions.map((s) => (
                  <AiPulse
                     key={s.query.id}
                     on={
                        workspace.status === WorkspaceStatus.PERSONAL_DEMO &&
                        stepThree &&
                        s === suggestions[0]
                     }
                     onClick={() => {
                        if (workspace.status === WorkspaceStatus.PERSONAL_DEMO && stepThree) {
                           setStepThree();
                        }
                     }}
                     sparkleAfter
                  >
                     <QueryWidget
                        action="use"
                        collapse
                        fullWidth
                        key={s.query.id}
                        queryVersion={s.query}
                        source="saved"
                     />{' '}
                  </AiPulse>
               ))}
            {((suggestions.length === 0 && !suggestionsLoading) || queryDebounced.length === 0) && (
               <div className="fs-12p">
                  <div className="fw-bold">
                     Start writing and query suggestions will show here.
                     <br />
                  </div>
               </div>
            )}
         </Stack>
      </Stack>
   );
};
export default QuerySuggestions;
