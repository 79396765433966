import {
   ColumnDefinition,
   ColumnModify,
   IndexModify,
   QueryBuilder,
   RelationDefinition,
   RelationModify,
} from './QueryBuilder';

export enum MySQLDataType {
   BIGINT = 'BIGINT',
   BIGINT_UNSIGNED = 'BIGINT UNSIGNED',
   BINARY = 'BINARY',
   BIT = 'BIT',
   BLOB = 'BLOB',
   BOOL = 'BOOL',
   BOOLEAN = 'BOOLEAN',
   CHAR = 'CHAR',
   DATE = 'DATE',
   DATETIME = 'DATETIME',
   DEC = 'DEC',
   DECIMAL = 'DECIMAL',
   DOUBLE = 'DOUBLE',
   DOUBLE_PRECISION = 'DOUBLE PRECISION',
   DOUBLE_PRECISION_UNSIGNED = 'DOUBLE PRECISION UNSIGNED',
   DOUBLE_UNSIGNED = 'DOUBLE UNSIGNED',
   ENUM = 'ENUM',
   FLOAT = 'FLOAT',
   GEOMETRY = 'GEOMETRY',
   GEOMETRYCOLLECTION = 'GEOMETRYCOLLECTION',
   INT = 'INT',
   INTEGER = 'INTEGER',
   INTEGER_UNSIGNED = 'INTEGER UNSIGNED',
   INT_UNSIGNED = 'INT UNSIGNED',
   JSON = 'JSON',
   LINESTRING = 'LINESTRING',
   LONGBLOB = 'LONGBLOB',
   LONGTEXT = 'LONGTEXT',
   LONG_VARBINARY = 'LONG VARBINARY',
   LONG_VARCHAR = 'LONG VARCHAR',
   MEDIUMBLOB = 'MEDIUMBLOB',
   MEDIUMINT = 'MEDIUMINT',
   MEDIUMINT_UNSIGNED = 'MEDIUMINT UNSIGNED',
   MEDIUMTEXT = 'MEDIUMTEXT',
   MULTILINESTRING = 'MULTILINESTRING',
   MULTIPOINT = 'MULTIPOINT',
   MULTIPOLYGON = 'MULTIPOLYGON',
   NUMERIC = 'NUMERIC',
   POINT = 'POINT',
   POLYGON = 'POLYGON',
   REAL = 'REAL',
   SERIAL = 'SERIAL',
   SET = 'SET',
   SMALLINT = 'SMALLINT',
   SMALLINT_UNSIGNED = 'SMALLINT UNSIGNED',
   TEXT = 'TEXT',
   TIME = 'TIME',
   TIMESTAMP = 'TIMESTAMP',
   TINYBLOB = 'TINYBLOB',
   TINYINT = 'TINYINT',
   TINYTEXT = 'TINYTEXT',
   VARBINARY = 'VARBINARY',
   VARCHAR = 'VARCHAR',
   YEAR = 'YEAR',
}

export const MySQLNumericColumns = [
   MySQLDataType.BIT,
   MySQLDataType.TINYINT,
   MySQLDataType.BOOL,
   MySQLDataType.BOOLEAN,
   MySQLDataType.SMALLINT,
   MySQLDataType.MEDIUMINT,
   MySQLDataType.INT,
   MySQLDataType.INTEGER,
   MySQLDataType.BIGINT,
   MySQLDataType.BIGINT_UNSIGNED,
   MySQLDataType.FLOAT,
   MySQLDataType.DOUBLE,
   MySQLDataType.DOUBLE_PRECISION,
   MySQLDataType.DECIMAL,
   MySQLDataType.DEC,
   MySQLDataType.NUMERIC,
   MySQLDataType.REAL,
   MySQLDataType.SERIAL,
   MySQLDataType.YEAR,
   MySQLDataType.DOUBLE_PRECISION_UNSIGNED,
   MySQLDataType.DOUBLE_UNSIGNED,
   MySQLDataType.INTEGER_UNSIGNED,
   MySQLDataType.INT_UNSIGNED,
   MySQLDataType.SMALLINT_UNSIGNED,
   MySQLDataType.MEDIUMINT_UNSIGNED,
];

export interface MySQLColumnModify extends ColumnModify {
   comment?: string;
   extra?: string;
}

export interface MySQLIndexModify extends IndexModify {
   comment?: string;
   extra?: string;
}

export class MySQLQueryBuilder extends QueryBuilder {
   public wrapIdentifier(identifier: string): string {
      return `\`${identifier}\``;
   }

   public beginTransaction(): string {
      return 'START TRANSACTION;';
   }

   public isTypeNumeric(column: ColumnDefinition): boolean {
      return MySQLNumericColumns.includes(column.dataType as MySQLDataType);
   }

   public alterTable({
      columns,
      table,
      schema,
   }: {
      columns: Record<string, { definition?: ColumnDefinition; modify: MySQLColumnModify }>;
      schema?: string;
      table: string;
   }): string {
      const alterTable = `ALTER TABLE ${this.qualifyTable(table, schema)} \n`;

      const columnChanges = Object.entries(columns).map(([column, { modify, definition }]) => {
         const changeScripts: string[] = [];
         const dataType = modify.dataType ?? definition?.dataType;
         const defaultVal =
            modify.default || definition?.default
               ? ` DEFAULT ${modify.default ?? definition?.default}`
               : '';
         const nullable = modify.nullable ?? definition?.nullable ?? true ? '' : ' NOT NULL';
         const extra =
            modify.extra || definition?.extra ? ` ${modify.extra ?? definition?.extra}` : '';
         const comment =
            modify.comment || definition?.comment
               ? ` COMMENT '${modify.comment ?? definition?.comment}'`
               : '';

         if (modify.isNew) {
            changeScripts.push(
               `ADD COLUMN ${this.wrapIdentifier(
                  column
               )} ${dataType}${defaultVal}${nullable}${extra}${comment}`
            );
         } else if (modify.drop) {
            changeScripts.push(`DROP COLUMN ${this.wrapIdentifier(column)}`);
         } else {
            if (!definition) {
               throw new Error(`ColumnDefinition is required to edit columns`);
            }

            if (modify.rename) {
               changeScripts.push(
                  `CHANGE COLUMN ${this.wrapIdentifier(column)} ${this.wrapIdentifier(
                     modify.rename
                  )} ${dataType}${defaultVal}${nullable}${extra}${comment}`
               );
            } else {
               changeScripts.push(
                  `MODIFY COLUMN ${this.wrapIdentifier(
                     column
                  )} ${dataType}${defaultVal}${nullable}${extra}${comment}`
               );
            }
         }
         return changeScripts.join(',\n');
      });

      if (columnChanges.length > 0) {
         return `${alterTable} ${columnChanges.join(',\n')};`;
      }
      return '';
   }

   public alterRelation({
      catalog,
      relations,
      table,
      schema,
   }: {
      catalog?: string;
      relations: Record<string, { definition?: RelationDefinition; modify: RelationModify }>;
      schema?: string;
      table: string;
   }): string {
      const alterTable = `ALTER TABLE ${this.qualifyTable(table, schema)}\n`;
      const relationChanges = Object.entries(relations).map(([relation, { modify }]) => {
         const changeScripts: string[] = [];

         // If it's a new relation, create the foreign key constraint
         if (modify.isNew) {
            const foreignKeyColumns = modify.columns
               ?.map((col) => this.wrapIdentifier(col))
               .join(', ');
            const referencedColumns = modify.fkColumns
               ?.map((col) => this.wrapIdentifier(col))
               .join(', ');
            const fkTable = this.wrapIdentifier(modify.fkTable || '');

            changeScripts.push(
               `ADD CONSTRAINT ${this.wrapIdentifier(relation)} FOREIGN KEY (${foreignKeyColumns})
               REFERENCES ${fkTable} (${referencedColumns})
               ON UPDATE ${modify.onUpdate} ON DELETE ${modify.onDelete}`
            );
         }
         // If the relation should be dropped
         else if (modify.drop) {
            changeScripts.push(`DROP FOREIGN KEY ${this.wrapIdentifier(relation)}`);
         }
         return changeScripts;
      });

      if (relationChanges.length > 0) {
         return `${alterTable} ${relationChanges.join('\n')};`;
      }
      return '';
   }

   public explainQuery(query: string, analyze?: boolean): string {
      return `EXPLAIN ${analyze ? 'ANALYZE ' : ''} FORMAT=TREE ${query}`;
   }

   // -- Delete Table

   protected ifExists = 'IF EXISTS';

   public supportsDisablingForeignKeyChecks = true;

   protected disableForeignKeyChecks(statements: string[]): string[] {
      return ['SET FOREIGN_KEY_CHECKS = 0;', ...statements, 'SET FOREIGN_KEY_CHECKS = 1;'];
   }

   // -- Create Table

   protected increments(
      name: string = 'id',
      { nullable = true, primaryKey = true }: { nullable?: boolean; primaryKey?: boolean } = {}
   ) {
      return `${this.wrapIdentifier(name)} int unsigned${
         nullable || primaryKey ? ' NOT NULL' : ''
      } AUTO_INCREMENT${primaryKey ? ' PRIMARY KEY' : ''}`;
   }
}
