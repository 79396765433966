import { useInjection } from 'inversify-react';
import { useMutation, useQueryClient } from 'react-query';

import { ExploreTabService } from '../../services';
import { TYPES } from '../../types';
import { handleError } from '../../utilities';
import { fetchExploreTabQueryKey } from '../../hooks';

import type { ExploreTabPatch } from '../../entities';

type Variables = {
   id: number;
   payload: ExploreTabPatch;
};
export const useEditExploreTabMutation = () => {
   const queryClient = useQueryClient();
   const exploreTabService = useInjection<ExploreTabService>(TYPES.exploreTabService);
   return useMutation({
      mutationFn: ({ id, payload }: Variables) => {
         return exploreTabService.patch(id, payload);
      },

      async onSuccess(data, variables) {
         await queryClient.invalidateQueries(
            fetchExploreTabQueryKey({ type: 'invalidate', data: { exploreTabId: variables.id } })
         );
      },
      onError(error) {
         handleError(error);
      },
   });
};
