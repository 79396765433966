import { DataChatThreadState, DataChatMessageQuery, Person } from '.';
import { Base } from './Base';

export enum DataChatMessageRole {
   ANALYST = 'ANALYST',
   CREATOR = 'CREATOR',
   SYSTEM = 'SYSTEM',
}

export type DataChatMessageSuggestion = {
   content: string;
   nextState: DataChatThreadState;
};

type MessageMetadataSchemaSuccess = {
   data: {
      suggestedResponses: DataChatMessageSuggestion[];
      version: 1;
   };
   success: true;
};

type MessageMetadataSchemaError = {
   error: string;
   success: false;
};
type MessageMetadataSchema = MessageMetadataSchemaSuccess | MessageMetadataSchemaError;

export const MESSAGE_METADATA_SUCCESS_VERSION_CURRENT = 1;

export interface DataChatMessage extends Base {
   content?: string;
   dataChatThreadId: number;
   isInternal: boolean;
   messageQueries: DataChatMessageQuery[];
   metadata: MessageMetadataSchema | null;
   person?: Person;
   queryVersionId?: number;
   role: DataChatMessageRole;
   threadId: number;
}
