// Keys and values need to match PersonRole in API
// The order here determine the order displayed in the UI
export enum PersonRole {
   ORG_ADMIN = 'orgAdmin',
   ORG_BUSINESS_USER = 'orgBusinessUser',
   ORG_EXPLORER = 'orgExplorer',
}

export const ROLE_META = {
   [PersonRole.ORG_ADMIN]: { label: 'Org Admin', order: 1 },
   [PersonRole.ORG_EXPLORER]: { label: 'Data Explorer', order: 2 },
   [PersonRole.ORG_BUSINESS_USER]: { label: 'Business User', order: 3 },
};

export const ADMIN_ROLES: PersonRole[] = [PersonRole.ORG_ADMIN];
