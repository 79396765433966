import { SyntaxItem, QueryReturn } from '../interfaces';
import { Base } from './Base';
import {
   DataConnection,
   Person,
   QueryVersion,
   QueryVersionLog,
   StepType,
   Workspace,
   QueryStep,
   QuerySuggestion,
} from '.';

export enum QueryLogContext {
   EXPLORER = 1,
   SYSTEM = 2,
}

export interface QueryLog extends Base {
   context: QueryLogContext;
   dataConnection?: DataConnection;
   dataConnectionId?: number;
   exploreTabId?: number;
   orgId?: number;
   person?: Person;
   personId?: number;
   queryId?: number;
   querySyntaxTree?: SyntaxItem[];
   queryText?: string;
   queryVersionId?: number;
   runId?: number;
   runtime?: number;
   schemaName?: string | null;
   step?: number;
   workspace?: Workspace;
   workspaceId?: number;
}

export function logsFromQueryReturn({
   exploreTabId,
   queryVersion,
   results,
   steps,
}: {
   exploreTabId?: number;
   queryVersion: QueryVersion;
   results: QueryReturn[];
   steps: QueryStep[];
}): QueryLog[] {
   return results.map((result, i) => ({
      context: QueryLogContext.EXPLORER,
      dataConnectionId: steps[i].dataConnectionId,
      schemaName: steps[i].schemaName,
      queryId: queryVersion.queryId,
      queryText: steps[i].queryText,
      queryVersionId: queryVersion.id,
      runtime: result.runtime,
      step: steps[i].order,
      workspaceId: queryVersion.query?.workspaceId,
      exploreTabId,
   }));
}
export function groupLogs(queryLogs: QueryLog[], sort: boolean = true): QueryLog[][] {
   let runId: number | undefined = undefined;
   const groupedLogs = queryLogs.reduce((acc, log) => {
      if (log.runId === undefined || log.runId !== runId) {
         acc.push([log]);
         runId = log.runId;
      } else {
         acc[acc.length - 1].push(log);
      }
      return acc;
   }, [] as QueryLog[][]);

   groupedLogs.forEach((steps) =>
      steps.sort((a, b) => (a.step !== undefined && b.step !== undefined ? a.step - b.step : 0))
   );

   return sort
      ? groupedLogs.sort((a, b) =>
           a?.[0].created !== undefined && b?.[0].created !== undefined
              ? new Date(b[0].created).getTime() - new Date(a[0].created).getTime()
              : 0
        )
      : groupedLogs;
}

export function createQueryVersionFromLogs(logs: QueryLog[]): QueryVersionLog {
   return {
      created: logs[0].created,
      createdByPerson: logs[0].createdByPerson,
      createdByPersonId: logs[0].createdByPersonId,
      id: logs[0].id,
      isLog: true,
      modified: logs[0].modified,
      modifiedByPerson: logs[0].modifiedByPerson,
      modifiedByPersonId: logs[0].modifiedByPersonId,
      queryId: logs[0].queryId,
      queryVersionId: logs[0].queryVersionId!,
      runtime: logs.reduce((acc, log) => acc + (log.runtime ?? 0), 0),
      steps: logs.map((log, i) => ({
         dataConnectionId: log.dataConnectionId,
         type: StepType.DATA_CONNECTION,
         order: log.step ?? i,
         queryText: log.queryText ?? '',
         schemaName: log.schemaName,
      })),
   };
}

export interface LogSearchResponse {
   facetInformation: FacetInformation;
   items: QueryLog[];
   totalItems: number;
}

export interface QuerySuggestionResponse {
   facetInformation: FacetInformation;
   items: QuerySuggestion[];
   totalItems: number;
}
export interface LogItem {
   columnNames: string[];
   created: string;
   createdByPersonId: number;
   dataConnectionId: number;
   orgId: number;
   queryId: number;
   queryLogId: number;
   queryStepId: number;
   queryStepText: string;
   queryVersionId: number;
   runId: number;
   tableNames: string[];
   workspaceId: number;
}

export interface FacetInformation {
   createdByPersonIds: FacetItem[];
   dataConnectionIds: FacetItem[];
   schemaNames: FacetItem[];
   tableNames: FacetItem[];
}

export interface FacetItem {
   connectionName?: string;
   doc_count: number;
   email?: string;
   key: any;
}
