import { useCallback, useState } from 'react';
import { Nav, NavItem, NavLink, Stack, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

import { CollaboratorRole, ConnectionAccessType, ExploreTab } from '../../../../entities';
import { DBMS } from '../../../../enums';
import { useExplorerWorkspaceRole } from '../../../../hooks';
// import { DataEditTab } from './DataEditTab';
import { SchemaColumnTab } from './SchemaColumnTab';
import { SchemaRelationsTab } from './SchemaRelationsTab';
import { SchemaIndexTab } from './SchemaIndexTab';

export const schemaEditSupported = (dbms: DBMS | undefined): boolean => {
   switch (dbms) {
      case DBMS.MySQL:
      case DBMS.Snowflake:
         return true;
      case DBMS.MSSQL:
      case DBMS.Redshift:
         return true;
      case DBMS.Postgres:
         return true;
      case DBMS.Oracle:
         return true;
      case DBMS.Databricks:
         return true;
      default:
         return false;
   }
};

export const getReadOnly = (
   dbms: DBMS | undefined,
   connectionAccessType: ConnectionAccessType | undefined,
   role?: CollaboratorRole
): boolean => {
   if (role === CollaboratorRole.READER) {
      return true;
   }
   if (connectionAccessType === ConnectionAccessType.DEMO) {
      return true;
   }
   return !schemaEditSupported(dbms);
};
const getIndexReadOnly = (
   dbms: DBMS | undefined,
   connectionAccessType: ConnectionAccessType | undefined,
   role?: CollaboratorRole
): boolean => {
   if (
      role === CollaboratorRole.READER ||
      connectionAccessType === ConnectionAccessType.DEMO ||
      dbms === DBMS.Snowflake
   ) {
      return true;
   }
   return !schemaEditSupported(dbms);
};
export enum TableEditTab {
   COLUMNS = 'columns',
   // DATA = 'data',
   INDEXES = 'indexes',
   RELATIONS = 'relations',
}
const TableTabContent = ({
   exploreTab,
   onPendingUpdateChange,
   defaultTab = TableEditTab.COLUMNS,
}: {
   defaultTab?: TableEditTab;
   exploreTab: ExploreTab;
   onPendingUpdateChange?: (value: boolean) => void;
}): JSX.Element => {
   const [tabsPendingUpdate, _setTabsPendingUpdate] = useState<TableEditTab[]>([]);
   const [tabId, setTabId] = useState<string>(
      [TableEditTab.COLUMNS, TableEditTab.INDEXES, TableEditTab.RELATIONS].includes(defaultTab)
         ? defaultTab
         : TableEditTab.COLUMNS
   );
   const setPendingUpdate = useCallback(
      (tab: TableEditTab, update: boolean) => {
         _setTabsPendingUpdate((tabsPending) => {
            let tabs = tabsPending;
            let valueChanged = false;
            if (update && !tabsPending.includes(tab)) {
               tabs = [...tabsPending, tab];
               valueChanged = true;
            } else if (!update && tabsPending.includes(tab)) {
               tabs = tabsPending.filter((t) => t !== tab);
               valueChanged = true;
            }

            if (valueChanged) {
               //_setTabsPendingUpdate(tabs);
               if (tabs.length === 0) {
                  onPendingUpdateChange?.(false);
               } else {
                  onPendingUpdateChange?.(true);
               }
            }
            return tabs;
         });
      },
      [onPendingUpdateChange]
   );
   // const test = useCallback(
   //    (update: boolean) => {
   //       setPendingUpdate(TableEditTab.DATA, update);
   //    },
   //    [setPendingUpdate]
   // );

   const dbms = exploreTab.tableSchema?.dataConnection.dbms;
   const role = useExplorerWorkspaceRole(exploreTab.workspaceId);
   const readOnly = getReadOnly(
      exploreTab.tableSchema?.dataConnection.dbms,
      exploreTab.tableSchema?.dataConnection.connectionAccessType,
      role
   );
   const indexReadOnly = getIndexReadOnly(
      exploreTab.tableSchema?.dataConnection.dbms,
      exploreTab.tableSchema?.dataConnection.connectionAccessType,
      role
   );

   if (!exploreTab.workspaceId) return <Navigate to="/" />;
   if (!exploreTab.tableSchemaId) {
      return <Navigate to={`/workspaces/${exploreTab.workspaceId}`} />;
   }

   return (
      <TabContainer
         activeKey={tabId}
         onSelect={(eventKey) => setTabId(eventKey || TableEditTab.COLUMNS)}
         transition={false}
      >
         <Stack className="h-100 overflow-hidden">
            <header className="d-flex flex-column flex-shrink-0">
               <Nav
                  className="d-flex flex-row flex-nowrap align-items-center bg-secondary"
                  id="tableTabNav"
                  role="tablist"
               >
                  <div className="explore-tabs explore-tabs-scrollable">
                     {/* <NavItem
                           bsPrefix="explore-tab"
                           className={`top-explore-tabs ${tabId === 'data' ? 'active' : ''}`}
                           key="data"
                        >
                           <NavLink
                              className="position-relative override-active-pointer top-explore-tab-spacing"
                              eventKey={TableEditTab.DATA}
                              role="tab"
                           >
                              Data
                              {tabsPendingUpdate.includes(TableEditTab.DATA) && <span>*</span>}
                           </NavLink>
                        </NavItem> */}

                     {schemaEditSupported(exploreTab.tableSchema?.dataConnection.dbms) && (
                        <>
                           <NavItem
                              bsPrefix="explore-tab"
                              className={`top-explore-tabs ${tabId === 'columns' ? 'active' : ''}`}
                              key="columns"
                           >
                              <NavLink
                                 className="position-relative override-active-pointer top-explore-tab-spacing"
                                 eventKey={TableEditTab.COLUMNS}
                                 role="tab"
                              >
                                 Columns
                                 {tabsPendingUpdate.includes(TableEditTab.COLUMNS) && (
                                    <span>*</span>
                                 )}
                              </NavLink>
                           </NavItem>
                           {dbms !== DBMS.Redshift && dbms !== DBMS.Databricks && (
                              <NavItem
                                 bsPrefix="explore-tab"
                                 className={`top-explore-tabs ${
                                    tabId === 'indexes' ? 'active' : ''
                                 }`}
                                 key="indexes"
                              >
                                 <NavLink
                                    className="position-relative override-active-pointer top-explore-tab-spacing"
                                    eventKey={TableEditTab.INDEXES}
                                    role="tab"
                                 >
                                    Indexes
                                    {tabsPendingUpdate.includes(TableEditTab.INDEXES) && (
                                       <span>*</span>
                                    )}
                                 </NavLink>
                              </NavItem>
                           )}
                        </>
                     )}
                     {schemaEditSupported(exploreTab.tableSchema?.dataConnection.dbms) &&
                        exploreTab.tableSchema?.dataConnection.dbms !== DBMS.Snowflake && (
                           <NavItem
                              bsPrefix="explore-tab"
                              className={`top-explore-tabs ${
                                 tabId === 'relations' ? 'active' : ''
                              }`}
                              key="relations"
                           >
                              <NavLink
                                 className="position-relative override-active-pointer top-explore-tab-spacing"
                                 eventKey={TableEditTab.RELATIONS}
                                 role="tab"
                              >
                                 Relations
                                 {tabsPendingUpdate.includes(TableEditTab.RELATIONS) && (
                                    <span>*</span>
                                 )}
                              </NavLink>
                           </NavItem>
                        )}
                  </div>
               </Nav>
            </header>
            <TabContent className="flex-grow-1 overflow-hidden">
               {/* <TabPane className="h-100" eventKey="data">
                     <DataEditTab onStatusChange={test} readOnly={readOnly} />
                  </TabPane> */}

               <TabPane className="h-100" eventKey="columns">
                  <SchemaColumnTab
                     onStatusChange={(update) => setPendingUpdate(TableEditTab.COLUMNS, update)}
                     readOnly={readOnly}
                  />
               </TabPane>
               {dbms !== DBMS.Redshift && dbms !== DBMS.Databricks && (
                  <TabPane className="h-100" eventKey="indexes">
                     <SchemaIndexTab
                        onStatusChange={(update) => setPendingUpdate(TableEditTab.INDEXES, update)}
                        readOnly={indexReadOnly}
                     />
                  </TabPane>
               )}
               <TabPane className="h-100" eventKey="relations">
                  <SchemaRelationsTab
                     onStatusChange={(update) => setPendingUpdate(TableEditTab.RELATIONS, update)}
                     readOnly={readOnly}
                  />
               </TabPane>
            </TabContent>
         </Stack>
      </TabContainer>
   );
};

export default TableTabContent;
