import { QueryBuilder } from './QueryBuilder';

export class BigQueryQueryBuilder extends QueryBuilder {
   public beginTransaction(): string {
      return '';
   }

   public endTransaction(): string {
      return '';
   }

   public explainQuery(query: string, analyze?: boolean): string {
      throw new Error('Method not supported');
   }

   // -- Delete Table

   protected ifExists = 'IF EXISTS';

   // -- Create Table

   protected increments(
      name?: string,
      options?: { nullable?: boolean; primaryKey?: boolean }
   ): string {
      throw new Error('Method not supported');
   }

   public createTable({ schema, table }: { schema: string; table: string }) {
      return `CREATE TABLE ${this.qualifyTable(table, schema)} (
  id INT64
);`;
   }
}
