//DataBase Management System
export enum DBMS {
   Big_Query = 'Big Query',
   CHAT_GPT = 'Chat GPT',
   Databricks = 'Databricks',
   Federated = 'Federated',
   Hugging_Face = 'Hugging Face',
   MSSQL = 'MSSQL',
   MongoDB = 'MongoDB',
   MySQL = 'MySQL',
   Neo4j = 'Neo4j',
   Oracle = 'Oracle',
   Postgres = 'Postgres',
   Python = 'Python',
   Redshift = 'Redshift',
   Snowflake = 'Snowflake',
   Trino = 'Trino',
}

export const DBMS_TYPES = [
   DBMS.Big_Query,
   DBMS.MSSQL,
   DBMS.MySQL,
   DBMS.Neo4j,
   DBMS.Postgres,
   DBMS.Redshift,
   DBMS.Snowflake,
   DBMS.Databricks,
   DBMS.Oracle,
   DBMS.Trino,
   DBMS.Federated,
];

export const FEDERATED_DBMS = [
   DBMS.MySQL,
   DBMS.Postgres,
   DBMS.MSSQL,
   DBMS.Redshift,
   DBMS.Big_Query,
   DBMS.MongoDB,
   DBMS.Oracle,
   DBMS.Snowflake,
];

export const CATALOG_DBMS = [
   DBMS.MSSQL,
   DBMS.Big_Query,
   DBMS.Databricks,
   DBMS.Redshift,
   DBMS.Snowflake,
   DBMS.Trino,
   DBMS.Postgres,
];

export const dbmsKey = (value: string): string | undefined =>
   Object.entries(DBMS).find(([_, v]) => v === value)?.[0];

export const getDemoQuery = ({
   schema,
   dbms,
   table,
   limit = 100,
}: {
   dbms: DBMS;
   limit?: number;
   schema?: string;
   table: string;
}): string => {
   switch (dbms) {
      case DBMS.Big_Query:
         return `SELECT * FROM ${schema ? `${schema}.` : ``}${table} LIMIT ${limit};`;
      case DBMS.MSSQL:
         return `SELECT TOP ${limit} * FROM ${schema ? `[${schema}].` : ``}[${table}]`;
      case DBMS.Postgres:
      case DBMS.Redshift:
      case DBMS.Snowflake:
      case DBMS.Databricks:
         return `SELECT * FROM ${schema ? `"${schema}".` : ``}"${table}" LIMIT ${limit};`;
      case DBMS.MySQL:
      case DBMS.Trino:
      case DBMS.Federated:
         return `SELECT * FROM ${schema ? `\`${schema}\`.` : ``}\`${table}\` LIMIT ${limit};`;
      case DBMS.MongoDB:
         return `SELECT * FROM ${table} LIMIT ${limit};`;
      case DBMS.Oracle:
         return `SELECT * FROM ${schema ? `${schema}.` : ``}${table} WHERE ROWNUM <= ${limit};`;
      default:
         return '';
   }
};

export enum ColumnNull {
   NOT_NULL = 'NOT NULL',
   NULL = 'NULL',
}
