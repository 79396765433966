import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
   Button,
   Dropdown,
   DropdownButton,
   Modal,
   OverlayTrigger,
   Spinner,
   Stack,
   Tooltip,
} from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import { HiEllipsisHorizontal } from 'react-icons/hi2';
import { MdOutlinePlaylistAdd, MdOutlinePlaylistRemove, MdPlaylistPlay } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import {
   ImperativePanelHandle,
   Panel,
   PanelGroup,
   PanelResizeHandle,
} from 'react-resizable-panels';

import { DBMS_SCHEMA_SELECT_SUPPORTED, RunaMode, SERVICE_PLAN } from '@runql/util';

import { AiPulse, QueryHeader, QueryPanel, RunButton } from '../../../components';
import ManageWorkspaceConnections from '../../../components/ManageWorkspaceConnections';
import WorkspaceConnectionSelector from '../../../components/WorkspaceConnectionSelector';
import {
   CollaboratorRole,
   DataConnection,
   ExploreTab,
   nextQueryVersion,
   QueryStep,
   QueryVersion,
   QueryVersionPatch,
   SchemaOverride,
   StepType,
   useOpenQuery,
   WorkspaceStatus,
} from '../../../entities';
import { walkthroughStep } from '../../../entities/Walkthrough';
import { QueryKey } from '../../../enums';
import {
   QueryProvider,
   useCurrentStep,
   useOverrideSchema,
   useRunQuery,
   useSetUnsavedTab,
   useWorkspace,
   fetchExploreTabQueryKey,
} from '../../../hooks';
import { useAskRunaContext } from '../../../hooks/askHooks';
import {
   useEditExploreTabMutation,
   useExplorerWorkspaceRole,
   useListWorkspaceConnectionsQuery,
   useNewQueryMutator,
   useUpdateQueryMutator,
} from '../../../hooks/entities';
import { useQueryPanelContext } from '../../../hooks/QueryPanelContext';
import { useWalkthroughStep } from '../../../hooks/walkthrough';
import { UpgradeModal } from '../../../pages';
import { SeeOther } from '../../../services';
import { formatQuery, handleError, usePublish } from '../../../utilities';
import { QueryEditor, QueryEditorMethods } from './QueryEditor';

// Reference a consistent empty array to avoid unnecessary re-renders
const NO_STEPS: QueryStep[] = [];

const QueryPage = ({
   active,
   exploreTab,
   plan,
}: {
   active: boolean;
   exploreTab: ExploreTab;
   plan: SERVICE_PLAN;
}): JSX.Element => {
   // State Variables
   const [queryVersion, setQueryVersion] = useState<QueryVersion | undefined>(
      exploreTab?.queryVersion
   );

   const steps = queryVersion?.steps ?? NO_STEPS;
   const [focusedStep, setFocusedStep] = useState<QueryStep>();
   const [generateCommentsClicked, setGenerateCommentsClicked] = useState<boolean>(false);
   const [saving, setSaving] = useState(false);
   const [publishing, setPublishing] = useState(false);
   const [duplicateQuery, setDuplicateQuery] = useState<QueryVersion>();
   const [promptRemoveStep, setPromptRemoveStep] = useState<number>();
   const [showConnections, setShowConnections] = useState(false);

   const [isGeneratingDocumentation, setIsGeneratingDocumentation] = useState(false);
   const [isGeneratingExplanation, setIsGeneratingExplanation] = useState(false);
   const [actionsBarMsg, setActionsBarMsg] = useState<string | null>(null);
   const { expanded, setExpanded, collapsedSize, setCollapsedSize, setQueryTab } =
      useQueryPanelContext();
   const { askRuna } = useAskRunaContext();
   const panelRef = useRef<ImperativePanelHandle>(null);
   const queryPanelRef = useRef<any>(null);
   const debouncedSave = useRef<{
      patch: QueryVersionPatch;
      timeout: ReturnType<typeof setTimeout>;
   }>();
   const expectNewVersion = useRef<QueryVersion | undefined>(queryVersion);
   const isWorkflow = queryVersion && steps.length > 1;
   const openQuery = useOpenQuery();
   const currentEditorRef = useRef<QueryEditorMethods | undefined>();
   const editorRefs = useRef<QueryEditorMethods[]>([]);
   useEffect(() => {
      editorRefs.current = editorRefs.current.slice(0, steps.length);
   }, [steps.length]);
   const [isFormatting, setIsFormatting] = useState(false);
   const [showUpgradeModal, setShowUpgradeModal] = useState(false);
   const canGenerateDocumentation = plan?.canGenerateDocumentation ?? false;
   const setUnsavedTab = useSetUnsavedTab();
   const workspace = useWorkspace();

   const [stepFour, setStepFour] = useWalkthroughStep(walkthroughStep.FIRST_RUN_BUTTON);
   const [stepEight, setStepEight] = useWalkthroughStep(walkthroughStep.PYTHON_RUN_BUTTON);
   const [stepSix] = useWalkthroughStep(walkthroughStep.GENERATE_INLINE_COMMENTS);
   const [stepNine, setStepNine] = useWalkthroughStep(walkthroughStep.PYTHON_SAVE);
   const [prevRunConfig, setPrevRunConfig] = useState<{
      overrideSchema?: SchemaOverride;
      queryTextOverride?: string;
      queryVersion?: QueryVersion;
      step?: number;
      stopAfterStep?: number;
   }>({});
   const setSteps = () => {
      if (stepFour) {
         setStepFour();
      } else if (stepEight) {
         setStepEight();
      }
   };
   const runSourceEnabled = !!queryVersion?.query?.token;
   const bringExplanationIntoView = usePublish(`scrollToExplanation-${queryVersion?.id}`);

   // Data Services
   const queryClient = useQueryClient();
   const {
      modals: runModals,
      run,
      isRunning,
      results,
   } = useRunQuery(queryVersion, {
      exploreTabId: exploreTab.id,
   });
   const editTabMutator = useEditExploreTabMutation();
   // Queries
   const role = useExplorerWorkspaceRole(workspace.id);
   const readOnly = role === CollaboratorRole.READER;
   // Once a Query has been saved, `latestVersion.version` will never be `null`.
   const isInitialUnsavedQueryVersion = !queryVersion?.query?.latestVersion?.version;
   const dirty = !queryVersion?.version || !!debouncedSave.current || saving || publishing;

   const workspaceConnectionList = useListWorkspaceConnectionsQuery({
      workspaceId: workspace.id,
      includeConnectionDetails: true,
   });
   const firstDataConnectionId =
      steps[0]?.dataConnectionId ?? workspaceConnectionList.isLoading
         ? undefined
         : workspaceConnectionList?.data?.[0]?.dataConnectionId ?? null;

   const cancelDebouncedSave = useCallback(() => {
      if (debouncedSave.current) {
         clearTimeout(debouncedSave.current.timeout);
         debouncedSave.current = undefined;
      }
   }, []);

   const stepConnections: Record<number, DataConnection> = useMemo(() => {
      const connections: Record<number, DataConnection> = {};
      steps.forEach((step) => {
         if (step.dataConnection && step.dataConnection.id) {
            connections[step.dataConnection.id] = step.dataConnection;
         }
      });
      return connections;
   }, [steps]);

   const overrideSchema = useOverrideSchema();

   // Disable lint about formatAndRun causing the useEffect below to re-run every time,
   // since we're using other checks to ensure it's only called once.
   //
   // eslint-disable-next-line react-hooks/exhaustive-deps
   const formatAndRun = async (onlyStep?: number) => {
      setIsFormatting(true);
      if (onlyStep) {
         const singleStep = steps.find((step) => step.order === onlyStep);
         if (singleStep) {
            queryPanelRef.current.onRunSteps([singleStep]);
         }
      } else {
         queryPanelRef.current.onRunSteps(steps);
      }

      try {
         // Format and apply any pending saves
         const newQueryVersion = readOnly
            ? queryVersion
            : await saveDraft({ steps: formatSteps(steps ?? []) }, { throwOnError: true });

         if (
            readOnly &&
            queryVersion?.parameters &&
            !queryVersion?.parameters.every(
               (p, i) =>
                  p.name === exploreTab.parameters?.[i]?.name &&
                  p.exclude === exploreTab.parameters?.[i]?.exclude &&
                  p.defaultValue === exploreTab.parameters?.[i]?.defaultValue
            )
         ) {
            await editTabMutator.mutateAsync({
               id: exploreTab.id!,
               payload: { parameters: queryVersion?.parameters ?? [] },
            });
         }
         await run({
            overrideSchema,
            queryVersion: newQueryVersion,
            step: onlyStep,
         });
         setPrevRunConfig({
            overrideSchema,
            queryVersion: newQueryVersion,
            step: onlyStep,
         });
      } catch (err) {
         handleError(err);
      }
      setIsFormatting(false);
   };

   const [urlParams] = useSearchParams();
   const [autoRun, setAutoRun] = useState(!!urlParams.get('autoRun'));
   useEffect(() => {
      if (autoRun) {
         setAutoRun(false);
         formatAndRun();
      }
   }, [autoRun, formatAndRun]);

   const onRefreshQueryData = useCallback(async () => {
      await run(prevRunConfig);
   }, [prevRunConfig, run]);

   useHotkeys(
      ['Shift+Enter', 'Alt+Enter'],
      () => {
         formatAndRun();
      },
      { enabled: active },
      [formatAndRun]
   );

   const onRun = useCallback(
      async (queryTextOverride?: string, stepOrder?: number) => {
         await run({ queryVersion, queryTextOverride, step: stepOrder, overrideSchema });
         setPrevRunConfig({ queryVersion, queryTextOverride, step: stepOrder, overrideSchema });
         // Update result editing
         const singleStep = steps.find((step) => step.order === stepOrder);
         if (singleStep) {
            queryPanelRef.current.onRunSteps([singleStep]);
         }
      },
      [run, queryVersion, overrideSchema, steps]
   );

   const refresh = (count: number) => {
      queryClient.invalidateQueries([QueryKey.SavedQuery]);
      setTimeout(() => {
         count--;
         if (count > 0) {
            refresh(count);
         }
      }, 1000);
   };
   const createQueryMutation = useNewQueryMutator({
      async onSuccessCallback(data, _newQuerySave, _context) {
         setDuplicateQuery(undefined);
         refresh(3);

         if (!data?.id) return;
         expectNewVersion.current = data;
         await openQuery({
            exploreTabId: exploreTab.id,
            queryVersion: data,
            workspaceId: workspace.id,
            source: null, // don't track as query reuse
         });
         // setSaveType(undefined) is performed in the useEffect to avoid jank
      },
      onErrorCallback(error) {
         setSaving(false);
         setDuplicateQuery(undefined);
         if (error instanceof SeeOther) {
            setDuplicateQuery(error.details.queryVersion);
         } else {
            handleError(error);
         }
      },
   });

   const updateQueryMutation = useUpdateQueryMutator({
      async onSuccessCallback(data, update, _context) {
         refresh(3);
         setDuplicateQuery(undefined);
         if (!data?.id) return;
         if (update.publish) {
            await openQuery({
               exploreTabId: exploreTab.id,
               queryVersion: data,
               workspaceId: workspace.id,
               source: null, // don't track as query reuse
            });
         } else {
            // openQuery handles its own invalidation, so only do this in the else path.
            await queryClient.invalidateQueries(
               fetchExploreTabQueryKey({
                  type: 'invalidate',
                  data: { exploreTabId: exploreTab.id! },
               })
            );
         }
         setSaving(false);
      },
      onErrorCallback(error) {
         setSaving(false);
         setDuplicateQuery(undefined);
         if (error instanceof SeeOther) {
            const duplicate = error.details.queryVersion;
            if (duplicate?.id !== queryVersion?.id) {
               setDuplicateQuery(duplicate);
            }
         } else {
            handleError(error);
         }
      },
   });

   const formatSteps = useCallback((steps: QueryStep[]) => {
      const newSteps = steps.map((step) => ({
         ...step,
         queryText:
            step.queryText && step.type !== StepType.PYTHON
               ? formatQuery(step.queryText)
               : step.queryText,
      }));
      return newSteps;
   }, []);

   const save = useCallback(
      async ({
         debounce,
         patch,
      }: {
         debounce?: boolean;
         patch?: QueryVersionPatch;
      } = {}): Promise<QueryVersion | undefined> => {
         if (debounce && (patch?.publish || patch?.approve)) {
            debounce = false;
         }

         const newSteps = patch?.queryVersion.steps;
         if (newSteps) {
            setQueryVersion((queryVersion) => ({
               ...queryVersion,
               steps: newSteps,
            }));
         }
         if (
            patch?.queryVersion?.title ||
            patch?.queryVersion?.description ||
            patch?.queryVersion?.question
         ) {
            setQueryVersion((queryVersion) => ({
               ...queryVersion!,
               title: patch!.queryVersion.title ?? queryVersion?.title,
               description: patch!.queryVersion.description ?? queryVersion?.description,
               question: patch!.queryVersion.question ?? queryVersion?.question,
            }));
         }
         if (patch?.queryVersion?.explanation) {
            setQueryVersion((queryVersion) => ({
               ...queryVersion!,
               explanation: patch!.queryVersion.explanation ?? queryVersion?.explanation,
            }));
         }
         const newParams = patch?.queryVersion?.parameters;
         if (newParams) {
            setQueryVersion((queryVersion) => ({
               ...queryVersion!,
               parameters: newParams,
            }));
         }

         if (debouncedSave.current) {
            if (debouncedSave.current.patch) {
               patch = {
                  ...debouncedSave.current.patch,
                  ...patch,
                  queryVersion: {
                     ...debouncedSave.current.patch.queryVersion,
                     ...patch?.queryVersion,
                  },
               };
            }
            cancelDebouncedSave();
         }
         if (debounce && patch) {
            debouncedSave.current = {
               patch,
               timeout: setTimeout(async () => {
                  try {
                     await save();
                  } catch (e) {
                     // Errors are displayed by the mutation
                  }
               }, 5000),
            };
            return undefined;
         }
         let ret;
         setSaving(true);
         if (patch) {
            if (exploreTab.queryVersion?.version) {
               // We can't mutate a saved version, so we must create a new draft with the changes.
               const {
                  id: _1,
                  version: _2,
                  ...newVersion
               } = {
                  ...exploreTab.queryVersion,
                  ...patch.queryVersion,
                  parentId: exploreTab.queryVersion.id,
               };

               ret = await createQueryMutation.mutateAsync({
                  ...patch,
                  queryVersion: newVersion,
                  workspaceId: workspace.id,
               });
            } else {
               ret = await updateQueryMutation.mutateAsync(patch);
            }
         }

         debouncedSave.current = undefined;
         return ret;
      },
      [
         cancelDebouncedSave,
         createQueryMutation,
         updateQueryMutation,
         workspace.id,
         exploreTab?.queryVersion,
      ]
   );

   const share = useCallback(
      async (queryVersion: QueryVersion, share = true) => {
         return await updateQueryMutation.mutateAsync({
            queryVersion: {
               id: queryVersion.id!,
            },
            share,
         });
      },
      [updateQueryMutation]
   );

   const openAnotherQuery = useCallback(
      async (queryVersion: QueryVersion, newTab?: boolean) => {
         newTab ??= true;
         if (!queryVersion?.queryId) return;
         await save();
         openQuery({
            queryVersion: queryVersion,
            workspaceId: workspace.id,
            newTab,
            source: 'duplicate',
         });
      },
      [save, openQuery, workspace.id]
   );

   const saveDraft = useCallback(
      async (
         {
            steps: newSteps,
            description,
            explanation,
            question,
            title,
            parameters,
         }: Partial<
            Pick<
               QueryVersion,
               'steps' | 'title' | 'description' | 'explanation' | 'question' | 'parameters'
            >
         >,
         {
            debounce,
            throwOnError,
            generateComments,
            generateDocumentation,
            generateExplanation,
         }: {
            debounce?: boolean;
            generateComments?: boolean;
            generateDocumentation?: boolean;
            generateExplanation?: boolean;
            throwOnError?: boolean;
         } = {}
      ) => {
         if (readOnly) {
            if (parameters) {
               setQueryVersion((queryVersion) => ({
                  ...queryVersion!,
                  parameters,
               }));
            }
            return queryVersion;
         }

         if (!queryVersion?.id) {
            throw new Error('No query version to save');
         }
         const updateVersion: Partial<QueryVersion> & { id: number } = {
            id: queryVersion.id!,
         };

         let changed = false;
         if (newSteps) {
            updateVersion.steps = newSteps;
            changed = true;
         }

         if (title !== undefined && title !== queryVersion.title) {
            updateVersion.title = title;
            updateVersion.generatedTitle = false;
            changed = true;
         }
         if (
            description !== undefined &&
            description !== queryVersion.description &&
            (description !== '' || queryVersion.description !== null)
         ) {
            updateVersion.description = description;
            updateVersion.generatedDescription = false;
            changed = true;
         }
         if (
            question !== undefined &&
            question !== queryVersion.question &&
            (question !== '' || queryVersion.question !== null)
         ) {
            updateVersion.question = question;
            updateVersion.generatedQuestion = false;
            changed = true;
         }
         if (
            explanation !== undefined &&
            explanation !== queryVersion.explanation &&
            (explanation !== '' || queryVersion.explanation !== null)
         ) {
            updateVersion.explanation = explanation;
            updateVersion.generatedExplanation = false;
            changed = true;
         }

         if (parameters) {
            updateVersion.parameters = parameters;
            changed = true;
         }

         if (
            !changed &&
            !generateComments &&
            !generateDocumentation &&
            !generateExplanation &&
            !debouncedSave.current
         ) {
            return queryVersion;
         }
         setDuplicateQuery(undefined);
         try {
            const result = await save({
               debounce,
               patch: {
                  queryVersion: updateVersion,
                  generateComments,
                  generateDocumentation,
                  generateExplanation,
               },
            });

            return result;
         } catch (e) {
            if (throwOnError) {
               throw e;
            }
         }
      },
      [readOnly, queryVersion, save]
   );

   const forkQuery = useCallback(
      async (queryVersion: QueryVersion) => {
         await saveDraft({}, { throwOnError: true });
         await openQuery({
            queryVersion: {
               ...queryVersion,
               title: '',
               description: '',
               question: '',
               explanation: '',
            },
            workspaceId: workspace.id,
            fork: true,
            newTab: true,
            source: 'fork',
         });
      },
      [openQuery, workspace.id, saveDraft]
   );

   const handleClickRevert = useCallback(async () => {
      cancelDebouncedSave();
      if (exploreTab.queryVersion?.version) {
         // If the current version is a saved version, we reset to the original.
         setQueryVersion(exploreTab.queryVersion);
      } else if (queryVersion?.parent?.version) {
         // If the current version is a draft, go back to the previous, saved
         // version.
         setSaving(true);
         await openQuery({
            queryVersion: queryVersion.parent,
            workspaceId: workspace.id,
            source: null, // don't track as query reuse
         });
         setSaving(false);
      }
   }, [
      cancelDebouncedSave,
      exploreTab.queryVersion,
      queryVersion?.parent,
      openQuery,
      workspace.id,
   ]);

   const handleClickGenerateInlineComments = useCallback(async () => {
      if (!queryVersion?.id) {
         return;
      }

      askRuna({
         clearConversation: true,
         mode: RunaMode.InlineComments,
         steps: steps.map((step) => ({
            defaultSchema: step.schemaName ?? '',
            dataConnectionId: step.dataConnectionId,
            query: step.queryText,
         })),
      });
      setQueryTab('runa');
   }, [queryVersion?.id, askRuna, steps, setQueryTab]);

   const handleClickGenerateDocumentation = useCallback(async () => {
      if (!queryVersion?.id) {
         return;
      }

      setActionsBarMsg('Generating documentation');
      setIsGeneratingDocumentation(true);

      await saveDraft({ steps: formatSteps(steps ?? []) }, { generateDocumentation: true });

      setIsGeneratingDocumentation(false);
      setActionsBarMsg(null);
      setIsGeneratingDocumentation(false);
   }, [formatSteps, queryVersion?.id, saveDraft, steps]);

   const handleClickGenerateExplanation = useCallback(async () => {
      if (!queryVersion?.id) {
         return;
      }

      setActionsBarMsg('Generating analyst description');
      setIsGeneratingExplanation(true);

      await saveDraft({ steps: formatSteps(steps ?? []) }, { generateExplanation: true });

      setIsGeneratingExplanation(false);
      bringExplanationIntoView('');
      setActionsBarMsg(null);
      setIsGeneratingExplanation(false);
   }, [formatSteps, queryVersion?.id, saveDraft, steps, bringExplanationIntoView]);

   const handleSetExplanation = useCallback(
      async (explanation: string) => {
         if (!queryVersion?.id) {
            return;
         }
         await saveDraft({ explanation });
      },
      [saveDraft, queryVersion?.id]
   );

   const handleRunaSetExplanation = useCallback(
      async (explanation: string) => {
         if (!queryVersion?.id) {
            return;
         }
         await saveDraft({ explanation });
         setQueryTab('docs');
         bringExplanationIntoView('');
      },
      [saveDraft, setQueryTab, bringExplanationIntoView, queryVersion?.id]
   );

   const handleClickOptimize = useCallback(async () => {
      askRuna({
         clearConversation: true,
         mode: RunaMode.Optimize,
         steps: steps.map((step) => ({
            defaultSchema: step.schemaName ?? '',
            dataConnectionId: step.dataConnectionId,
            query: step.queryText,
         })),
         dataConnections: stepConnections,
         workspaceId: workspace.id,
      });
      setQueryTab('runa');
   }, [askRuna, setQueryTab, stepConnections, steps, workspace.id]);
   const [hasShownConnections, setHasShowConnections] = useState(false);

   const publish = useCallback(async () => {
      if (!queryVersion?.id) {
         return;
      }
      setUnsavedTab(exploreTab?.id, false);

      setPublishing(true);

      const generateDocumentation =
         isInitialUnsavedQueryVersion &&
         (!queryVersion.title?.trim() ||
            !queryVersion.description?.trim() ||
            !queryVersion.question?.trim());

      if (generateDocumentation) {
         setActionsBarMsg(
            isInitialUnsavedQueryVersion
               ? 'Generating initial documentation'
               : 'Generating documentation'
         );
         setIsGeneratingDocumentation(true);
      }

      let queryUpdate: QueryVersionPatch = {
         queryVersion: {
            id: queryVersion.id,
            steps: formatSteps(steps ?? []),
         },
         publish: true,
         generateDocumentation,
      };

      try {
         await save({ patch: queryUpdate });
      } catch (e) {
         // handled by the mutation
      }

      setIsGeneratingDocumentation(false);
      setActionsBarMsg(null);
      setPublishing(false);
   }, [
      queryVersion?.id,
      queryVersion?.title,
      queryVersion?.description,
      queryVersion?.question,
      setUnsavedTab,
      exploreTab?.id,
      isInitialUnsavedQueryVersion,
      formatSteps,
      steps,
      save,
   ]);
   useHotkeys(`Alt+p`, () => publish(), { enabled: active }, [publish]);

   const [, _setCurrentStep] = useCurrentStep();
   const setCurrentStep = useCallback(
      ({ queryStep, selection }: { queryStep?: QueryStep; selection?: string }) => {
         queryStep ??= focusedStep;
         if (!queryVersion || !queryStep || !currentEditorRef.current) return;
         _setCurrentStep?.({
            insert: currentEditorRef.current?.insertSnippet,
            queryStep,
            queryVersion,
            replaceAll: (steps: QueryStep[]) => {
               saveDraft({
                  steps,
               });
            },
            selection,
         });
      },
      [focusedStep, _setCurrentStep, queryVersion, saveDraft]
   );
   const lastQueryVersion = useRef<QueryVersion | undefined>(queryVersion);
   useEffect(
      () => {
         cancelDebouncedSave();
         // Sync changes from server.
         setQueryVersion(exploreTab.queryVersion);
         setSaving(false);
         if (
            exploreTab.queryVersion &&
            expectNewVersion.current?.id !== exploreTab.queryVersion.id &&
            exploreTab.queryVersion.id !== lastQueryVersion.current?.id &&
            !exploreTab.queryVersion.version
         ) {
            setCurrentStep({ queryStep: exploreTab.queryVersion.steps?.[0] });
         }
         setDuplicateQuery(undefined);
         lastQueryVersion.current = exploreTab.queryVersion;
      },
      // Intentionally only update the queryVersion on specific changes.
      //
      // eslint-disable-next-line
      [
         // the tab is switched to a new version
         exploreTab.queryVersion?.id,
         // the version is published
         exploreTab.queryVersion?.version,
         // the version is approved
         exploreTab.queryVersion?.query?.approvedVersionId,
         // the query is shared
         exploreTab.queryVersion?.query?.state,
         // any update to documentation tab
         exploreTab.queryVersion?.title,
         exploreTab.queryVersion?.description,
         exploreTab.queryVersion?.question,
         exploreTab.queryVersion?.explanation,
         // The runsource token is generated
         exploreTab.queryVersion?.query?.token,
      ]
   );

   const hasQuery = !!steps?.[0]?.queryText;

   const moreActions: Array<{
      className?: string;
      label: string;
      onClick: React.MouseEventHandler<HTMLButtonElement>;
   }> = [
      // Generate Inline Comments
      ...(!readOnly && canGenerateDocumentation
         ? [
              {
                 className:
                    workspace.status === WorkspaceStatus.PERSONAL_DEMO && stepSix
                       ? 'rainbowAnimation'
                       : '',
                 label: 'Generate Inline Comments',
                 onClick: handleClickGenerateInlineComments,
              },
           ]
         : []),
      // Duplicate
      ...(!readOnly && !isInitialUnsavedQueryVersion
         ? [{ label: 'Duplicate', onClick: () => forkQuery(queryVersion) }]
         : []),
      // Revert
      ...(!readOnly ? [{ label: 'Revert', onClick: handleClickRevert }] : []),
      // Generate Optimization
      ...(!readOnly ? [{ label: 'Ask Runa to Optimize', onClick: handleClickOptimize }] : []),
   ];

   const actionButtons = [
      // Save
      ...(!readOnly
         ? [
              <AiPulse
                 key="save"
                 on={workspace.status === WorkspaceStatus.PERSONAL_DEMO && stepNine}
                 onClick={() => {
                    if (workspace.status === WorkspaceStatus.PERSONAL_DEMO && stepNine) {
                       setStepNine();
                    }
                 }}
              >
                 <Button
                    disabled={!dirty || publishing || !hasQuery}
                    onClick={publish}
                    size="sm"
                    style={{ minWidth: '70px' }}
                    variant="secondary"
                 >
                    {publishing ? (
                       <>
                          &nbsp;
                          <Spinner
                             animation="border"
                             aria-hidden="true"
                             as="span"
                             role="status"
                             size="sm"
                          />
                          &nbsp;
                       </>
                    ) : (
                       `Save ${nextQueryVersion(queryVersion)}`
                    )}
                 </Button>
              </AiPulse>,
           ]
         : []),
      // More...
      ...(moreActions.length > 0
         ? [
              <AiPulse
                 key="more"
                 on={
                    workspace.status === WorkspaceStatus.PERSONAL_DEMO &&
                    stepSix &&
                    !generateCommentsClicked
                 }
                 onClick={() => {
                    if (!generateCommentsClicked) {
                       setGenerateCommentsClicked(true);
                    }
                 }}
                 sparkleAfter
              >
                 <DropdownButton size="sm" title={<HiEllipsisHorizontal />} variant="secondary">
                    {moreActions.map((action) => (
                       <Dropdown.Item
                          as="button"
                          className={action.className}
                          key={action.label}
                          onClick={action.onClick}
                       >
                          {action.label}
                       </Dropdown.Item>
                    ))}
                 </DropdownButton>
              </AiPulse>,
           ]
         : []),
      // Run
      ...(steps.every(
         (step) => step.dataConnectionId !== undefined || step.type !== StepType.DATA_CONNECTION
      )
         ? [
              <AiPulse
                 key="run"
                 on={workspace.status === WorkspaceStatus.PERSONAL_DEMO && (stepFour || stepEight)}
                 onClick={() => workspace.status === WorkspaceStatus.PERSONAL_DEMO && setSteps()}
                 sparkleAfter
              >
                 <RunButton
                    disabled={!hasQuery}
                    key="run"
                    onClick={() => formatAndRun()}
                    running={isFormatting || isRunning}
                 >
                    Run
                 </RunButton>
              </AiPulse>,
           ]
         : [
              <OverlayTrigger
                 key="select-db"
                 overlay={<Tooltip>Please select a database first</Tooltip>}
              >
                 <div>
                    <RunButton disabled={true} />
                 </div>
              </OverlayTrigger>,
           ]),
   ];

   const addAnotherStep = () => {
      if (!queryVersion || steps.length === 0) {
         return;
      }

      const previousStep = steps[steps.length - 1];

      saveDraft({
         steps: [
            ...steps,
            {
               type: previousStep.type,
               order: steps.length + 1,
               queryText: '',
               dataConnectionId:
                  previousStep.type === StepType.DATA_CONNECTION
                     ? previousStep.dataConnectionId
                     : undefined,
               schemaName:
                  previousStep.type === StepType.DATA_CONNECTION
                     ? previousStep.schemaName ?? null
                     : undefined,
            },
         ],
      });
   };

   useEffect(() => {
      // loading?
      if (firstDataConnectionId === undefined) return;

      // no data connections in workspace?
      if (firstDataConnectionId === null) {
         if (!hasShownConnections) setShowConnections(true);
         return;
      }

      // This may no longer be necessary since we add an empty first step when an ExploreTab is
      // created.
      if (queryVersion?.steps.length === 0) {
         const newStep = {
            order: 1,
            queryText: '',
            dataConnectionId: firstDataConnectionId,
            type: StepType.DATA_CONNECTION,
         };
         setQueryVersion((queryVersion) => ({
            ...queryVersion,
            steps: [newStep],
         }));
         setCurrentStep({ queryStep: newStep });
      } else if (
         queryVersion?.steps.some(
            (step) => step.type === StepType.DATA_CONNECTION && !step.dataConnectionId
         )
      ) {
         setQueryVersion((queryVersion) => ({
            ...queryVersion,
            steps: (queryVersion?.steps ?? []).map((step) => ({
               ...step,
               dataConnectionId: step.dataConnectionId ?? firstDataConnectionId,
            })),
         }));
      }
   }, [queryVersion, firstDataConnectionId, setCurrentStep, hasShownConnections]);

   const removeStep = (index: number) => {
      saveDraft({
         steps: steps.slice(0, index).concat(steps.slice(index + 1)),
      });
      setPromptRemoveStep(undefined);
   };

   const addStepOverlay = (
      <Tooltip id="workflowStepHelpInfo">
         Add workflow step
         <br />
         Chain queries across different databases or add python
      </Tooltip>
   );

   const editorControls = (step: QueryStep, index: number) => (
      <Stack className="d-flex align-items-start" direction="horizontal" gap={2}>
         {!readOnly && (
            <Stack className="d-flex align-items-start" direction="horizontal" gap={2}>
               {isWorkflow && (
                  <OverlayTrigger
                     delay={{ show: 500, hide: 0 }}
                     overlay={<Tooltip id="removeWorkflowStepInfo">Delete this step</Tooltip>}
                     placement="top"
                  >
                     <a
                        className="versionHistory"
                        href="#addWorkflowStep"
                        onClick={(e) => {
                           e.preventDefault();
                           setPromptRemoveStep(index);
                        }}
                     >
                        <MdOutlinePlaylistRemove size={18} />
                     </a>
                  </OverlayTrigger>
               )}
               {index === steps.length - 1 && steps[steps.length - 1].queryText && (
                  <OverlayTrigger
                     delay={{ show: 500, hide: 0 }}
                     overlay={addStepOverlay}
                     placement="top"
                  >
                     <a className="versionHistory" href="#addWorkflowStep" onClick={addAnotherStep}>
                        <MdOutlinePlaylistAdd size={18} />
                     </a>
                  </OverlayTrigger>
               )}
               {isWorkflow && (
                  <OverlayTrigger
                     delay={{ show: 500, hide: 0 }}
                     overlay={<Tooltip>Run this step only</Tooltip>}
                     placement="top"
                  >
                     <a
                        className="versionHistory"
                        href="#runStep"
                        onClick={(e) => {
                           e.preventDefault();
                           formatAndRun(index + 1);
                        }}
                     >
                        <MdPlaylistPlay size={18} />
                     </a>
                  </OverlayTrigger>
               )}
            </Stack>
         )}
      </Stack>
   );
   const editorStep = (step: QueryStep) =>
      readOnly
         ? {
              ...step,
              queryText: step.queryText
                 ?.split('\n')
                 .map((line, index) => {
                    const comment = step.comments?.find((c) => c.line === index)?.comment;
                    if (comment) {
                       return `${line} -- ${comment}`;
                    }
                    return line;
                 })
                 .join('\n'),
           }
         : step;
   const [stepTwo, setStepTwo] = useWalkthroughStep(walkthroughStep.IDE_CELL);

   const onStepChange = useCallback(
      (step: QueryStep) => {
         // Update unsaved
         setUnsavedTab(exploreTab?.id, true);
         if (workspace.status === WorkspaceStatus.PERSONAL_DEMO && stepTwo) {
            setStepTwo();
         }

         const newSteps = steps.slice() ?? [];
         newSteps.splice(step.order - 1, 1, step);
         saveDraft(
            {
               steps: newSteps,
            },
            {
               debounce: true,
            }
         );
         if (queryVersion?.queryId) {
            setCurrentStep({ queryStep: step });
         }
      },
      [
         setUnsavedTab,
         exploreTab?.id,
         workspace.status,
         stepTwo,
         steps,
         saveDraft,
         queryVersion?.queryId,
         setStepTwo,
         setCurrentStep,
      ]
   );

   const handlePanelResize = (size: number, prevSize?: number) => {
      // Check for prevSize === undefined: Edge case when expanding dialog and then reloading page. prevSize is undefined on first render.
      // If dragged down to less than half height, button will automatically change to Expand.
      if (size < 100 && (prevSize === undefined || size < prevSize) && expanded) {
         setExpanded(false);
         // If expanded up to more than half height, button will automatically change to Collapse.
      } else if (size >= 100 && (prevSize === undefined || size > prevSize) && !expanded) {
         setExpanded(true);
      }
   };

   const collapsePanel = () => {
      const panel = panelRef.current;
      if (panel) {
         panel.resize(collapsedSize > 80 ? 80 : collapsedSize);
      }
   };

   const expandPanel = () => {
      const panel = panelRef.current;
      if (panel) {
         setCollapsedSize(panel.getSize());
         panel.resize(100);
      }
   };

   function handleConnectionChange(
      step: QueryStep,
      dataConnection: DataConnection | undefined,
      schemaName: string | null | undefined,
      type: StepType
   ): void {
      if (type === StepType.DATA_CONNECTION) {
         if (dataConnection === undefined) {
            return;
         }

         if (dataConnection.dbms && !DBMS_SCHEMA_SELECT_SUPPORTED.includes(dataConnection.dbms)) {
            schemaName = null;
         }

         localStorage.setItem(
            `lastSelectedDataConnectionData-${workspace.id}`,
            JSON.stringify({ dataConnectionId: dataConnection.id, schemaName })
         );
      }

      onStepChange({
         ...step,
         dataConnection: dataConnection,
         dataConnectionId: dataConnection?.id,
         type,
         schemaName,
      });
   }

   return (
      <QueryProvider onChange={saveDraft} queryVersion={queryVersion}>
         <>
            <PanelGroup autoSaveId="query" direction="vertical">
               <Panel defaultSize={70} id="main" minSize={0} order={1}>
                  <Stack className="h-100 query-page-background overflow-auto" gap={4}>
                     <AiPulse
                        on={workspace.status === WorkspaceStatus.PERSONAL_DEMO && stepTwo}
                        onClick={() => {}}
                        sparkleAfter
                     >
                        {steps.map((step, index) => (
                           <div key={index}>
                              <div className="d-flex justify-content-end px-2 pt-1" key={index}>
                                 <WorkspaceConnectionSelector
                                    dataConnectionId={step.dataConnectionId}
                                    onChange={(dataConnection, schemaName, type) =>
                                       handleConnectionChange(
                                          step,
                                          dataConnection,
                                          schemaName,
                                          type
                                       )
                                    }
                                    onlyShared={runSourceEnabled}
                                    readOnly={readOnly}
                                    schemaName={step.schemaName}
                                    type={step.type ?? StepType.DATA_CONNECTION}
                                    workspaceId={workspace.id}
                                 />
                              </div>
                              <QueryEditor
                                 dataConnections={
                                    workspaceConnectionList.data?.map((c) => c.dataConnection!) ??
                                    []
                                 }
                                 loading={false}
                                 onChange={onStepChange}
                                 onFocus={() => {
                                    if (!queryVersion) return;
                                    currentEditorRef.current = editorRefs.current[index];
                                    setFocusedStep(step);
                                    setCurrentStep?.({
                                       queryStep: step,
                                    });
                                 }}
                                 onRun={(queryTextOverride?: string) =>
                                    onRun(queryTextOverride, step.order)
                                 }
                                 onSelection={(selection) => {
                                    if (!queryVersion) return;
                                    setCurrentStep?.({
                                       selection,
                                    });
                                 }}
                                 readOnly={readOnly || publishing}
                                 ref={(el: QueryEditorMethods) => {
                                    editorRefs.current[index] = el;
                                 }}
                                 step={editorStep(step)}
                              />
                              <div className="d-flex justify-content-end px-2 pb-1">
                                 <div>{editorControls(step, index)}</div>
                              </div>
                           </div>
                        ))}
                     </AiPulse>
                  </Stack>
               </Panel>
               <Stack direction="vertical" gap={2} style={{ flex: 'none' }}>
                  {duplicateQuery && (
                     <div
                        style={{
                           display: 'flex',
                           flexDirection: 'row',
                           justifyContent: 'space-between',
                           minWidth: '100%',
                           gap: '0.25rem',
                           padding: '0 1rem',
                        }}
                     >
                        <div style={{ alignSelf: 'center', color: 'darkgoldenrod' }}>
                           A saved query matching this one already exists.{' '}
                        </div>
                        <Button
                           onClick={() => {
                              openAnotherQuery(duplicateQuery);
                           }}
                           size="sm"
                        >
                           Open Existing Query
                        </Button>
                     </div>
                  )}
                  <Stack
                     className="px-3 py-2 query-page-button-bar border-top-line justify-content-between"
                     direction="horizontal"
                     gap={2}
                  >
                     <Stack className="align-items-center" direction="horizontal">
                        {!isInitialUnsavedQueryVersion && queryVersion && (
                           <QueryHeader
                              queryVersion={queryVersion}
                              setShared={readOnly ? undefined : share}
                              showDetails={false}
                              showTags={false}
                           />
                        )}
                     </Stack>
                     <div>
                        {actionsBarMsg ? (
                           <span className="fs-11p text-muted">{actionsBarMsg}...</span>
                        ) : null}
                     </div>
                     <Stack direction="horizontal" gap={1}>
                        {actionButtons}
                     </Stack>
                  </Stack>
               </Stack>
               {queryVersion && (
                  <>
                     <PanelResizeHandle className="panelHandle horizontal">
                        <div className="panelHandleLine" />
                     </PanelResizeHandle>
                     <Panel
                        defaultSize={30}
                        id="bottom"
                        onResize={handlePanelResize}
                        order={2}
                        ref={panelRef}
                     >
                        <QueryPanel
                           allowDocGeneration={canGenerateDocumentation}
                           collapsePanel={collapsePanel}
                           expandPanel={expandPanel}
                           hideTabs={{
                              admin: readOnly,
                              comments: true,
                              requests: workspace.status !== WorkspaceStatus.OPEN,
                           }}
                           isGeneratingDocumentation={isGeneratingDocumentation}
                           isGeneratingExplanation={isGeneratingExplanation}
                           onChangeDocumentation={(params) => saveDraft(params, { debounce: true })}
                           onClickGenerateDocumentation={handleClickGenerateDocumentation}
                           onClickGenerateExplanation={handleClickGenerateExplanation}
                           onRefreshQueryData={onRefreshQueryData}
                           onRunaSetExplanation={handleRunaSetExplanation}
                           onSetExplanation={handleSetExplanation}
                           queryResults={isRunning ? [] : results}
                           queryVersion={queryVersion}
                           readOnly={readOnly}
                           ref={queryPanelRef}
                           setShowUpgradeModal={() => setShowUpgradeModal(true)}
                        />
                     </Panel>
                  </>
               )}
            </PanelGroup>
            {runModals}
            <Modal
               onHide={() => setPromptRemoveStep(undefined)}
               show={promptRemoveStep !== undefined}
            >
               <Modal.Header className="border-0 mb-0 pb-0" closeButton>
                  <Modal.Title className="fs-14p">Delete Step</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <span>Are you sure you wish to delete this step?</span>
                  <div className="d-flex justify-content-end mt-2">
                     <Button
                        onClick={() => setPromptRemoveStep(undefined)}
                        size="sm"
                        variant="secondary"
                     >
                        Cancel
                     </Button>
                     <Button
                        className="ms-2"
                        onClick={() =>
                           promptRemoveStep !== undefined && removeStep(promptRemoveStep)
                        }
                        size="sm"
                        type="button"
                     >
                        Delete
                     </Button>
                  </div>
               </Modal.Body>
            </Modal>
            <ManageWorkspaceConnections
               onClose={() => {
                  setHasShowConnections(true);
                  setShowConnections(false);
               }}
               show={showConnections}
               workspaceId={workspace.id}
            />

            {showUpgradeModal && (
               <UpgradeModal onClose={() => setShowUpgradeModal(false)} show={showUpgradeModal} />
            )}
         </>
      </QueryProvider>
   );
};
export default QueryPage;
