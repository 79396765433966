import { useContext, createContext } from 'react';
import { useInjection } from 'inversify-react';
import { useQuery } from 'react-query';

import { LoadingSpinner } from '../components';
import { ExploreTab } from '../entities';
import { ExploreTabService } from '../services';
import { TYPES } from '../types';

export type ExploreTabContext = {
   exploreTab: ExploreTab;
};

const Ctx = createContext<ExploreTabContext | undefined>(undefined);

export const fetchExploreTabQueryKey = ({
   type,
   data,
}: {
   data: { exploreTabId: number };
   type: 'fetch' | 'invalidate';
}) => ['exploreTabs', data.exploreTabId];

export const ExploreTabProvider = ({
   exploreTabId,
   children,
}: React.PropsWithChildren<{ exploreTabId: number }>) => {
   const exploreTabService = useInjection<ExploreTabService>(TYPES.exploreTabService);
   const exploreTabQueryResult = useQuery<ExploreTab>(
      fetchExploreTabQueryKey({ type: 'fetch', data: { exploreTabId } }),
      async () => {
         const result = await exploreTabService.get(exploreTabId);

         if (!result) {
            throw new Error('Error fetching ExploreTab');
         }

         return result;
      },
      {
         refetchOnWindowFocus: false,
         refetchOnMount: 'always',
         retry: false,
      }
   );

   const exploreTab = exploreTabQueryResult.data;

   if (!exploreTab) {
      return <LoadingSpinner />;
   }

   return <Ctx.Provider value={{ exploreTab }}>{children}</Ctx.Provider>;
};

export const useExploreTab = () => {
   const context = useContext(Ctx);
   if (context === undefined) {
      throw new Error('useExploreTab must be used within an ExploreTabProvider');
   }
   return context.exploreTab;
};

export const useOptionalExploreTab = () => {
   const context = useContext(Ctx);
   return context?.exploreTab;
};
