export function queryIsDangerous(query: string) {
   // Define a regular expression to remove both single-line and multi-line comments from the SQL block
   const sqlWithoutComments = query
      // Remove all single-line comments starting with '--'
      .replace(/--.*?(\r?\n|$)/g, ' ')
      // Remove all multi-line comments between '/* */'
      .replace(/\/\*[\s\S]*?\*\//g, ' ');

   // Convert the cleaned SQL block to lowercase for easier case-insensitive matching
   const lowerCasedSql = sqlWithoutComments.toLowerCase();

   // List of SQL keywords considered as data-changing operations
   const dataChangingKeywords = [
      'insert',
      'update',
      'delete',
      'merge',
      'replace',
      'create',
      'alter',
      'drop',
      'truncate',
   ];

   // Construct a single regular expression that checks for each keyword bounded by word boundaries
   const regexPattern = new RegExp('\\b(' + dataChangingKeywords.join('|') + ')\\b', 'g');

   // Use the regular expression to test if any matches are found in the comment-free SQL block
   return regexPattern.test(lowerCasedSql);
}
