import {
   BiDotsVerticalRounded,
   BiEditAlt,
   BiEdit,
   BiFilter,
   BiInfoCircle,
   BiSave,
   BiSearch,
   BiX,
   BiSolidRightArrow,
   BiSolidDownArrow,
   BiTrash,
   BiSolidEdit,
   BiSend,
} from 'react-icons/bi';
import { BsHeartFill, BsPin, BsPinFill, BsChatRightDots, BsSave } from 'react-icons/bs';
import { GoPlus, GoGraph } from 'react-icons/go';
import {
   MdOutlineSave,
   MdSettings,
   MdCable,
   MdOutlineArrowBack,
   MdOutlineArrowForward,
   MdOutlineSettings,
   MdKey,
} from 'react-icons/md';
import { RiAlertFill } from 'react-icons/ri';
import { CiViewTable } from 'react-icons/ci';
import {
   VscHome,
   VscCodeOss,
   VscGitPullRequestGoToChanges,
   VscCoverage,
   VscGitStash,
   VscQuestion,
   VscHistory,
   VscSymbolSnippet,
   VscArrowCircleLeft,
   VscServerProcess,
   VscGithubAction,
   VscLayoutMenubar,
} from 'react-icons/vsc';
import {
   FaUsers,
   FaUsersSlash,
   FaLock,
   FaUnlock,
   FaExternalLinkAlt,
   FaLongArrowAltUp,
   FaCircle,
   FaCheck,
} from 'react-icons/fa';
import { PiTreeView } from 'react-icons/pi';
import { FaRegCircleCheck } from 'react-icons/fa6';

export const IconAlert = RiAlertFill;
export const IconBack = MdOutlineArrowBack;
export const IconCircleBack = VscArrowCircleLeft;
export const IconDownArrow = BiSolidDownArrow;
export const IconEdit = BiEdit;
export const IconEditAlt = BiEditAlt;
export const IconFilter = BiFilter;
export const IconForward = MdOutlineArrowForward;
export const IconGraph = GoGraph;
export const IconHeart = BsHeartFill;
export const IconIndex = VscLayoutMenubar;
export const IconInformation = BiInfoCircle;
export const IconLocked = FaLock;
export const IconMenu = BiDotsVerticalRounded;
export const IconMenuAdmin = MdOutlineSettings;
export const IconMenuBizRequests = VscGitStash;
export const IconMenuCertRequests = VscCoverage;
export const IconSingleCertRequest = FaRegCircleCheck;
export const IconCheck = FaCheck;
export const IconMenuDataSources = MdCable;
export const IconMenuDocs = VscQuestion;
export const IconMenuHome = VscHome;
export const IconMenuQueryChanges = VscGitPullRequestGoToChanges;
export const IconMenuQueryHistory = VscHistory;
export const IconMenuSavedQueries = BsSave;
export const IconMenuSavedSnippets = VscSymbolSnippet;
export const IconMenuSuggestions = BsChatRightDots;
export const IconMenuWorkspaces = VscCodeOss;
export const IconNavigator = PiTreeView;
export const IconOpen = FaExternalLinkAlt;
export const IconPinned = BsPinFill;
export const IconPlus = GoPlus;
export const IconPrivate = FaUsersSlash;
export const IconRightArrow = BiSolidRightArrow;
export const IconSave = BiSave;
export const IconSaveAlt = MdOutlineSave;
export const IconSearch = BiSearch;
export const IconSettings = MdSettings;
export const IconShared = FaUsers;
export const IconStoredProc = VscServerProcess;
export const IconTable = CiViewTable;
export const IconTrash = BiTrash;
export const IconTrigger = VscGithubAction;
export const IconUnlocked = FaUnlock;
export const IconUnpinned = BsPin;
export const IconUnsaved = FaCircle;
export const IconUse = FaLongArrowAltUp;
export const IconX = BiX;
export const IconKey = MdKey;
export const IconNewChat = BiSolidEdit;
export const IconSendChat = BiSend;
