import { Tooltip } from 'react-bootstrap';
import LoadingError from '../../../../components/UI/LoadingError';
import LoadingSpinner from '../../../../components/UI/LoadingSpinner';
import {
   ConnectionAccessType,
   ConnectionLanguage,
   DataConnection,
   DataCredential,
   getAllowableEditType,
   SSHAuthenticationMethod,
   SSLMode,
} from '../../../../entities';
import { DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { assertNever, getErrorMessage } from '../../../../utilities';
import BigQueryDetailForm from './BigQueryDetailForm';
import DatabricksDetailForm from './DatabricksDetailForm';
import MongoDetailForm from './MongoDetailForm';
import MSSqlDetailForm from './MSSqlDetailForm';
import MySqlDetailForm from './MySqlDetailForm';
import Neo4jDetailsForm from './Neo4jDetailsForm';
import OracleDetailForm from './OracleDetailForm';
import PostgreSqlDetailForm from './PostgreSqlDetailForm';
import RedshiftDetailForm from './RedshiftDetailForm';
import SnowflakeDetailForm from './SnowflakeDetailForm';
import TrinoDetailForm from './TrinoDetailForm';

export const connectionDetailsFormId = 'connectionDetailsForm';

export const tooltipSharedConnection = (
   <Tooltip id="tooltipSharedConnection">
      Use this for connections that are shared with one set of credentials. This connection cannot
      be edited by others and the credentials for this connection cannot be viewed by others.
   </Tooltip>
);

export type CredentialFields = {
   accountName?: string;
   password?: string;
   sshAuthMethod?: SSHAuthenticationMethod;
   sshKeyFile?: string;
   sshPassword?: string;
   sshUsername?: string;
   useSSH?: boolean;
};

export type ConnectionFields = {
   authSource?: string;
   connectionAccessType?: ConnectionAccessType;
   connectionName: string;
   database?: string;
   databricksPath?: string;
   description?: string;
   hideDetails?: boolean;
   host?: string;
   language?: ConnectionLanguage;
   port?: number;
   snowflakeAccount?: string;
   sshHost?: string;
   sshPort?: number;
   sslCaCert?: FileList;
   sslClientCert?: FileList;
   sslClientKey?: FileList;
   useSSH?: boolean;
   useSSL?: boolean;
   warehouse?: string;
};

function ConnectionDetailsForm({
   isSaving,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
   selectedDBMS,
}: {
   isSaving?: boolean;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (
      newConnection: DataConnection | undefined,
      newCredential: DataCredential | undefined
   ) => Promise<void>;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
   selectedDBMS?: DBMS;
}) {
   const setCurrentFormState = (state: 'clean' | 'dirty') => {
      onSaveStateChange?.(state);
   };

   // Queries
   const explorerQuery = useGetAuthorizedExplorerQuery();
   const selectedConnectionQuery = useGetDataConnectionQuery({ id: selectedConnectionId });

   const createCredentialObject = (data: CredentialFields) => {
      const credential: DataCredential = {
         accountName: data.accountName,
         accountPassword: data.password === 'CURRENT' ? undefined : data.password,
         dataConnectionId: selectedConnectionId!,
      };
      if (data.useSSH === true) {
         credential.sshAuthMethod = data.sshAuthMethod ?? SSHAuthenticationMethod.PASSWORD;
         credential.sshUsername = data.sshUsername;
         credential.sshPassword = data.sshPassword === 'CURRENT' ? undefined : data.sshPassword;
         credential.sshKeyFile = data.sshKeyFile;
      }
      return credential;
   };

   const createDataConnectionObject = (data: ConnectionFields, newCredential?: DataCredential) => {
      const connection: DataConnection = {};
      if (newCredential) {
         connection.dataCredentials = [newCredential];
      } else {
         connection.dataCredentials = [];
      }

      connection.connectionAccessType = data.connectionAccessType;
      connection.id = selectedConnectionId;
      connection.name = data.connectionName;
      connection.dbms = selectedDBMS ?? selectedConnectionQuery.data?.dbms;
      connection.description = data.description;
      connection.dbHost = data.host;
      connection.dbPort = data.port?.toString();
      connection.hideDetails = data.hideDetails;
      connection.catalogName = data.database;
      connection.snowflakeAccount = data.snowflakeAccount;
      connection.snowflakeWarehouse = data.warehouse;
      connection.authSource = data.authSource;
      connection.language = data.language;
      connection.databricksPath = data.databricksPath;

      connection.sslMode = data.useSSL ? SSLMode.VALIDATED : SSLMode.OPTIONAL;
      if (data.sslCaCert) {
         connection.sslCaCert = data.sslCaCert[0];
      }
      if (data.sslClientCert) {
         connection.sslClientCert = data.sslClientCert[0];
      }
      if (data.sslClientKey) {
         connection.sslClientKey = data.sslClientKey[0];
      }

      connection.useSSH = data.useSSH;
      if (data.useSSH === true) {
         connection.sshHost = data.sshHost;
         connection.sshPort = data.sshPort?.toString();
      }

      return connection;
   };

   //Page Functions
   async function handleSubmit(data: ConnectionFields & CredentialFields): Promise<void> {
      if (!onSubmit) return;
      const credential = createCredentialObject(data);
      const connection = createDataConnectionObject(data, credential);
      onSubmit(connection, credential);
   }

   //Render
   if (selectedConnectionQuery.isLoading || explorerQuery.isLoading) {
      return <LoadingSpinner />;
   }
   if (selectedConnectionQuery.isError) {
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;
   }
   if (explorerQuery.isError) {
      return <LoadingError message={getErrorMessage(explorerQuery.error)} />;
   }

   const showDBMSForm = selectedDBMS ?? selectedConnectionQuery.data?.dbms;
   const editType = getAllowableEditType(selectedConnectionQuery.data, explorerQuery.data?.person);

   switch (showDBMSForm) {
      case DBMS.MSSQL:
         return (
            <MSSqlDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Big_Query:
         return (
            <BigQueryDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.MySQL:
         return (
            <MySqlDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Databricks:
         return (
            <DatabricksDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Neo4j:
         return (
            <Neo4jDetailsForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Postgres:
         return (
            <PostgreSqlDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Redshift:
         return (
            <RedshiftDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Snowflake:
         return (
            <SnowflakeDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Oracle:
         return (
            <OracleDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.Trino:
         return (
            <TrinoDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.MongoDB:
         return (
            <MongoDetailForm
               editType={editType}
               formId={connectionDetailsFormId}
               isSaving={isSaving}
               onSaveStateChange={setCurrentFormState}
               onSubmit={handleSubmit}
               onlyCreds={onlyCreds}
               selectedConnectionId={selectedConnectionId}
            />
         );
      case DBMS.CHAT_GPT:
      case DBMS.Hugging_Face:
      case DBMS.Federated:
      case DBMS.Python:
      case undefined:
         return <></>;
      default:
         assertNever(showDBMSForm);
   }
}

export default ConnectionDetailsForm;
