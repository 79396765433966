import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { AiPulse, Button, Page } from '../../components';
import LoadingError from '../../components/UI/LoadingError';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import SearchInput from '../../components/UI/SearchInput';
import { ConnectionAccessType, DataConnection } from '../../entities';
import { LocalCredentialService } from '../../services';
import { TYPES } from '../../types';
import { useTitle } from '../../utilities';
import AddConnectionWizard from './AddConnectionWizard/AddConnectionWizard';
import { DataConnectionCard } from './DataConnectionCard/DataConnectionCard';
import { usePerson, useListDataConnectionsQuery } from '../../hooks';
import { PersonRole } from '../../enums';

export const DataConnectionsPage = (): JSX.Element => {
   const person = usePerson();
   useTitle('Data Sources');

   //State variables
   const [searchText, setSearchText] = useState('');
   const [showAddModal, setShowAddModal] = useState(false);
   const [myConnections, setMyConnections] = useState<DataConnection[]>([]);

   const localCredentialService = useInjection<LocalCredentialService>(
      TYPES.localCredentialService
   );

   //Queries
   const dataConnections = useListDataConnectionsQuery({
      withNewMetadata: 'flag',
   });

   useEffect(() => {
      if (!dataConnections.data) return;

      const getConnections = async () => {
         const filteredConnections = (await Promise.all(
            dataConnections.data.items
               .filter(
                  (connection) =>
                     connection.connectionAccessType === ConnectionAccessType.INDIVIDUAL
               )
               .map(async (connection) => {
                  const hasCredential = await localCredentialService.has(connection.id!);
                  if (hasCredential) {
                     return connection;
                  }
               })
         ).then((connections) => {
            return connections.filter((connection) => connection !== undefined);
         })) as DataConnection[];
         setMyConnections(filteredConnections);
      };
      getConnections();
   }, [dataConnections.data, localCredentialService]);

   //Page Functions
   const filterConnectionsByText = (
      value: DataConnection,
      index: number,
      array: DataConnection[]
   ) => {
      //check the name
      if (value.name && value.name.toLowerCase().includes(searchText)) {
         return true;
      }

      //Check the description
      if (value.description?.toLowerCase().includes(searchText)) {
         return true;
      }
   };

   if (dataConnections.isLoading) {
      return <LoadingSpinner />;
   } else if (dataConnections.isError) {
      return LoadingError({});
   }

   return (
      <Page
         action={
            person.role !== PersonRole.ORG_BUSINESS_USER ? (
               <Button onClick={() => setShowAddModal(true)} size="sm">
                  Add Data Source
               </Button>
            ) : undefined
         }
         tabs={[
            {
               title: 'My Sources',
               content: (
                  <Stack gap={2}>
                     <SearchInput
                        entityName="data sources"
                        onTextChanged={(newText) => setSearchText(newText.toLowerCase())}
                        value={searchText}
                     />
                     {myConnections.filter(filterConnectionsByText).map((connection) => (
                        <AiPulse key={connection.id} on={connection.hasNewMetadata}>
                           <DataConnectionCard dataConnectionId={connection.id!} />
                        </AiPulse>
                     ))}
                  </Stack>
               ),
            },
            {
               title: 'Shared Sources',
               content: (
                  <Stack gap={2}>
                     <SearchInput
                        entityName="data sources"
                        onTextChanged={(newText) => setSearchText(newText.toLowerCase())}
                        value={searchText}
                     />
                     {dataConnections.data?.items
                        ?.filter(
                           (connection) =>
                              connection.connectionAccessType === ConnectionAccessType.SHARED ||
                              connection.connectionAccessType === ConnectionAccessType.DEMO
                        )
                        .filter(filterConnectionsByText)
                        .map((connection) =>
                           connection.id ? (
                              <AiPulse key={connection.id} on={connection.hasNewMetadata}>
                                 <DataConnectionCard dataConnectionId={connection.id} />
                              </AiPulse>
                           ) : (
                              <></>
                           )
                        )}
                  </Stack>
               ),
            },
            {
               title: 'Available Sources',
               content: (
                  <Stack gap={2}>
                     <SearchInput
                        entityName="data sources"
                        onTextChanged={(newText) => setSearchText(newText.toLowerCase())}
                        value={searchText}
                     />
                     {dataConnections.data?.items
                        ?.filter(
                           (connection) =>
                              connection.connectionAccessType === ConnectionAccessType.INDIVIDUAL &&
                              !myConnections.some(
                                 (myConnection) => myConnection.id === connection.id
                              )
                        )
                        .filter(filterConnectionsByText)
                        .map((connection) => (
                           <AiPulse key={connection.id} on={connection.hasNewMetadata}>
                              <DataConnectionCard dataConnectionId={connection.id!} />
                           </AiPulse>
                        ))}
                  </Stack>
               ),
            },
         ]}
      >
         <AddConnectionWizard onClose={() => setShowAddModal(false)} show={showAddModal} />
      </Page>
   );
};

export default DataConnectionsPage;
