"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SERVICE_PLANS = exports.planNames = void 0;
exports.planNames = ["Free", "Professional", "Team", "Enterprise"];
exports.SERVICE_PLANS = [
    {
        id: 0,
        name: "Free",
        historyDays: 10,
        workspaces: 10,
        users: 10,
        runAIUses: 10,
        documentationAIUses: 100,
        chartAIUses: 100,
        runAIOrgLimit: true,
        runSource: false,
        certification: false,
        schemaChange: false,
        federation: false,
        businessAnswers: false,
    },
    {
        id: 1,
        name: "Professional",
        workspaces: 30,
        users: 30,
        historyDays: 30,
        runAIUses: 300,
        documentationAIUses: 300,
        chartAIUses: 300,
        runAIOrgLimit: true,
        runSource: false,
        certification: false,
        schemaChange: false,
        federation: false,
        businessAnswers: false,
    },
    {
        id: 2,
        name: "Team",
        historyDays: 365,
        runAIUses: 600,
        runAIOrgLimit: true,
        runSource: true,
        certification: true,
        schemaChange: true,
        federation: true,
        businessAnswers: true,
    },
    {
        id: 3,
        name: "Enterprise",
        historyDays: 730,
        runAIOrgLimit: false,
        runSource: true,
        certification: true,
        schemaChange: true,
        federation: true,
        businessAnswers: true,
    },
];
