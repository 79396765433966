import { inject, injectable } from 'inversify';
import {
   ChartConfig,
   SuggestedCharts,
   PaginatedResult,
   Query,
   QueryLog,
   QueryRunOptions,
   QueryToken,
   QueryVersion,
   QueryVersionPatch,
   QueryVersionPost,
   SystemQueryRun,
} from '../entities';
import { QueryReturn } from '../interfaces';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

import type { LocalCredentialService } from './LocalCredentialService';

const path = '/v1/query';
const tokenPath = '/v1/queryToken';

export type VersionType =
   | 'current'
   | 'latest'
   | 'approved'
   | 'shared'
   | 'mine'
   | 'ai-suggestions'
   | 'review'
   | 'all'
   | number;

export interface ListOptionsQuery {
   allWorkspaces?: boolean;
   countOnly?: boolean;
   expandedPersonData?: boolean;
   includeCommentCount?: boolean;
   includeDataConnectionDetails?: boolean;
   includeLatestRun?: boolean;
   includeParent?: boolean;
   includeWorkspace?: boolean;
   query?: string;
   queryId?: number;
   skip?: number;
   sort?: string;
   take?: number;
   version?: VersionType;
   workspaceId?: number;
}

export interface GetOptionsQuery {
   includeLatestVersion?: boolean;
}

interface QueryRunResponse {
   logs: QueryLog[];
   results: QueryReturn[];
}

const filterQueryVersion = (queryVersion: Partial<QueryVersion>) => {
   const { query, parent, ...rest } = queryVersion;
   return rest;
};

function reviveChartConfigFunctions(obj: any): ChartConfig {
   if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
         if (typeof obj[key] === 'string' && obj[key].includes('function')) {
            try {
               // eslint-disable-next-line no-eval
               obj[key] = eval(`(${obj[key]})`);
            } catch (error) {
               break;
            }
         } else if (typeof obj[key] === 'object') {
            reviveChartConfigFunctions(obj[key]);
         }
      }
   }
   return obj;
}

@injectable()
export class QueryService
   implements ApiServiceInterface<QueryVersion, QueryVersionPost, QueryVersionPatch>
{
   constructor(
      @inject(TYPES.apiService) private apiService: ApiService,
      @inject(TYPES.localCredentialService) private localCredentialService: LocalCredentialService
   ) {}

   listOptions(options?: ListOptionsQuery): Promise<PaginatedResult<QueryVersion>> {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }
      return this.list(params);
   }

   async patch(
      id: string | number,
      body: QueryVersionPatch,
      params?: Record<string, string> | undefined
   ): Promise<QueryVersion | undefined> {
      const result = await this.apiService.patch(
         `${path}/${id}`,
         {
            ...body,
            queryVersion: filterQueryVersion(body.queryVersion),
         },
         params
      );
      if (!result) {
         return undefined;
      }

      return result as QueryVersion;
   }

   async post(
      body: QueryVersionPost,
      params?: Record<string, string>
   ): Promise<QueryVersion | undefined> {
      const result = await this.apiService.post<QueryVersion>(
         `${path}/${body.workspaceId}`,
         {
            ...body,
            queryVersion: filterQueryVersion(body.queryVersion),
         },
         params
      );
      if (!result) {
         return undefined;
      }
      return result;
   }

   async put(
      _body: QueryVersion,
      _params?: Record<string, string> | undefined
   ): Promise<QueryVersion | undefined> {
      throw new Error('Method not implemented.');
   }

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }

   async deleteVersion(queryVersionId: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/version/${queryVersionId}`);
   }

   async get(
      id: string | number,
      params?: Record<string, string>
   ): Promise<QueryVersion | undefined> {
      const result = await this.apiService.get<QueryVersion>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      return result as QueryVersion;
   }

   async getLatest(queryId: number): Promise<QueryVersion | undefined> {
      const result = await this.apiService.get<QueryVersion>(`${path}/latest/${queryId}`);
      if (!result) {
         return undefined;
      }

      return result as QueryVersion;
   }

   async getToken(token: string | number) {
      return await this.apiService.get<{ queryId: number }>(`${tokenPath}/token/${token}`);
   }

   async getOptions(id: string | number, options?: GetOptionsQuery) {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }
      return this.get(id, params);
   }

   async list(params?: Record<string, string>): Promise<PaginatedResult<QueryVersion>> {
      const result = await this.apiService.get<PaginatedResult<Query>>(path, params);
      if (!result) {
         return { items: [], totalItems: 0 };
      }

      return {
         ...result,
         items: result.items.flatMap((query) =>
            query.versions
               ? query.versions.map((v) => ({
                    ...v,
                    query: {
                       ...query,
                       query: query,
                    },
                 }))
               : {
                    ...(query.reviewVersion ??
                       query.approvedVersion ??
                       query.latestVersion ??
                       query.aiSuggestionVersion)!,
                    query,
                 }
         ),
      };
   }

   async runQuery(
      queryVersion: QueryVersion,
      options: QueryRunOptions,
      includeCredentialHeaders = true
   ): Promise<QueryReturn[] | null> {
      const dataConnectionIds = options.overrideSchema?.dataConnection.id
         ? [options.overrideSchema.dataConnection.id]
         : queryVersion.steps?.map((step) => step.dataConnectionId!) ?? [];

      return (
         (
            await this.apiService.post<QueryRunResponse>(
               `${path}/${queryVersion.id}/run`,
               { params: options.params, queryTextOverride: options.queryTextOverride },
               {
                  ...(options.exploreTabId
                     ? { exploreTabId: options.exploreTabId.toString() }
                     : {}),
                  ...(options.step ? { step: options.step?.toString() } : {}),
                  ...(options.stopAfterStep
                     ? { stopAfterStep: options.stopAfterStep?.toString() }
                     : {}),
                  ...(options.overrideSchema
                     ? {
                          overrideSchema: JSON.stringify({
                             dataConnectionId: options.overrideSchema.dataConnection.id,
                             schemaName: options.overrideSchema.schemaName,
                          }),
                       }
                     : {}),
               },
               includeCredentialHeaders
                  ? await this.localCredentialService.generateHeaders(dataConnectionIds)
                  : undefined
            )
         )?.results ?? null
      );
   }

   async runSystemQuery(run: SystemQueryRun, includeCredentialHeaders = true) {
      const result = await this.apiService.post<{ log: QueryLog; result: QueryReturn }>(
         `${path}/system-run`,
         {
            ...run,
         },
         {},
         includeCredentialHeaders
            ? await this.localCredentialService.generateHeaders([run.dataConnectionId])
            : undefined
      );

      if (result === null) {
         throw new Error('Bad implementation');
      }

      return result.result;
   }

   async postToken(queryVersion: QueryVersion): Promise<QueryToken | undefined> {
      return (
         (await this.apiService.post<QueryToken>(`${tokenPath}/${queryVersion.queryId}/`, {})) ??
         undefined
      );
   }

   async deleteToken(queryVersion: QueryVersion): Promise<QueryToken | undefined> {
      if (!queryVersion.query?.token?.id) return;
      return (
         (await this.apiService.delete<QueryToken>(
            `${tokenPath}/${queryVersion.queryId}/${queryVersion.query.token.id}`,
            {}
         )) ?? undefined
      );
   }

   async createChartConfig(
      queryVersionId: number,
      chartType?: string,
      rowStructure?: Record<string, string>
   ): Promise<ChartConfig | undefined> {
      const response =
         (await this.apiService.post<ChartConfig>(`${path}/${queryVersionId}/createChart`, {
            chartType: chartType,
            rowStructure,
         })) ?? undefined;
      const parsedResponse = reviveChartConfigFunctions(response ? response : undefined);
      return parsedResponse;
   }

   async suggestChartTypes(
      queryVersionId: number,
      rowStructure?: Record<string, string>
   ): Promise<SuggestedCharts | undefined> {
      const response =
         (await this.apiService.post<SuggestedCharts>(`${path}/${queryVersionId}/suggestCharts`, {
            rowStructure,
         })) ?? undefined;
      return response;
   }
}
