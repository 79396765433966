import { QueryBuilder } from './QueryBuilder';

export class TrinoQueryBuilder extends QueryBuilder {
   public explainQuery(query: string, analyze?: boolean): string {
      if (!analyze) return `EXPLAIN (TYPE LOGICAL) ${query}`;
      return `EXPLAIN ANALYZE ${query}`;
   }
   // -- Create Table

   protected increments(
      name?: string,
      options?: { nullable?: boolean; primaryKey?: boolean }
   ): string {
      throw new Error('Method not supported');
   }

   public createTable({ schema, table }: { schema: string; table: string }) {
      return `CREATE TABLE ${this.qualifyTable(table, schema)} (
  id bigint
);`;
   }
}
