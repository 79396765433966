import { useInjection } from 'inversify-react';
import { useQuery } from 'react-query';
import { Stack } from 'react-bootstrap';

import { LoadingSpinner, Chat, ChatHistory, QueryGetter, QueryWidget } from '../components';
import {
   useOptionalWorkspace,
   useExploreTab,
   useEditExploreTabMutation,
   useListDataChatThreads,
   useGetDataChatThread,
   useAddDataChatMessage,
} from '../hooks';
import { TYPES } from '../types';
import { ApiService } from '../services';
import { DataChatThread, DataChatMessage, QueryVersion } from '../entities';
import { IconBack } from '../utilities/icons';

type DataChat = {
   id: number;
   title: string;
};

export const fetchDataChatQueryKey = ({
   type,
   data,
}: {
   data: { dataChatThreadId: number };
   type: 'fetch' | 'invalidate';
}) => ['dataChats', data.dataChatThreadId];

export const useDataChat = ({ dataChatThreadId }: { dataChatThreadId: number }) => {
   const apiService = useInjection<ApiService>(TYPES.apiService);

   return useQuery<DataChat>(
      fetchDataChatQueryKey({ type: 'fetch', data: { dataChatThreadId } }),
      async () => {
         const result = await apiService.get<DataChat>(`/v1/dataChats/${dataChatThreadId}`);

         if (result === null) {
            throw new Error('Error fetching DataChat');
         }

         return result;
      },
      { retry: false, refetchOnMount: 'always', staleTime: 1000 * 60 }
   );
};

type FetchDataChatsQueryKeyParamsFetch = {
   data: {
      role: 'creator' | 'analyst';
      workspaceId?: number;
   };
   type: 'fetch';
};
type FetchDataChatsQueryKeyParamsInvalidate = {
   data: {
      role?: 'creator' | 'analyst';
      workspaceId?: number;
   };
   type: 'invalidate';
};
type FetchDataChatsQueryKeyParams =
   | FetchDataChatsQueryKeyParamsFetch
   | FetchDataChatsQueryKeyParamsInvalidate;

export const fetchDataChatsQueryKey = ({ type, data }: FetchDataChatsQueryKeyParams) => {
   const baseKey = ['dataChats'];

   if (type === 'fetch') {
      return [...baseKey, data.role, ...(data.workspaceId ? [data.workspaceId] : [])];
   }

   if (data.role === undefined) {
      return baseKey;
   }

   const roleKey = [...baseKey, data.role];

   if (data.workspaceId === undefined) {
      return roleKey;
   }

   return [...roleKey, data.workspaceId];
};

export const useDataChats = ({ workspaceId }: { workspaceId?: number } = {}) => {
   const apiService = useInjection<ApiService>(TYPES.apiService);

   return useQuery<DataChat[]>(
      fetchDataChatsQueryKey({ type: 'fetch', data: { role: 'analyst', workspaceId } }),
      async () => {
         const results = await apiService.get<DataChat[]>(`/v1/dataChats`, {
            role: 'analyst',
            ...(workspaceId ? { workspaceId: workspaceId.toString() } : {}),
         });

         return results ?? [];
      },
      { retry: false, refetchOnMount: 'always', staleTime: 1000 * 30 }
   );
};

const DataChatContainer = ({
   dataChatThreadId,
   exploreTabId,
   queryVersion,
}: {
   dataChatThreadId: number;
   exploreTabId: number;
   queryVersion: QueryVersion;
}) => {
   const { data: thread, isError } = useGetDataChatThread(dataChatThreadId);
   const { mutateAsync } = useEditExploreTabMutation();
   const addDataChatMessage = useAddDataChatMessage();

   if (isError) {
      throw new Error('Error loading DataChat');
   }

   if (!thread) {
      return <LoadingSpinner />;
   }

   const handleClickBack = () => {
      mutateAsync({
         id: exploreTabId,
         payload: { dataChatThreadId: null },
      });
   };

   const addMessage = (message: Partial<DataChatMessage>, thread?: Partial<DataChatThread>) => {
      addDataChatMessage.mutate({
         id: message.threadId,
         message: {
            content: message.content,
            queryVersionId: message.queryVersionId,
         },
         state: thread?.state,
      });
   };

   return (
      <Stack className="h-100 overflow-hidden">
         <Stack className="py-2 px-3 flex-grow-0 flex-shrink-0" direction="horizontal" gap={2}>
            <div onClick={handleClickBack} style={{ cursor: 'pointer', lineHeight: 1 }}>
               <IconBack size={16} />
            </div>
            <div className="f-14p fw-600">{thread.title}</div>
         </Stack>
         <div className="flex-grow-1 overflow-hidden">
            <Chat
               currentQueryVersionId={queryVersion.id}
               noNewThread
               onSend={({ content, nextState, queryVersionId }) => {
                  addMessage(
                     {
                        content,
                        queryVersionId,
                        threadId: thread.id,
                     },
                     {
                        state: nextState,
                     }
                  );
               }}
               renderQueries={(queries) => (
                  <div className="query-list two-up">
                     {queries.map((q, i) => (
                        <QueryGetter key={i} queryVersion={q.query.queryVersion}>
                           {(queryVersion) => (
                              <QueryWidget
                                 collapse
                                 dataChatThreadId={thread.id}
                                 queryVersion={queryVersion}
                                 source="answers"
                              />
                           )}
                        </QueryGetter>
                     ))}
                  </div>
               )}
               thread={thread}
            />
         </div>
      </Stack>
   );
};

export const RequestsTabContent = ({ queryVersion }: { queryVersion: QueryVersion }) => {
   const workspace = useOptionalWorkspace();
   const exploreTab = useExploreTab();
   const threadsQuery = useListDataChatThreads({ workspaceId: workspace?.id });
   const threads = threadsQuery.data ?? [];

   const { mutateAsync } = useEditExploreTabMutation();
   const updateThreadId = (dataChatThreadId: number | null) => {
      mutateAsync({
         id: exploreTab.id!,
         payload: { dataChatThreadId },
      });
   };

   if (!exploreTab.dataChatThreadId) {
      return (
         <ChatHistory
            currentId={null}
            noNewThread
            onChange={(thread) => updateThreadId(thread?.id ?? null)}
            threads={threads}
            title="Workspace Requests"
         />
      );
   }

   return (
      <DataChatContainer
         dataChatThreadId={exploreTab.dataChatThreadId}
         exploreTabId={exploreTab.id!}
         queryVersion={queryVersion}
      />
   );
};

export default RequestsTabContent;
