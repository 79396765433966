"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatQuery = formatQuery;
const newlineClauses = /\b(SELECT|FROM|WHERE)\b/gi;
function formatQuery(query) {
    // Remove comments
    query = query.replaceAll(/--.*$/gm, "").replaceAll(/\/\*.*?\*\//gs, "");
    // Collapse all whitespace
    query = query.replaceAll(/\s+/g, " ");
    // Split lines by semicolon
    query = query
        .split(";")
        .map((stmt) => stmt.trim())
        .filter((stmt) => stmt !== "")
        .join(";\n")
        .trim();
    // Split line by keyword
    query = query
        .replaceAll(newlineClauses, "\n$1") // New line before key clauses
        .trim(); // Trim leading and trailing whitespace
    return query;
}
