import { DataChatMessage, Person, Org, Workspace } from '.';
import { Base } from './Base';

export enum DataChatThreadState {
   ACCEPTED = 'ACCEPTED',
   CANCELLED = 'CANCELLED',
   NEW = 'NEW',
   OPEN = 'OPEN',
   PENDING = 'PENDING',
   SOLVED = 'SOLVED',
}

export interface DataChatThread extends Base {
   assignee: Person;
   assigneeId: number | null;
   creator: Person;
   creatorLastViewedAt: string;
   creatorWorkspaces?: {
      id: number;
      name: string;
   }[];
   latestMessageAt: string;
   messages: DataChatMessage[];
   org: Org;
   orgId: number;
   state: DataChatThreadState;
   title: string;
   workspace: Workspace;
   workspaceId: number;
}
