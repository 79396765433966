import React from 'react';

import { Tooltip, Form, OverlayTrigger, Stack, Popover, InputGroup, Button } from 'react-bootstrap';
import { IconInformation } from '../../../../utilities';
import { ConnectionAccessType, SSHAuthenticationMethod } from '../../../../entities';
import { Controller, useFormContext } from 'react-hook-form';
import { isDesktop } from '../../../../services/DesktopQueryService';
import { ToggleTip } from '../../../../components';
import type { OpenDialogOptions } from 'electron';

export type ConnectionEditType = 'connection' | 'credential' | 'read-only';

const tooltipHideDetails = (
   <Tooltip id="tooltipHideDetails">
      The explorer role can use this connection, but cannot see its details such as the host and
      port.
   </Tooltip>
);

export const HideDetailsField = React.forwardRef((props: any, ref) => (
   <Form.Group>
      <Form.Label>
         Hide Connection Details
         <OverlayTrigger overlay={tooltipHideDetails} placement="auto">
            <span>
               <IconInformation size={16} />
            </span>
         </OverlayTrigger>
      </Form.Label>
      <Form.Check {...props} ref={ref} type="switch" />
   </Form.Group>
));

export const AccessTypeField = React.forwardRef((props: any, ref) => (
   <>
      <hr />
      <Form.Group>
         <Form.Label>Credentials</Form.Label>
         <div>Individual connections are always stored encrypted on your computer.</div>
         <div>Shared credentials are stored encrypted on the runQL servers.</div>
         <Form.Select {...props} ref={ref}>
            <option value={ConnectionAccessType.INDIVIDUAL}>
               Individual (stored only on your computer)
            </option>
            <option value={ConnectionAccessType.SHARED}>Shared (stored in the cloud)</option>
         </Form.Select>
      </Form.Group>
   </>
));

export const SSHConnectionFields = ({ useSSH }: { useSSH: boolean }) => {
   const { control } = useFormContext();

   return (
      <>
         {!isDesktop() && (
            <div>
               We do not support private keys on the web. Please use the desktop app if required.
            </div>
         )}
         <Stack gap={3}>
            <Controller
               control={control}
               name="sshHost"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>
                        SSH Host <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...field}
                        isInvalid={fieldState.error && fieldState.isTouched}
                        isValid={fieldState.isTouched && !fieldState.error}
                        placeholder="SSH Host"
                        required={useSSH}
                     />
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
            <Controller
               control={control}
               name="sshPort"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>
                        SSH Port <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...field}
                        isInvalid={fieldState.error && fieldState.isTouched}
                        isValid={fieldState.isTouched && !fieldState.error}
                        onChange={(e) => {
                           const value = parseInt(e.target.value);
                           field.onChange(isNaN(value) ? e.target.value : value);
                        }}
                        placeholder="SSH Port"
                        required={useSSH}
                     />
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
         </Stack>
      </>
   );
};

export const SSHCredentialFields = ({
   editType,
   isSshKeyFilePermitted,
   useSSH,
}: {
   editType: ConnectionEditType;
   isSshKeyFilePermitted: boolean;
   useSSH: boolean;
}) => {
   const {
      control,
      watch,
      setValue,
      clearErrors,
      formState: { touchedFields },
   } = useFormContext();
   const sshAuthMethod = +watch('sshAuthMethod');

   const handleClickSelectSshKeyFile = async () => {
      if (sshAuthMethod !== SSHAuthenticationMethod.KEY_FILE || !globalThis.runql) {
         return;
      }

      const openDialogOptions: OpenDialogOptions = {
         properties: ['openFile', 'showHiddenFiles', 'dontAddToRecent'],
         title: 'Select SSH Key File',
         message: 'Please select the SSH key file to use with this connection',
      };

      const filePath = await globalThis.runql.openFileDialog(openDialogOptions);

      // No file selected?
      if (filePath === null) {
         return;
      }

      setValue('sshKeyFile', filePath, { shouldDirty: true });
   };

   const handleBlur =
      (checkValue: string = '', setValueOnFocus: string = 'CURRENT') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         const { name, value } = e.target;
         if (value === checkValue && !(name in touchedFields)) {
            setValue(name, setValueOnFocus, {
               shouldTouch: false,
               shouldDirty: false,
            });
            return;
         }
         // Need to ensure the field is registered as touched.
         setValue(name, e.target.value, { shouldTouch: true });
      };

   const handleFocus =
      (checkValue: string = 'CURRENT', setValueOnFocus: string = '') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         const { name, value } = e.target;
         if (value === checkValue) {
            setValue(name, setValueOnFocus, {
               shouldTouch: false,
               shouldDirty: false,
            });
         }
      };

   return (
      <>
         <Stack gap={3}>
            <Controller
               control={control}
               name="sshAuthMethod"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>SSH Authentication Method</Form.Label>
                     <Form.Select
                        disabled={!isSshKeyFilePermitted || editType === 'read-only'}
                        {...field}
                        onChange={(e) => {
                           field.onChange(+e.target.value);
                           setValue('sshPassword', '', { shouldDirty: true });
                           clearErrors('sshPassword');
                           setValue('sshKeyFile', '', { shouldDirty: true });
                        }}
                     >
                        <option value={SSHAuthenticationMethod.PASSWORD}>Password</option>
                        <option value={SSHAuthenticationMethod.KEY_FILE}>SSH Key</option>
                     </Form.Select>
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
            <Controller
               control={control}
               name="sshUsername"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>
                        SSH Username <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...field}
                        disabled={editType === 'read-only'}
                        isInvalid={fieldState.error && fieldState.isTouched}
                        isValid={fieldState.isTouched && !fieldState.error}
                        placeholder="SSH Username"
                        required={useSSH}
                     />
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
            {sshAuthMethod === SSHAuthenticationMethod.KEY_FILE && (
               <Controller
                  control={control}
                  name="sshKeyFile"
                  render={({ field, fieldState }) => (
                     <Form.Group>
                        <Form.Label>
                           <Stack className="align-items-center" direction="horizontal" gap={2}>
                              <span>
                                 SSH Key File <span className="text-danger">*</span>{' '}
                              </span>
                              <ToggleTip>
                                 <Popover id="ssh-key-info-popover">
                                    <Popover.Body>
                                       runQL only stores the path to your SSH key file, and the file
                                       content is never sent to our servers.
                                    </Popover.Body>
                                 </Popover>
                              </ToggleTip>
                           </Stack>
                        </Form.Label>
                        <InputGroup onClick={handleClickSelectSshKeyFile}>
                           <Button
                              className="fs-xs chooseFileButton"
                              disabled={editType === 'read-only'}
                              variant="outline-secondary"
                           >
                              <span className="">Choose File</span>
                           </Button>
                           <Form.Control
                              className="chooseFileInput"
                              {...field}
                              disabled={editType === 'read-only'}
                              placeholder="No file chosen"
                              readOnly={true}
                              required={
                                 useSSH && sshAuthMethod === SSHAuthenticationMethod.KEY_FILE
                              }
                           />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                           {fieldState.error?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                  )}
               />
            )}
            <Controller
               control={control}
               name="sshPassword"
               render={({ field, fieldState }) => (
                  <Form.Group>
                     <Form.Label>
                        {`SSH ${
                           sshAuthMethod === SSHAuthenticationMethod.PASSWORD
                              ? 'Password'
                              : 'Key Passphrase'
                        }`}{' '}
                        {useSSH && sshAuthMethod === SSHAuthenticationMethod.PASSWORD ? (
                           <span className="text-danger">*</span>
                        ) : null}
                     </Form.Label>
                     <Form.Control
                        {...field}
                        disabled={editType === 'read-only'}
                        isInvalid={fieldState.error && fieldState.isTouched}
                        isValid={fieldState.isTouched && !fieldState.error}
                        onBlur={handleBlur('', 'CURRENT')}
                        onFocus={handleFocus('CURRENT', '')}
                        placeholder={`SSH ${
                           sshAuthMethod === SSHAuthenticationMethod.PASSWORD
                              ? 'Password'
                              : 'Key Passphrase'
                        }`}
                        required={useSSH && sshAuthMethod === SSHAuthenticationMethod.PASSWORD}
                        type="password"
                     />
                     <Form.Control.Feedback type="invalid">
                        {fieldState.error?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
               )}
            />
         </Stack>
      </>
   );
};
