import { inject, injectable } from 'inversify';

import { TYPES } from '../types';
import { ApiService } from './ApiService';

import type { DataChatThread } from '../entities';

const PATH = '/v1/dataChats';

export type DataChatRole = 'creator' | 'analyst';

export type DataChatThreadPost = { message: string };
export type DataChatThreadPatch = {
   assigneeId?: number | null;
   messages?: { content?: string; queryVersionId?: number }[];
   state?: DataChatThread['state'];
   viewedByCreator?: true;
   workspaceId?: number;
};
type DataChatThreadsMetadataCreator = {
   metadata: {
      counts: {
         unread: number;
      };
   };
   type: 'creator';
};
type DataChatThreadsMetadataAnalyst = {
   metadata: {
      counts: {
         assignedToOthers: number;
         assignedToSelf: number;
         unassigned: number;
      };
   };
   type: 'analyst';
};
export type DataChatThreadsMetadata =
   | DataChatThreadsMetadataCreator
   | DataChatThreadsMetadataAnalyst;

@injectable()
export class DataChatService {
   constructor(@inject(TYPES.apiService) private apiService: ApiService) {}

   async list({
      role,
      workspaceId,
   }: {
      role: DataChatRole;
      workspaceId?: number;
   }): Promise<DataChatThread[]> {
      const ret = await this.apiService.get<DataChatThread[]>(PATH, {
         role,
         ...(workspaceId ? { workspaceId: workspaceId.toString() } : {}),
      });
      if (!ret) throw new Error('DataChatThread list failed');
      return ret;
   }

   async fetchMetadata({ role }: { role: DataChatRole }): Promise<DataChatThreadsMetadata> {
      const ret = await this.apiService.get<DataChatThreadsMetadata>(`${PATH}/metadata`, { role });
      if (!ret) {
         throw new Error('DataChatThreadsMetadata fetch failed');
      }
      return ret;
   }

   async get(id: string | number): Promise<DataChatThread> {
      const ret = await this.apiService.get<DataChatThread>(`${PATH}/${id}`);
      if (!ret) throw new Error(`DataChatThread not found: ${id}`);
      return ret;
   }

   async post(thread: DataChatThreadPost): Promise<DataChatThread> {
      const result = await this.apiService.post<DataChatThread>(PATH, thread);
      if (!result) {
         throw new Error('DataChatThread creation failed');
      }
      return result;
   }

   async patch(id: number, data: DataChatThreadPatch): Promise<DataChatThread> {
      const result = await this.apiService.patch<DataChatThread>(`${PATH}/${id}`, data);
      if (!result) {
         throw new Error('DataChatThread update failed');
      }
      return result;
   }
}
