import React from 'react';
import { QueryPanel, QueryWidget, Tab } from '../components';
import { QueryVersion } from '../entities';
import { Dropdown } from 'react-bootstrap';
import { BiChevronDown } from 'react-icons/bi';
import { useRunQueries } from '../hooks';

function QueryReviewItem({
   version,
   diffVersion,
   expandDetails,
   extraActions,
   showDetails,
   setShowDetails,
   extraTabs,
   hideTabs,
}: {
   diffVersion?: (queryVersion: QueryVersion) => QueryVersion | undefined;
   expandDetails?: boolean;
   extraActions?: (queryVersion: QueryVersion) => React.ReactNode[];
   extraTabs?: (queryVersion?: QueryVersion) => Tab[];
   hideTabs?: {
      comments?: boolean;
      logs?: boolean;
   };
   setShowDetails: (queryVersion: QueryVersion, show?: boolean) => void;
   showDetails: (queryVersion: QueryVersion) => boolean | 0 | undefined;
   version: QueryVersion;
}) {
   const { runButton, runStatus } = useRunQueries({
      onStatusChange: (queryVersion, status) => {
         if (status.results && queryVersion.id) {
            setShowDetails(queryVersion, true);
         }
      },
   });

   return (
      <QueryWidget
         action="none"
         diffSide="new"
         diffVersion={diffVersion?.(version)}
         extraActions={[
            !expandDetails && (
               <Dropdown key="publish">
                  <Dropdown.Toggle className="btn btn-sm" variant="secondary">
                     <>
                        Actions
                        <BiChevronDown size={16} />
                     </>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                     <Dropdown.Item onClick={() => setShowDetails(version, !showDetails(version))}>
                        {showDetails(version) ? 'Hide' : 'Show'} Details
                     </Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
            ),
            runButton(version),
            ...(extraActions?.(version) ?? []),
         ]}
         key={version.id}
         noHover
         queryVersion={version}
         showTags
         source="saved"
      >
         {version && (expandDetails || showDetails(version)) && (
            <div style={{ margin: '8px -8px -8px' }}>
               <QueryPanel
                  extraTabs={extraTabs?.(version)}
                  hideTabs={{
                     ...hideTabs,
                     runSource: true,
                     suggestions: true,
                     runa: true,
                     admin: true,
                     requests: true,
                  }}
                  isGeneratingDocumentation={false}
                  queryResults={runStatus(version)?.results}
                  queryVersion={version}
                  readOnly
               />
            </div>
         )}
      </QueryWidget>
   );
}

export default QueryReviewItem;
