import { inject, injectable } from 'inversify';
import { Org } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/org';

export interface WorkflowCountResult {
   workflowCount: number;
}

export interface OrgStats {
   availableDataSources: number;
   collaboratedWorkspaces: number;
   createdDataSources: number;
   createdWorkspaces: number;
   myCertifiedQueries: number;
   myQueryRunCount: number;
   mySavedQueries: number;
   mySnippets: number;
   queryRunCount: number;
   savedQueries: number;
   totalCertifiedQueries: number;
   totalDataSources: number;
   totalQueriesAwaitingReview: number;
   totalSnippets: number;
   totalWorkspaces: number;
}

@injectable()
export class OrgService implements ApiServiceInterface<Org> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }
   listOptions(filter: Object): Promise<Org[]> {
      throw new Error('Method not implemented.');
   }
   async patch(
      id: string | number,
      body: Org,
      params?: Record<string, string> | undefined
   ): Promise<Org | undefined> {
      const result = await this.apiService.patch(`${path}/${id}`, body, params);
      if (!result) {
         return undefined;
      }
      return result as Org;
   }
   post(body: Org, params?: unknown): Promise<Org | undefined> {
      throw new Error('Method not implemented.');
   }

   async put(_body: Org, _params?: Record<string, string> | undefined): Promise<Org | undefined> {
      throw new Error('Method not implemented.');
   }

   async delete(id: string | number): Promise<null> {
      throw new Error('Method not implemented.');
   }

   async get(id: string | number, params?: Record<string, string>): Promise<Org | undefined> {
      const result = await this.apiService.get<Org>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      const data = result as Org;
      this.cast(data);
      return data;
   }

   async list(params: Record<string, string> = {}): Promise<Org[]> {
      const result = await this.apiService.get<Org[]>(`${path}/`, params);
      if (!result) {
         return [];
      }

      const data = result as Org[];
      data.forEach((item) => this.cast(item));
      return data;
   }

   async getWorkflowCount(): Promise<WorkflowCountResult> {
      const result = await this.apiService.get<WorkflowCountResult>(`${path}/workflowCount`);
      if (!result) {
         return { workflowCount: 0 };
      }
      return result;
   }

   async getStats(): Promise<OrgStats> {
      const result = await this.apiService.get<OrgStats>(`${path}/stats`);
      if (!result) {
         return {
            availableDataSources: 0,
            collaboratedWorkspaces: 0,
            createdDataSources: 0,
            createdWorkspaces: 0,
            myQueryRunCount: 0,
            mySavedQueries: 0,
            mySnippets: 0,
            queryRunCount: 0,
            savedQueries: 0,
            myCertifiedQueries: 0,
            totalDataSources: 0,
            totalQueriesAwaitingReview: 0,
            totalSnippets: 0,
            totalWorkspaces: 0,
            totalCertifiedQueries: 0,
         };
      }

      return result;
   }

   cast(item: Org): void {
      if (!item) {
         return;
      }
   }
}
