import { inject, injectable } from 'inversify';
import { Base } from '../entities/Base';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { RunaMode } from '@runql/util';
import type { LocalCredentialService } from './LocalCredentialService';

const PATH = '/v1/ask';

export interface Prompt extends Base {
   dataConnectionId?: number;
   prompt: string;
}

export type RunaStep = {
   dataConnectionId?: number;
   defaultSchema?: string;
   execPlan?: string;
   order?: number;
   query?: string;
   schemas?: string[];
   suggestedExecPlan?: string;
   suggestedQuery?: string;
};

export type RunaStepData = {
   currentQuery?: string;
   dataConnectionId?: number;
   defaultSchema?: string;
   execPlan?: string;
   order?: number;
   suggestedExecPlan?: string;
};

export interface AskRunaRequest {
   client: 'web' | 'desktop';
   mode: RunaMode;
   previous?: AskRunaResponse[];
   prompt?: string;
   steps: RunaStep[];
}

export interface AskRunaResponse {
   answer?: string;
   isUpgradePrompt?: string;
   label: string;
   mode?: RunaMode;
   prompt: string;
   steps: RunaStepData[];
   timestamp: Date;
}

@injectable()
export class AskService {
   constructor(
      @inject(TYPES.apiService) private apiService: ApiService,
      @inject(TYPES.localCredentialService) private localCredentialService: LocalCredentialService
   ) {}

   async askRuna(
      body: AskRunaRequest,
      params?: Record<string, string>
   ): Promise<AskRunaResponse | null> {
      return await this.apiService.post<AskRunaResponse>(
         `${PATH}/askRuna`,
         body,
         params,
         body.mode === RunaMode.Optimize ||
            body.mode === RunaMode.CompareExecPlans ||
            body.mode === RunaMode.AnalyzeExecPlans
            ? await this.localCredentialService.generateHeaders(
                 body.steps
                    .map((step) => step.dataConnectionId)
                    .filter((id): id is number => id !== undefined)
              )
            : undefined
      );
   }
}
